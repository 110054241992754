@import (reference) '../../styles/_library.less';

.f-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s, visibility 0s 0.2s;
  z-index: 1449; // 1 less than .CategoryNav-wrapper
  background: @modal-backdrop-bg;
  backdrop-filter: @modal-backdrop-filter;

  &.visible {
    opacity: 1;
    transition-delay: 0s;
    transition-duration: 0s;
    visibility: visible;
  }

  // Version 2025
  &--v2025 {
    background: @color-white;
  }

  &--all {
    z-index: 10046;
  }

  &--menu {
    z-index: @z-indexOverlaySidePanelMenu;
    left: 200px;
  }

  &--menuTab {
    z-index: 70;
  }

  &--header {
    z-index: 1031;
  }

  // Same as &--header but cover search bar on narrow screens
  &--header-layers {
    z-index: 1550;
    top: 62px;
  }

  &--cartPreview {
    opacity: 1;
  }

  &--addFavoriteStore,
  &--wishlist {
    background-color: @color-white;
    opacity: 1;
  }

  &--framed {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    min-height: 80px;
    z-index: 50;
  }

  &--sidepanel {
    position: fixed;
    height: 100%;
    z-index: @z-indexOverlaySidePanel;
    opacity: 0.64;
  }

  &--inside {
    background: @color-white;
    position: absolute;

    &__rounded {
      background: @color-white;
      border-radius: 8px 0 0 8px;
      z-index: @z-indexOverlayInsideSidePanel;
      &--right {
        border-radius: 0 8px 8px 0;
      }
    }

    &__roundedRight {
      background: @color-white;
      border-radius: 0 8px 8px 0;
      z-index: @z-indexOverlayInsideSidePanel;
    }
  }

  &__loader {
    width: 1em;
    height: 1em;
    font-size: 56px;

    // Version 2025
    &--v2025 {
      font-size: 76px;
    }
  }

  &__message {
    font-size: 15px;
    margin-top: 14px;
    text-align: center;
    text-wrap: balance;
  }
}
