@import (reference) '../../styles/_library.less';
@import (reference) '../../styles/common/components/buttons.less';

.stickyHeader {
  background: @color-white;
  height: 70px;
  position: sticky;
  top: calc(@header-nav - 1px); // hide top box-shadow
  z-index: 999;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0 -8px 40px @color-gray-dark;

  &__nav {
    flex: 1 1 auto;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    padding: 0;

    @media (max-width: 1024px) {
      width: calc(100% - 70px);
      overflow-x: auto;
      flex: initial;
      white-space: nowrap;

      &::-webkit-scrollbar-button,
      &::-webkit-scrollbar {
        display: block;
        width: 0;
        height: 0;
      }
    }
  }

  &__item {
    border-left: 1px solid @color-grey-dark;
    flex: 1 1 auto;

    &:first-child {
      border: none;
    }

    @media (max-width: 1300px) {
      &--priority2 {
        display: none;
      }
    }
  }

  &__label {
    position: relative;
    flex-wrap: wrap;
    height: 100%;
    font-size: 15px;
    border-left: 1px solid @color-grey-dark;
    flex-direction: column;
    padding: 10px 20px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    color: @color-dark-light;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      left: 0;
      right: 0;
      border-bottom: 5px solid @color-dark-light;
      flex: 0 0 auto;
    }

    &:hover,
    &.active {
      cursor: pointer;
      //font-weight: bold;
      text-decoration: none;
      text-shadow: 0 0 0.8px @color-dark-light;

      &::after {
        width: 100%;
      }
    }
  }

  &__buybox {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0 0 auto;
    width: 305px;

    @media (max-width: 1024px) {
      width: 70px;
      position: absolute;
      right: 0;
      background: @color-white;
    }
  }

  &__buyboxVisual {
    padding: 10px;
    border-left: 1px solid @color-grey-dark;
    height: 100%;
    flex: 0 0 80px;

    img {
      display: block;
      margin: auto;
      max-width: 50px;
      max-height: 50px;
      height: auto;
      width: auto;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__buyboxText {
    padding: 0 10px;
    width: 100%;
    max-height: 100%;
    flex: 1 1 0%;
    overflow: hidden;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &__buyboxTextTitle {
    display: block;
    color: @color-dark-light;
    text-overflow: ellipsis;
    //white-space: nowrap;
    overflow: hidden;
    max-width: 135px;
  }

  &__buyboxPrice {
    width: 50%;
    float: left;
  }

  &__buyboxTextPrice {
    display: block;
    color: @color-orange;
  }

  &__buyboxPriceStandard {
    font-size: 8px;
  }

  &__buyboxPriceADH {
    font-size: 8px;
  }

  &__buyboxTextPriceStandard {
    color: @color-red;
  }

  &__buyboxTextPriceADH {
    color: @color-gray;
  }

  &__buyboxButton {
    border: 0;
    padding: 20px;
    flex: 0 0 70px;
    cursor: pointer;
    transition: @button-transition;
    border-radius: 0;

    .ff-button-label {
      display: none; // TODO screen accessibility
    }

    .ff-button-icon {
      font-size: 30px;
      width: 1em;
      margin: 0;
    }
  }
}
