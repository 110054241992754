///////////////////////////////////////////////////////////////
//
// TODO: use just one mixins file for desktop AND mobile :
// \Src\Front.Assets\Assets\style\utils\mixins.less
// \Src\Front.Assets\Nav\Css\Mobile\less\mixins\helpers.less
// \Src\Front.Assets\Nav\Css\Mobile\less\common\grid.less
//
///////////////////////////////////////////////////////////////

//
// Mixins
// --------------------------------------------------

// Utilities
// -------------------------

// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `::before` to contain the top-margins of child elements.
.clearfix() {
  &::before,
  &::after {
    content: ' '; // 1
    display: table; // 2
  }

  &::after {
    clear: both;
  }
}

// WebKit-style focus
.tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

// Center-align a block level element
.center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Text overflow
// Requires inline-block or block for proper styling
.text-overflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Button sizes
// -------------------------
.button-size(@padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;
}

// Grid System
// -----------

// Centered container element
.container-fixed() {
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
  width: 100%; // when the container is in flex parent, and have a flex (grand)child with overflow(-x) other that `visible` (like .simpleCarousel), we need this or the child will no more overflow
  .clearfix();
}

// Form control sizing
//
// Relative text size, padding, and border-radii changes for form controls. For
// horizontal sizing, wrap controls in the predefined grid classes. `<select>`
// element gets special love because it's special, and that's a fact!

.input-size(@input-height; @padding-vertical; @padding-horizontal; @font-size; @line-height; @border-radius) {
  height: @input-height;
  padding: @padding-vertical @padding-horizontal;
  font-size: @font-size;
  line-height: @line-height;
  border-radius: @border-radius;

  select& {
    height: @input-height;
    line-height: @input-height;
  }

  textarea&,
  select[multiple]& {
    height: auto;
  }
}

// TODO replace with font-weight: 300;
.typoFnacLight() {
  // font-family: @typoFnac;
  font-weight: 300;
}

// TODO replace with font-weight: normal;
.typoFnacRegular() {
  // font-family: @typoFnac;
  font-weight: normal;
}

// TODO replace with font-weight: bold;
.typoFnacBold() {
  // font-family: @typoFnac;
  font-weight: 700;
}

///////////////
// Mixins used by mobile
///////////////

.greyBgActive() {
  transition: background-color 0.1s ease;

  &:active {
    background-color: @color-silver-light;
  }
}

/*#region HEADINGS */
/*
    Create a title with a background color
    [CSS color] @bgColor (default:#000)
    [CSS color] @color (default: #FFF)
*/
.headTitle(@bgColor: @color-black, @color: @color-white, @fontSize: 0.9em) {
  background-color: @bgColor;
  color: @color;
  font-weight: bold;
  text-transform: uppercase;
  font-size: @fontSize;
  display: inline-block;
  padding: 0 0.4em;
}

.sectionHead(@size: 13px, @margin: 0 0 1em 0) {
  color: @color-silver-light;
  background-color: @color-silver-lighter;
  font-size: @size;
  text-transform: uppercase;
  padding: 0.4em 1em;
  margin: 0 0 1em 0;
  border-bottom: 1px solid @color-silver-dark;
  border-top: 1px solid @color-silver-dark;
}
/*#endregion */
/*#region UI/WIDGET */

/*#region <details> */
/* HTML Struct:
    <details>
        <summary></summary>
        <dl>
            <dt></dt>
            <dd></dd>
        </dl>
    </details>
*/
// .detail() {
//   padding: 0;

//   &[open],
//   &.open {
//     & summary {
//       &::after {
//         content: '-';
//         font-size: 20px;
//         margin-top: -13px;
//         right: 21px;
//       }
//     }
//   }

//   summary {
//     &::marker {
//       display: none;
//     }

//     outline: none;
//     display: block;
//     font-family: @typoFnac;
//     position: relative;
//     padding: 1em;
//     text-transform: uppercase;
//     line-height: unit((15px / 12px));
//     //border-bottom: 1px solid @color-silver-dark;
//     //background-color:@color-silver-lighter;
//     &::before {
//       color: @color-black;
//       padding-right: 10px;
//     }

//     &::after {
//       position: absolute;
//       font-size: 16px;
//       top: 50%;
//       margin-top: -10px;
//       right: 20px;
//       content: '+';
//       text-align: right;
//       display: inline-block;
//       font-weight: bold;
//     }
//   }

//   dd {
//     border-bottom: 1px solid @color-silver-dark;
//     padding: 1em;

//     a {
//       font-size: 14px;
//       padding: 10px 20px 10px 20px;
//       display: block;
//       position: relative;

//       img {
//         vertical-align: middle;
//         margin-right: 0.5em;
//       }

//       &:active {
//         animation: animGreyBg 0.1s ease-in;
//       }

//       &::after {
//         content: '';
//         .chevron(7px, #232323, -45deg);
//         top: 40%;
//         position: absolute;
//         right: 21px;
//       }
//     }
//   }
// }

// .detail(@tpl) when (@tpl = 'filter') {
//   border-bottom: 1px solid @color-silver-dark;

//   &[open],
//   &.open {
//     background-color: @color-silver-lighter;
//     font-weight: lighter;
//     font-size: 15px;

//     & > summary {
//       font-size: 16px;
//       background-color: @color-silver-light;

//       &::before {
//         content: @icon-i_058_arrow_bottom_02;
//       }

//       &::after {
//         content: '';
//       }
//     }
//   }

//   & > summary {
//     .icon(false, 13px);

//     &::before {
//       content: @icon-i_054_arrow_right_02;
//     }

//     &::after {
//       content: '';
//     }

//     background-color: @color-white;
//   }
// }
/*#endregion */

/*#region FORMS */
.mandatory(@content, @position: after) {
  &::before,
  &::after {
    color: @color-red;
    font-weight: lighter;
    font-size: 16px;
    vertical-align: top;
    padding-left: 2px;
  }
}

.mandatory(@content, @position) when (@position = after) {
  &::after {
    content: @content;
  }
}

.mandatory(@content, @position) when (@position = before) {
  &::before {
    content: @content;
  }
}

.chevron(@size, @color, @deg) {
  width: @size;
  height: @size;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: @color;
  display: block;
  transform: rotate(@deg);
}

.cross(@bg, @height, @graisse) {
  display: inline-block;
  background: @bg;
  height: @height;
  position: relative;
  vertical-align: middle;
  width: @graisse;

  &::after {
    background: @bg;
    content: '';
    height: @graisse;
    left: (-(@height - @graisse)/2);
    position: absolute;
    top: ((@height - @graisse)/2);
    width: @height;
  }
}

.fnacButton() {
  display: block;
  padding: 0 20px;
  margin: 1em;
  appearance: none;
  background-color: @color-white;
  border: 1px solid @color-dark;
  border-radius: @base-radius;
  color: @color-dark;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  transition: all 0.1s;

  &:active,
  &:focus {
    border-color: @color-dark;
  }

  &:active {
    opacity: 0.8;
  }
}

.fnacButton--block() {
  width: calc(100% - 2em);
}

.fnacButton--inline() {
  display: inline-block;
}

.fnacButton--fixed() {
  position: fixed;
  bottom: -50px;
  left: 0;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  z-index: 1;
  transition: 0.25s;
  transform: translateZ(0);
  width: 100%;

  &.fnacbutton--visible {
    transform: translateY(-100%);
  }
}

.fnacButton--small() {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
}

.fnacButton--very-small() {
  height: 32px;
  line-height: 32px;
  font-size: 13px;
}
// colors
.fnacButton--gold() {
  background-color: @color-orange;
  color: @color-white;
  border-color: @color-orange;

  &:active,
  &:focus,
  &:hover {
    background: @color-orange;
    border-color: @color-orange;
    color: @color-white;
  }
}

.fnacButton--black() {
  color: @color-white;
  background-color: #2f3236;

  &:active,
  &:focus,
  &:hover {
    background: #2f3236;
    border-color: #2f3236;
  }
}

.fnacButton--emerald() {
  color: @color-white;
  background: #00bc99;
  border-color: #00bc99;

  &:active,
  &:focus,
  &:hover {
    background: #00e0b6;
    border-color: #00e0b6;
  }
}

.fnacButton--blue() {
  background-color: @color-skyBlue;
  border-color: @color-skyBlue;
  color: @color-white;

  &:focus,
  &:active {
    background-color: @color-skyBlue;
    border-color: @color-skyBlue;
  }
}

.fnacButton--green() {
  background-color: @color-forestGreen;
  border-color: @color-forestGreen;
  color: @color-white;

  &:focus,
  &:active {
    background-color: @color-forestGreen;
    border-color: @color-forestGreen;
  }
}

.fnacButton--emerald() {
  background-color: @color-caribbeanGreen;
  border-color: @color-caribbeanGreen;
  color: @color-white;

  &:focus,
  &:active {
    background-color: @color-caribbeanGreen;
    border-color: @color-caribbeanGreen;
  }
}

.fnacButton--ebook() {
  background-color: @color-skyBlue;
  border-color: @color-skyBlue;
  color: @color-white;
  width: 45px;
  height: 40px;
  padding: 0;
  line-height: 40px;
  margin-right: 0;
  position: absolute;
  right: 8px;
  bottom: 8px;

  &:active,
  &:focus,
  &:hover {
    background: @color-skyBlue !important;
    border: 1px solid @color-skyBlue;
  }

  &::before {
    display: none;
  }

  i::before {
    padding-right: 10px;

    @media (max-width: 800px) {
      padding-right: 0px;
    }
  }
}

.back() {
  background: @color-white;
  font-size: 14px;
  padding: 0.5em 1em;
  border-bottom: 1px solid #c1c1c1;

  a {
    margin-left: 0.5em;
  }

  &::before {
    content: '';
    border-left: 1px solid @color-black;
    border-bottom: 1px solid @color-black;
    transform: rotate(45deg);
    display: inline-block;
    width: 10px;
    height: 10px;
  }
}

/* Mobile headings mixins */
.mobile__page-title() {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  padding: 18px;

  &:empty {
    display: none;
  }
}

.mobile__strata-header() {
  display: flex;
  justify-content: space-between;
  flex: 1 0 auto;
  flex-wrap: nowrap;
  align-items: center;
}

.mobile__strata-title() {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  padding: 16px;

  &:empty {
    display: none;
  }
}

.mobile__strata-link() {
  width: auto;
  float: none;
  font-size: 12px;
  font-weight: normal;
  margin: 0 0 0 auto;
  padding: 0 18px;
  white-space: nowrap;
}

/* Membership price display */
.member-price() {
  font-size: 12px;
  float: left;
  border-right: 1px solid @color-silver-darker;
  margin-right: 4%;
  padding-right: 4%;

  .price {
    display: block;
    font-size: 20px;
    font-weight: bold;

    &.red {
      color: @color-red;
    }
  }

  &--bgGrey {
    border-right: 0;
    margin-right: 0;
    padding-right: 0;

    .price {
      color: @color-gray;
    }
  }

  .tax {
    font-style: normal;
    font-size: 14px;
    top: -4px;
    position: relative;
  }
}

// do not remove "!important" because of ugly HTML with inline style to reset
.text-reset() {
  font-family: inherit !important;
  color: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
}

.text-resetHard() {
  font: inherit !important;
  background: 0 !important;
  color: inherit !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: inherit !important;

  // Removes also <br> and empty tags
  &:empty {
    display: none;
  }
}

.hideScrollbars() {
  scrollbar-width: none;
  scrollbar-color: transparent; // W3C
  -webkit-overflow-scrolling: touch; // Safari iOS smooth scroll

  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollsnap() {
  display: flex;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-snap-type: x mandatory;

  .hideScrollbars();
}

.shadow-A() {
  box-shadow: 0 0 12px 0 rgba(@color-black, 0.05), 0 5px 8px 0 rgba(@color-black, 0.05),
    0 2px 4px 0 rgba(@color-black, 0.05);
}

.shadow-B() {
  box-shadow: 0 0 1px 0 rgba(@color-black, 0.05), 0 3px 4px 0 rgba(@color-black, 0.08);
}

/* TODO: make this dynamic (color, background, positions...) */
.tooltip() {
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 5px;
  color: @color-dark;
  opacity: 0;
  visibility: hidden;
  background: @color-white;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(@color-black, 0.5);
  font-size: 12px;
  line-height: 1.2;
  min-width: 250px;
  padding: 10px;
  text-align: left;
  transition: opacity 0.25s;
  z-index: 1;

  @media (min-width: @screen-sm-min) {
    left: 100%;
    top: 50%;
    margin-left: 12px;
    margin-top: 0;
    transform: translate(0, calc(-50% + 1px));
  }

  &::before {
    @media (min-width: @screen-sm-min) {
      position: absolute;
      background: @color-white;
      border-style: solid;
      border-color: rgba(@color-black, 0.5);
      border-width: 1px 0 0 1px;
      height: 12px;
      width: 12px;
      content: '';
      left: -13px;
      top: 50%;
      transform: translate(50%, -50%) rotate(-45deg);
    }
  }
}

// Custom scrollbar for Sidebar & SlideNav
.customScrollbars(@overflowY: hidden, @overflowY-hover: overlay) {
  @scrollbar-track: rgba(0, 0, 0, 0.25);
  @scrollbar-thumb: rgba(0, 0, 0, 0.5);

  overflow-x: hidden;
  overflow-y: @overflowY;

  // https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
  overscroll-behavior: contain;
  // IE : https://css-tricks.com/snippets/css/hide-scrollbar-in-edge-ie-1011
  -ms-overflow-style: -ms-autohiding-scrollbar;
  // FF : https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scrollbars
  scrollbar-color: @scrollbar-thumb @scrollbar-track;
  scrollbar-width: thin;

  &:hover {
    overflow-y: auto;
  }

  // CSS hack excluding Safari
  @supports not (background: -webkit-canvas(squares)) {
    &:hover {
      overflow-y: @overflowY-hover; // https://caniuse.com/?search=overlay
    }

    // WebKit : https://developer.mozilla.org/en-US/docs/Web/CSS/::-webkit-scrollbar
    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-track {
      background: @scrollbar-track;
      background-clip: content-box;
      border: 4px solid transparent;
      border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background: @scrollbar-thumb;
      background-clip: content-box;
      border: 4px solid transparent;
      border-radius: 7px;
    }
  }
}

.nCarouselRoundedArrows(@size: 36px) {
  .f-nCarousel__arrow {
    &Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: @size;
      height: @size;
      border-radius: 50%;
      transition: opacity 0.2s;
      background-color: var(--f-ncarousel-arrow-background-color, rgba(35, 35, 35, 0.6));
      color: var(--f-ncarousel-arrow-color, @color-white);

      .f-icon {
        font-size: (@size / 3);
      }
    }
  }
}

.nCarouselPositionArrows(@arrowMargin: 0) {
  .f-nCarousel__arrow {
    &--prev {
      left: @arrowMargin;
    }
    &--next {
      right: @arrowMargin;
    }
  }
}

.nCarouselArrowSize(@size) {
  .f-nCarousel__arrow {
    width: @size;
    height: @size;

    &Icon {
      width: @size;

      .f-icon {
        font-size: @size;
      }
    }
  }
}

.f-selectList() {
  display: block;
  font-size: 13px;
  font-family: inherit;
  font-weight: normal;
  color: @color-black;
  line-height: 1.3;
  max-width: 100%;
  // Useful when width is set to anything other than 100%
  margin: 0;
  appearance: none;

  // Hide arrow icon in IE browsers
  &::-ms-expand {
    display: none;
  }

  // &:focus {
  //   outline: none;
  // }

  option {
    font-weight: normal;
  }
}

.focus-visible() {
  &:focus-visible {
    outline: 2px solid rgba(@color-orange, 0.5) !important;
    outline-offset: -2px !important;
  }
}

.focus-visible-dark() {
  &:focus-visible {
    outline-color: rgba(@color-dark, 0.5) !important;
  }
}

.withBackdrop() {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: @modal-backdrop-bg;
    backdrop-filter: @modal-backdrop-filter;
  }
}
