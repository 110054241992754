// Express Plus
// ------------------------------

.Popup-FnacPlusInfo {
  background: @color-white;
  width: 1000px;
  padding: 0;
  overflow: auto;
}

.f-fnacPlusAside-FreeMonthLabelWrapper {
  display: table;
  width: 100%;
}

.f-fnacPlusAside-FreeMonthLabel {
  display: table-cell;
  background-color: #52c7e0;
  height: 179px;
  vertical-align: middle;
  text-align: center;
  font-size: 28px;
}

.f-fnacPlus-Advantages-label {
  font-size: 15px;
  line-height: 19px;
  font-weight: 300;
  text-transform: none;

  .bleu {
    color: @color-fnacplus;
    font-weight: 700;
  }

  .blanc {
    color: @color-white;
    font-weight: 700;
  }

  &--lvl1 {
    text-transform: uppercase;
    font-size: 17px;
    line-height: 23px;
    font-weight: 500;
    margin-bottom: 0 !important;
  }

  &--lvl2,
  &--lvl3 {
    margin-bottom: 20px !important;
  }
}

.f-fnacPlus-Advantages-Price {
  margin-bottom: 20px !important;
  font-size: 20px;
  font-weight: 700;

  small {
    font-weight: normal;
  }
}

.AdhCardInfo-Action {
  display: table;
  min-height: 310px;
  padding: 20px 30px;

  @media (max-width: @screen-md-min) {
    min-height: inherit;
    text-align: center;
    margin: 0 auto;
  }
}

.AdhCardPlusInfo-title,
.AdhCardInfo-content {
  overflow: hidden;
  font-size: 15px;

  b {
    font-weight: bold;
  }
}

.AdhCardPlusInfo-title {
  display: block;
  padding: 0;
  width: 100%;
  color: @color-white;
  font-size: 1.4em;
  text-transform: uppercase;
  background: @color-silver-darker;
  background: radial-gradient(ellipse at center, #464646 0%, @color-dark 70%);

  .small {
    text-transform: lowercase;
  }

  @media (min-width: @screen-md-min) {
    display: table-cell;
    width: 25%;
    vertical-align: middle;
  }
}

.AdhCardPlusInfo-img {
  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  @media (min-width: @screen-md-min) {
    margin-bottom: 16px;
  }

  @media (max-width: @screen-md-min) {
    margin-bottom: 16px;
  }
}

.AdhCardInfo-content {
  display: block;
  width: 100%;

  @media (min-width: @screen-md-min) {
    display: table-cell;
    width: 75%;
  }
}

.AdhCardInfo-block {
  background: @color-white;
  border: 1px solid @color-silver-darker;
  border-width: 0 1px 1px 0;
  display: table;
  height: 180px;
  float: left;
  color: @color-dark;
  text-align: center;
  text-transform: uppercase;

  &--vp {
    padding-top: 26px;

    .AdhCardInfo-icon {
      overflow: initial;
    }
  }

  // Adjust decimal precision
  @media (min-width: 768px) {
    &.col-sm-8 {
      width: (100% / 3);
    }
  }

  & > div {
    display: table-cell;
    vertical-align: top;
    padding: 0 18px;
  }
}

.AdhCardInfo-supText {
  display: block;
  margin-top: -0.2em;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0;
}

.AdhCardInfo-icon {
  // Text
  display: block;
  height: auto;
  font-size: 55px;
  font-style: normal;
  letter-spacing: -5px;
  position: relative;
  overflow: hidden;

  &--partner {
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -1.5px;
    font-weight: bold;
    text-align: left;
    padding: 20px 35px;
    position: relative;

    img {
      width: 110px;
      margin: 20px auto 0;
    }
  }

  &--spectables {
    line-height: 95px;
  }

  &--deezer {
    img {
      margin: 20px auto 0;
      width: 115px;
    }
  }

  &--chrono-2h {
    img {
      margin: 20px auto 0;
      width: 45px;
    }
  }

  &--cheques-cadeaux {
    img {
      width: 50px;
      margin: 20px auto 0;
    }
  }

  &--canal {
    height: 75px;
    line-height: 75px;

    img {
      max-width: 170px;
      width: 100%;
    }
  }
  // Icon
  & > .icon {
    font-size: 50px;
    vertical-align: inherit;
  }
}

.AdhCardInfo-partners-icon {
  display: block;
  margin: 20px auto 0;
  width: 28px;
}

.AdhCardInfo-txt {
  hr {
    margin: 12px 0 20px 0;
  }
}

.f-fnacPlus-AboList {
  padding: 0;
  line-height: 0;
  font-size: 0;
  margin: 0;
}

.f-fnacPlus-AboList li {
  display: inline-block;
  width: (100% / 3);
  border-left: 1px solid #d8d8d8;
  height: 200px;
  padding: 0;
  font-size: 15px;

  .icon {
    display: block;
    height: 10px;
    width: 10px;
    background: red;
  }
}

.f-fnacPlus-Advantages-footer {
  background: @color-silver-lighter;
  padding: 10px;
  line-height: 1;

  b {
    font-weight: bold;
  }
}

.f-fnacPlus-Advantages-footerParagraphTitle,
.f-fnacPlus-Advantages-footerParagraphText {
  color: @color-gray;
  font-size: 11px;
  margin-bottom: 10px;

  .icon {
    color: @color-dark;
    cursor: pointer;
    margin: 0 5px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(90deg);

    &.active {
      transform: rotate(90deg);
    }
  }
}

.f-fnacPlus-Advantages-footerParagraphText {
  color: @color-gray;
  font-size: 10px;
  height: 0;
  margin-bottom: 0;
  margin-top: 0;
  overflow: hidden;

  &.active {
    height: auto;
  }
}

#Popup-FnacPlusInfo {
  &.Popup-FnacPlusInfo {
    max-width: 750px;
    padding: 0;
    border-radius: 0;
  }

  .AdhCardInfo-Action {
    display: flex;
    flex-direction: row;
    height: 120px;
    min-height: 0;
    padding: 0;
    align-self: center;
    flex-wrap: nowrap;
    align-items: center;
    margin: auto;
  }

  .AdhCardPlusInfo-title {
    display: flex;
    padding: 0;
    width: 100%;
    background: @color-silver-darker;
    background: radial-gradient(ellipse at center, #464646 0%, @color-dark 70%);
    color: @color-white;
    font-size: 18px;
    line-height: 1.2;
    text-transform: none;

    .small {
      text-transform: lowercase;
    }
  }

  .AdhCardInfo-content {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-content: stretch;
    flex-wrap: wrap;
  }

  .AdhCardInfo-block {
    flex: (100% / 3);
    display: block;
    height: auto;
    width: auto;
    max-width: 250px;
    padding: 15px 5px;
    background: @color-white;
    border: 1px solid @color-silver-darker;
    border-width: 0 1px 1px 0;
    color: @color-dark;
    text-align: center;
    text-transform: none;

    &--vp {
      padding-top: 26px;

      .AdhCardInfo-icon {
        overflow: initial;
      }
    }

    & > div {
      display: block;
      padding: 0;
    }
  }

  .AdhCardInfo-icon {
    &--fnacpluscarte {
      margin-right: 25px;
      width: 120px;

      img {
        width: 100%;
      }
    }
  }

  .AdhCardInfo-partners-icon {
    display: block;
    margin: 0 auto;
    width: 50px;
    height: 50px;
  }

  .AdhCardInfo-txt {
    margin: 10px 0 0;
    line-height: 1.2;

    hr {
      margin: 12px 0 20px 0;
    }

    &-bleu {
      color: @color-fnacplus;
      font-weight: 700;
    }
  }

  .f-fnacPlus-Advantages-footer {
    background: @color-silver-lighter;
    padding: 20px;
    line-height: 1.2;

    b {
      font-weight: bold;
    }
  }

  .f-fnacPlus-Advantages-footerParagraphText {
    font-size: 11px;
  }

  .f-fnacPlus-Advantages-more {
    display: block;
    padding: 0;
    margin-top: 10px;
    background: none;
    border: none;
    text-decoration: underline;
  }

  .f-fnacPlus-Advantages-cgv {
    display: none;
    margin-top: 5px;

    &.active {
      display: block;
    }
  }

  .mfp-close {
    top: 0;
    color: @color-white;
  }
}
