// .brand {
//   position: relative;

//   .seeAll-bt {
//     border-color: @color-silver-darker;

//     @media (max-width: @screen-md-min) {
//       font-size: 1em;
//     }
//   }
// }

.brand-list {
  a {
    position: relative;
    display: inline-block;
  }
}

.brand-listContent {
  text-align: center;

  .f-nCarousel__itemInner {
    min-width: 200px;
  }
}

.brand-link {
  &:hover,
  &:focus {
    .brand-hover {
      opacity: 1;
    }
  }
}

.brand-hover {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  transition: opacity 0.3s ease;
}

.brand-carousel {
  clear: left;
  border-top: 1px solid @color-silver-darker;
  border-bottom: 1px solid @color-silver-darker;
  margin-left: 0;
  margin-right: 0;
  text-align: center;

  &.f-nCarousel--arrows {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  &::after {
    content: ' ';
    display: table;
    clear: both;
  }

  & > a {
    position: relative;
    vertical-align: top;
    text-decoration: none;
    display: inline-block;
  }

  .f-nCarousel__itemInner {
    min-width: 200px;
  }
}

.brand-carousel--noCarousel {
  white-space: normal;
  text-align: center;
}

.brand-visual {
  max-width: 100%;
}

.brand-media {
  display: block;
  clear: both;
  cursor: pointer;
}
