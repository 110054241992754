// Best sales strate
//
// .bestSales : best sales main container (wrap the components)
// .bestSales__title : best sales main title (used as a trigger for the js)
//

.bestSales {
  border: 0;
  border-bottom: 1px solid @color-silver-darker;

  &.isClosed {
    .bestSales__container {
      display: none;
    }

    .bestSales__toggle {
      &Off {
        display: inline-block;
      }

      &On {
        display: none;
      }
    }
  }
}

.bestSales__title {
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  padding: 10px 20px 10px;
  border-left: 5px solid @color-red;
}

.bestSales__container {
  border-left: 5px solid @color-red;

  .f-nCarousel__item {
    padding: 0 10px;
    border-right: 1px solid @color-silver-darker;
    margin: 10px 0;
    @media (min-width: 1281px) {
      min-width: (100% * 2 / 9);
    }
    @media (min-width: 1600px) {
      min-width: 18.18%;
    }
    @media (max-width: 1110px) {
      min-width: 28.57%;
    }
    @media (max-width: @screen-md-min) {
      min-width: 40%;
    }
    &:last-of-type {
      border-right-width: 0;
    }
  }
}

.bestSales__content {
  text-align: left;
  position: relative;
  font-size: 0;
  white-space: normal;
  height: 60px;
  width: 90%;
  margin: 0 auto;

  &:hover {
    .bestSales__image {
      transform: scale(1.05);
    }
  }
}

.bestSales__imageContainer {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.bestSales__image,
.bestSales__content .Category-image {
  max-height: 55px;
  height: auto;
  max-width: 55px;
  transform: scale(1);
  transition: all 0.2s linear;
  backface-visibility: hidden;
}

.bestSales__link {
  cursor: pointer;
  color: @color-dark;
  display: inline-block;
  font-size: 15px;
  padding: 0 0 0 70px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.bestSales__count {
  color: @color-gray;
  display: none;
}

.bestSales__toggle {
  border: 0;
  background: 0;
  font-size: 18px;

  &Off {
    display: none;
  }

  &On {
    display: inline-block;
  }
}
