// ----------------------------------------
// The product thumbnail
// ----------------------------------------

@import 'thumbnail-buttons.less';

.thumbnail {
  @thumbnail-padding: 25px 20px 0;
  @thumbnail-height: 465px;
  @thumbnail-small-height: 390px;
  @thumbnail-img-height: 165px;

  .typoFnacRegular();
  white-space: normal;
  box-shadow: none;
  padding: @thumbnail-padding;
  height: @thumbnail-height;
  border: 1px solid @color-silver-darker;
  border-left: 0;
  position: relative;
  cursor: default;

  &:hover {
    .AdherentBloc {
      display: none;
    }

    .f-countdown-position {
      display: none;
    }
  }

  // &--sponsor {
  //   border: 20px solid @color-silver-darker !important;
  //   padding: 25px 10px 0;
  // }

  // //sponsor tooltip button
  // &-sponsor {
  //   position: absolute;
  //   bottom: 2px;
  //   left: 20px;
  //   color: @color-gray-dark;
  // }

  &-sponsored {
    color: @color-gray-dark;
    display: block;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    right: 20px;
    z-index: 1;
  }

  &-toolbar {
    // should contains .thumbnail-btnOpen
    display: flex;
    position: absolute;
    right: 14px;
    top: 24px;
    flex-direction: column;
    align-items: center;
    // compensate thumbnail-btnOpen margin:
    @items-margin: 5px;
    margin: -@items-margin;

    // Search list, adjustments
    .Article-itemVisual--list & {
      right: 0;
      top: 0;

      .thumbnail-btnOpen {
        margin-bottom: 25px;

        &::after {
          font-size: 12px;
          // margin-top: 60px;
          white-space: nowrap;
          position: absolute;
          content: attr(data-legend);
          top: 29px;
          z-index: 1;
        }
      }
    }
    // FnacShop configurator page
    .layoutLight & {
      display: none;
    }
  }

  &-quantityContainer {
    display: block;
    position: absolute;
    bottom: 60px;
    text-align: center;
    width: auto;
    padding-top: 100px;
    margin-bottom: 20px;
    right: 0;
    left: 0;
    z-index: 9;
    background: linear-gradient(to top, @color-white 0, @color-white 50px, rgba(0, 0, 0, 0) 100%);
    // always hidden, but not when hover
    @media (hover: none) {
      display: none;
    }
    @media (hover: hover) {
      .thumbnail:not(:hover) & {
        display: none;
      }
    }
    // see also .f-addBasketQuantity
  }
  // Not the same as &-saleRank
  &-sale-rank {
    height: 30px;
    width: 30px;
    background: @color-skyBlue;
    display: block;
    color: @color-white;
    text-align: center;
    line-height: 32px;
    border-radius: 30px;
    position: absolute;
    left: 15px;
    font-size: 14px;
    font-weight: bold;
    top: 40px;
  }
  // Not the same as &-sale-rank
  &-saleRank {
    position: absolute;
    width: 36px;
    top: 24px;
    left: 14px;
    font-size: 1em;
    line-height: 36px;
    text-align: center;
    background: @color-turquoise-hover;
    color: @color-white;
    border-radius: 50%;
    height: 36px;
    z-index: 1;

    .Article-itemGroup & {
      bottom: 12px;
      top: auto;
    }
  }
  // Thumbnail image
  &-imgWrap {
    text-align: center;
    position: relative;
    min-height: @thumbnail-img-height;
  }

  &-imgWrapper {
    position: relative;
  }

  &-imgContent {
    max-width: 100%;
    background-position: 50%;
    background-size: auto 100%;
    max-height: @thumbnail-img-height;
    background-repeat: no-repeat;
    width: auto;
  }

  &-energyLabel,
  &-greenLabel {
    position: absolute;
    bottom: 0;
    pointer-events: none;
  }
  &-energyLabel {
    left: 0;
    width: 44px;
  }
  &-greenLabel {
    right: 0;
    &--radius {
      background: @color-white;
      border-radius: @base-radius;
    }
  }

  &-seller {
    display: flex;
    align-items: center;
    column-gap: 5px;
    flex-wrap: wrap;

    a {
      color: @color-marketplace;
    }

    // Thumbnail expert push
    &-image {
      text-align: center;
      margin: 0 auto 15px;
      display: block;
      border-radius: 50%;
    }

    &-title {
      display: block;
      color: @color-orange;
      font-size: 1.1em;
    }

    &-name {
      color: @color-marketplace;
      font-weight: bold;

      &:hover {
        color: @color-marketplace;
      }
    }

    &-subtitle {
      display: block;
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 10px;
    }

    &-description {
      font-size: 1.1em;
    }

    &-fulfilled {
      height: 20px;
      display: flex;
      align-items: center;
      &-coma {
        margin-left: -5px;
      }
    }

    &-icon {
      font-size: 16px;
      color: @color-orange;
      &--fulfilled {
        font-size: 33px;
        margin-top: -2px;
        margin-left: 5px;
      }
    }
  }

  @thumbnail-name-gap: 5px;
  &-seller-name-content {
    display: flex;
    flex-wrap: nowrap;
    gap: @thumbnail-name-gap;
  }

  // Thumbnail article elements
  @thumbnail-desc-margin-top: 8px;

  &-desc {
    position: relative;
    margin-top: @thumbnail-desc-margin-top;
  }

  &-title {
    display: inline-block;
    font-size: 1.1em;
    height: @line-height-base * 3em;
    overflow: hidden;
  }

  &-titleLink {
    color: @color-dark;
    overflow: hidden;
    display: block;
    // Truncating Multiple Line Text
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;

    &:hover {
      text-decoration: underline;
    }

    &::before {
      content: '';
      display: block;
      height: @thumbnail-img-height;
      position: absolute;
      margin-bottom: @thumbnail-desc-margin-top;
      bottom: 100%;
      width: 100%;
    }
  }
  // subtitle
  &-sub {
    font-size: 1.1em;
    height: 36px;
    overflow: hidden;
    max-width: 100%;
    margin: 4px 0;
    background: @color-white;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    span::before {
      content: '- ';
    }

    &--serial {
      display: inline-block;
      width: 100%;

      & + span::before {
        content: '';
      }
    }

    span:first-child::before {
      content: '';
    }
  }

  &-rate {
    height: 18px;
    background: @color-white;
  }
  // Thumbnail price
  &-priceBlock {
    padding-top: 4px;
    background: @color-white;
  }

  &-price {
    margin-top: auto;
    font-size: 1.7em;
    color: @color-red;
    font-weight: bold;

    &--mp {
      color: @color-marketplace;
    }

    html[lang='fr-CH'] & {
      sup,
      &Old sup {
        font-size: 1em;
        top: 0;
      }
    }
  }

  &-moreOfferPrice {
    font-weight: bold;
    color: @color-marketplace;
  }

  &-numericalFormat {
    clear: both;

    .userPriceNumerical {
      font-weight: bold;
    }

    .userPriceNumerical-old {
      font-size: 0.8em;
    }
  }

  &-moreOffers {
    clear: both;

    &__kobo {
      height: 15px;
      vertical-align: top;
      width: 63px;
    }

    .f-faMpMoreOffers {
      & + .f-faMpMoreOffers {
        margin: 0;
      }
    }

    // FnacShop configurator page
    .layoutLight & {
      display: none;
    }
  }

  &-content {
    .epub {
      color: #9d9d9d;
    }

    .TinyClues-content & {
      position: relative;
      height: @thumbnail-height - 30px;
    }

    .blocPriceBorder {
      .member-price();
    }
  }

  // &-modelAvalaible {
  //   clear: both;
  // }
}

// Thumbnail price
// .f-numberModelAvailable-link {
//   color: @color-gray;
//   font-size: 11px;
// }

.f-addBasketQuantity {
  &-content {
    max-width: 150px;
    margin: 0 auto;
  }

  &-quantity,
  &-decrease,
  &-increase {
    transition: color 0.3s ease;
    border: 0;
    padding: 0;
    position: relative;
    float: left;

    &:hover {
      color: @color-dark;
    }
  }

  &-quantity {
    text-align: center;
    height: 25px;
    width: 80px;
    border: 1px solid @color-orange;
    font-size: 15px;
    line-height: 20px;
    color: @color-dark;
  }

  &-decrease,
  &-increase {
    background: none;
    width: 35px;
    height: 26px;
    font-size: 26px;
    line-height: 26px;
    color: @color-orange;
  }

  &-buyBox {
    padding: unit((2px / 12px), em) 0;
  }
}
