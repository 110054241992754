/*
inline text logos
UIResource can contains these logos, where the paths can't be computed (or not easly computed) when used by backend code
*/

// <span class="text-fnacplus">FnacPLUS</span>
.text-fnacplus {
  font-size: 0;
}

.text-fnacplus::before {
  content: url('../../../Nav/Images/fnacplus/logo-fnacPlus.svg');
  display: inline-block;
  width: 50px;
  line-height: 1;
  vertical-align: bottom;
}
