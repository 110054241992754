// SLIDENAV  ---------------------------------------- //
.SideNav {
  width: 200px;
  background: @color-white;
  border-right: 1px solid @color-silver-darker;
  will-change: transform;
  height: calc(100% - @header-nav);
  .customScrollbars();

  &.isActive {
    transform: translateX(0);
  }

  &.isAbove {
    z-index: 1030;
    display: none;
  }
}
