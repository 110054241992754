.f-header__account {
  background: @color-white;
  position: relative;
  padding: 25px;
}

.f-header__account-button {
  background-color: #4a4a4a;
  border: 1px solid transparent;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  transition: all 0.2s linear;
  overflow: hidden;
  padding: 6px 10px;
  width: 100%;
  color: @color-white;
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: @color-dark;
    outline: none;
    color: @color-white;
    text-decoration: none;
  }
}

.f-header__account-signup-link {
  background-color: @color-silver-lighter;
  display: block;
  padding: 25px;
  color: @color-dark;
  font-size: 13px;
  text-align: center;
}
