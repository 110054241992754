@import (reference) '../../../../../styles/_library.less';

@largePadding: 15px;
@smallPadding: 15px;
@smallScreen: 640px;

.popinReview {
  margin: 6px auto;
  background: @color-white;
  padding: 0;

  .mfp-close-btn-in & .mfp-close,
  button.mfp-close {
    color: @color-white;
  }

  &-header {
    background: @color-dark;
    color: @color-white;
    padding: @smallPadding @largePadding;
  }

  &-title {
    font-size: 26px;
    font-weight: 300;
    text-transform: uppercase;
  }

  &-productTitle {
    font-weight: 300;
    font-size: 15px;
  }

  &-Content {
    padding: @smallPadding @largePadding;
    text-align: right;
  }

  &-form {
    width: 100%;
    display: block;
    text-align: left;
  }

  &-label {
    font-weight: normal;
    font-size: 14px !important;
    margin: 15px 0 2px;
    border: 0;
  }

  &-input,
  &-textArea {
    .typoFnacRegular();
    padding: 10px;
    width: 100%;
    border: 1px solid @color-silver-dark;
    min-height: 40px;
    resize: vertical;

    &:focus {
      outline: 0;
      border: 1px solid @color-silver-dark;
    }
  }

  &-formGroup {
    margin-bottom: 15px;
    position: relative;
    border: 0;
  }

  @media (max-width: 640px) {
    &-submit {
      width: 100%;
    }
  }

  @star-bg: colorize(data-uri(icon('icon_i_097_Star')), #d8d8d8);
  @star-fg: colorize(data-uri(icon('icon_i_097_Star')), #f5b027);

  &-rating {
    position: relative;
    height: @size + 2 + 8; // 2 for the border + 8 for padding
    @padding: 4px;
    padding: @padding;
    border: 1px solid @color-silver-light;
    border-radius: 5px;
    flex-basis: 130%;

    @size: 34px;
    @z-index-label-hover: 1;

    .star-pseudo() {
      content: '';
      height: @size;
      position: absolute;
      background: repeat-x left / @size;
    }
    .star-bg-pseudo() {
      .star-pseudo();
      width: 5 * @size;
      background-image: @star-bg;
    }
    .text() {
      padding-left: 5 * @size;
      height: @size;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;
    }

    &::before {
      .star-bg-pseudo();
      top: @padding;
      left: @padding;
    }

    &__default-text {
      .text();
    }

    &__item {
      margin-top: -@size;

      &-input {
        position: absolute;
        width: @size;
        height: @size;
        border: 0;
        opacity: 0;
        appearance: none;
        border-radius: 0;
        top: @padding;
        z-index: @z-index-label-hover + 1;

        &:focus-visible {
          opacity: 1;
        }

        // Increase specificity to override form styles
        &:not(#someID) {
          display: block;
          margin: 0;
        }
      }

      &-label {
        position: relative;
        background: white;
        font-weight: normal;

        // Increase specificity to override form styles
        &:not(#someID) {
          .text();
          margin: 0;
        }

        // Increase specificity to override form styles
        &:not(#someID)::before,
        &:not(#someID)::after {
          top: 0;
          left: 0;
          margin: 0;
          display: block;
          border: 0;
          border-radius: 0;
        }
        &:not(#someID)::before {
          .star-bg-pseudo();
        }
        &:not(#someID)::after {
          .star-pseudo();
          background-image: @star-fg;
        }
      }

      &-input:not(:checked) + &-label {
        opacity: 0;
      }

      // PCD-2345
      // Hover effect only for devices that support it
      @media (hover: hover) {
        &-input:not(:checked):not(:hover) + &-label {
          opacity: 0;
        }
        &-input:hover + &-label {
          z-index: @z-index-label-hover;
        }
      }

      // This first child is default-text
      // Increase specificity to override form styles
      &:not(#someID):nth-child(2) &-input {
        left: @padding;
      }
      &:not(#someID):nth-child(2) &-label::after {
        width: @size;
      }
      &:not(#someID):nth-child(3) &-input {
        left: @padding + @size;
      }
      &:not(#someID):nth-child(3) &-label::after {
        width: @size * 2;
      }
      &:not(#someID):nth-child(4) &-input {
        left: @padding + @size * 2;
      }
      &:not(#someID):nth-child(4) &-label::after {
        width: @size * 3;
      }
      &:not(#someID):nth-child(5) &-input {
        left: @padding + @size * 3;
      }
      &:not(#someID):nth-child(5) &-label::after {
        width: @size * 4;
      }
      &:not(#someID):nth-child(6) &-input {
        left: @padding + @size * 4;
      }
      &:not(#someID):nth-child(6) &-label::after {
        width: @size * 5;
      }
    }
  }

  &-mentions {
    margin-top: 20px;
    font-size: 13px;
    color: @color-gray;
    text-align: left;

    a {
      color: @color-gray-darker;
    }
  }
}
