// thumbnail buttons
.thumbnail-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 36px; /* why default all button to be squared? */
  height: 36px;
  line-height: 29px;
  font-size: 16px;
  position: absolute; /* why default all button to be absolute? */
  cursor: pointer;
  z-index: 10;
  background: @color-orange;
  transform: translateZ(0);
  transition: 0.25s ease-out;
  transition-property: background-color, border-color, color, opacity;

  // &:hover,
  // &:focus {
  //   background: @color-orange-hover;
  //   color: @color-white;
  // }

  // For devices that support hover the button is hidden and shown on rollover
  // But there is some case (with .thumbnail--alwaysvisible) where we don't wan't that behavior
  // See miniFA.less
  @media (hover: hover) {
    .thumbnail:not(.thumbnail--alwaysvisible):not(:hover) & {
      display: none;
    }
  }

  // We need to keep this (and .thumbnail-btn) until Criteo update it's code that generate fake FA
  &Cart.thumbnail-btn {
    border: 0;
    right: 20px;
    left: 20px;
    // color: @color-white;
    height: 42px;
    border-radius: @base-radius;
    font-size: 13px;
    width: calc(100% - 40px);
    line-height: inherit;

    position: absolute;
    bottom: 20px;
    top: auto;
    overflow: hidden;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      // color: @color-white;
      text-decoration: none;
    }
  }

  // Tools (in thumbnail-toolbar)
  &Open {
    background: @color-white;
    color: @color-gray;
    border: 1px solid currentcolor;
    line-height: 34px;
    transition: color 0.25s ease-out;
    position: static;
    margin: 5px;

    &:hover,
    &:focus {
      background: @color-white;
      color: @color-dark;
    }

    // When "added", force color
    &--added {
      color: @color-dark;
      //cursor: default;
    }

    &Icon {
      flex: 0 0 auto;
      // Only write cases where the icon should be display none, instead of display block/none for all states
      .thumbnail-btnOpen:hover &--default,
      .thumbnail-btnOpen--added &--default,
      .thumbnail-btnOpen:not(:hover) &--add,
      .thumbnail-btnOpen--added &--add,
      .thumbnail-btnOpen:not(.thumbnail-btnOpen--added) &--added,
      .thumbnail-btnOpen--added:hover &--added,
      .thumbnail-btnOpen:not(.thumbnail-btnOpen--added) &--remove,
      .thumbnail-btnOpen--added:not(:hover) &--remove {
        display: none;
      }
    }
  }

  .f-icon {
    font-size: 16px;
  }
}
