.disable-global-scroll {
  overflow: hidden;
}

// .disable-maincontent-scroll {
//   .f-main-page {
//     height: 100%;
//     overflow: hidden;
//   }
// }
