@import (reference) '../../../styles/_library.less';

.f-kamino-billboard {
  display: flex;
  height: 300px;
  position: relative;

  .f-kamino-product {
    justify-content: flex-start;
    padding: 25px 30px;
    position: relative;
    width: 100%;
  }

  .f-kamino-dispo {
    color: @color-forestGreen;
    padding: 8px 10px;
    font-size: 14px;
    border: 1px solid @color-silver-darker;
    display: flex;
    align-items: center;
    margin-top: 10px;
    max-width: 200px;

    svg {
      margin-right: 6px;
    }
  }

  .f-kamino-video,
  .f-kamino-billboard__media-wrapper {
    max-height: 300px;
    height: 300px;
  }

  .f-kamino-visual {
    width: 30%;
    min-width: 30%;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      display: block;
      margin: auto;
    }
  }

  .f-kamino-subtitle {
    display: inline;
    margin-right: 5px;
  }

  .f-nCarousel--bullets {
    height: 16px;

    .f-nCarousel__itemInner {
      width: 10px;
      height: 10px;
      margin: 3px;
      min-height: auto;
    }
  }

  .f-kamino-title {
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: @color-black;
    text-wrap: wrap;
    overflow: hidden;
  }

  .f-kamino-buybox-price {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    &__price-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: end;
    }

    .thumbnail-greenLabel {
      right: 0;
      top: -64px;
      left: 0;
      width: 60px;
      height: 48px;
    }

    .price {
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
      color: #dd1e35;
      // justify-self: end;
    }
    .oldPrice {
      font-weight: 400;
      color: #232323;
    }
  }

  .miniFA__button.ff-button {
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    margin-top: 0;

    .ff-button-icon {
      margin-right: 0;
      font-size: 24px;
    }
  }

  .f-kamino-buybox {
    align-self: stretch;
    width: 100%;
    position: relative;
  }

  &__media-wrapper {
    aspect-ratio: 16 / 9;
  }

  &__media {
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    place-items: center;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--image-background-var);
      background-size: cover;
      background-position: center;
      filter: blur(50px);
      transform: scale(1.5);
    }
    img {
      object-fit: contain;
      min-width: 0;
      max-width: 100%;
      min-height: 0;
      max-height: 100%;
      width: auto;
      height: auto;
      z-index: 2;
    }
  }

  /* Carousel */
  .f-nCarousel {
    flex: 1;
    margin: 0;
    overflow-x: hidden;
    & when (@ismobile) {
      overflow-x: auto;
    }
    .nCarouselRoundedArrows();
    .nCarouselPositionArrows(10px);

    .f-kamino-product form {
      display: contents;
    }

    &__wrapper {
      width: 100%;
      height: 100%;
    }

    &--arrows {
      padding: 0;
      height: auto;
    }

    &__item {
      min-width: 100%;
    }
  }

  /* Sponsored */
  .f-kamino {
    &-sponsored {
      position: absolute;
      right: 5px;
      bottom: 2px;
      background: @color-white;
      display: flex;
      justify-content: flex-end;
      color: @color-gray;
      align-items: center;
      border: 0;
      align-self: flex-end;

      & when (@ismobile) {
        font-size: 12px;
      }

      &--icon {
        font-size: 18px;
        & when (@ismobile) {
          font-size: 12px;
        }
        margin-left: 5px;
      }
    }
  }

  @media (max-width: @screen-lg-min) {
    flex-direction: column;
    height: auto;
    width: 100%;

    .f-kamino-buybox-price {
      position: relative;
      margin-top: 60px;

      .thumbnail-greenLabel {
        top: -54px;
      }
    }
  }

  & when (@ismobile) {
    box-shadow: 0px 4px 4px 0px #00000040;
    width: calc(100% - 16px) !important;
    border-radius: 8px;
    margin: 8px;
    .f-kamino-product {
      padding: 16px 16px 30px;
    }

    .f-kamino-video-wrapper {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .f-kamino-video,
    .f-kamino-billboard__media-wrapper {
      max-height: none;
      height: unset;
      width: 100%;
    }

    .f-kamino-buybox-price {
      position: relative;
      flex-grow: 1;
      margin: unset;

      & .price {
        justify-self: start;
      }
      .thumbnail-greenLabel {
        right: 58px;
        top: auto;
        left: unset;
        width: auto;
        height: 32px;
      }
    }

    .f-kamino-buybox {
      display: flex;
      flex-direction: column;
    }
    // .f-kamino-buybox-price .oldPrice {
    //   align-self: flex-start;
    // }

    .miniFA__button.ff-button {
      right: 0;
      bottom: 0;
      position: absolute;
      left: unset;
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;

      .ff-button-icon {
        margin-right: 0;
        font-size: 16px;
      }
    }
  }
}

//FA
.f-productPage .f-kamino-billboard,
.FA .f-kamino-billboard {
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(69, 69, 69, 0.08);
  background-color: @color-white;
  margin: 30px auto 0;

  .f-kamino-product {
    padding: 16px 16px 30px;
  }

  .f-kamino-buybox {
    margin: 15px 0;
  }

  @media (max-width: @screen-lg-min) {
    max-width: 533.5px;
    justify-content: center;
    margin: 15px auto;

    .f-kamino-dispo {
      display: none;
    }
    .f-kamino-buybox {
      margin: unset;
    }
  }
}
