.f-breadcrumb {
  color: @color-gray;
  font-size: 12px;
  line-height: 1.5;
  padding-left: 30px;

  &--nospaced {
    padding-left: 0;
  }

  & + .home-title {
    padding-top: 0;
  }
}

.f-breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 10px 0;
}

.f-breadcrumb__item {
  display: inline-flex;

  &:not(:last-child)::after {
    content: '›';
    margin: 0 4px;
  }
}

.f-breadcrumb__current {
  overflow-wrap: anywhere;
}

.f-breadcrumb__link {
  color: inherit;
}
