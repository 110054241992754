// FOOTER ---------------------------------------- //
/*
The footer ES is more complete for places (awards + legal)
 */
.f-footer {
  @homepage-logo-size: 114px;

  .white-fade-link() {
    color: @color-white;
    transition: color 0.2s;

    &:hover,
    &:active,
    &:focus {
      color: fade(@color-white, 70%);
      text-decoration: none;
    }
  }

  &-homepage {
    position: absolute;
    left: 50%;
    top: 50%;
    max-height: @homepage-logo-size;
    height: 140%;
    width: @homepage-logo-size;
    display: block;
    transform: translate(-50%, -50%);
    .white-fade-link();

    &__icon {
      height: 100%;
      width: 100%;
    }
  }

  &__keypoint {
    display: inline-flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-size: 11px;

    &-icon {
      font-size: 38px;
      color: @color-dark;
      margin-bottom: 4px;
    }

    &-list {
      background: @color-silver-lighter;
      display: flex;
      list-style: none;
      padding: 25px 10px;
      justify-content: center;
    }

    &-li {
      flex: 0 1 20%;
      display: flex;
      justify-content: center;
    }
  }

  &__featured {
    position: relative;
    background: #ffb403;
    min-height: 92px;
    display: flex;
    flex-direction: column;
    @padding-v: 20px;
    @padding-h: 24px;

    &-link {
      @margin: 10px;

      display: inline-flex;
      align-items: center;
      padding: @padding-v - @margin @padding-h - @margin;
      transition: color 0.2s;
      position: relative;
      .white-fade-link();

      &-text {
        margin: @margin;
      }

      b {
        text-transform: uppercase;
      }

      &-icon {
        font-size: 40px;
        margin: @margin;
        flex-shrink: 0;

        &--lab {
          font-size: 70px;
        }

        &--fnac-shop {
          font-size: 60px;
        }
      }
    }

    &-network {
      @margin: 10px;

      &-link {
        display: inline-flex;
        align-items: center;
        .white-fade-link();
      }

      &-icon {
        font-size: 25px;
      }

      &-list {
        list-style: none;
        padding: 0;
        margin: -@margin;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      &-item {
        display: flex;
        align-items: center;
        margin: @margin;
      }
    }
    // Group of links (2 links per line)
    &-grp {
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;

      &--2 {
        background: #ffc63e;
      }

      &-item {
        // Add spacing between 2 element in same group, for the homepage link (logo)
        flex: 0 0 calc(50% - (@homepage-logo-size / 2));
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &--network {
          padding: @padding-h @padding-v;
        }

        &:last-child {
          flex-direction: row-reverse;
          text-align: right;
        }
      }
    }
  }

  @places-margin: 10px;

  &__places {
    color: @color-gray-dark;
    background: @color-dark;
    padding: 3.2rem 4.1rem 6.4rem; // need a extra bottom padding for the comparator bar

    &-grps {
      display: flex;
      margin: -@places-margin;
      justify-items: center;
      flex-wrap: wrap;

      &--logos {
        align-items: center;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    @places-grp-width: 160px;

    &-grp {
      margin: @places-margin;
      flex: 1 1 @places-grp-width; // each group should have the same width
    }

    &-title {
      text-transform: uppercase;
      font-weight: 100;
      font-size: 1.4rem;
      margin: @places-margin 0;
      color: @color-white;
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: @places-margin 0;
    }

    @places-lists-margin: (@places-margin / 2);

    &-lists {
      display: flex;
      margin: -@places-lists-margin;
      flex-wrap: wrap;

      &-item {
        margin: @places-lists-margin;
        flex: 1 1 @places-grp-width;
      }
    }
    // list item
    &-li {
      margin-bottom: 0.2em;
    }

    &-affix {
      &-text {
        display: inline-block;
        margin: 0;
      }

      &-list {
        list-style: none;
        padding: 0;
        display: inline-flex;
      }

      &-li {
        display: flex;
        align-items: center;

        &:nth-child(n + 2)::before {
          margin: 0 0.3em;
          content: ' - ';
        }
      }
    }

    &-link,
    &-button {
      color: @color-white;
      font-weight: normal;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;

      &:hover,
      &:active,
      &:focus {
        color: @color-white;
      }
    }

    &-button {
      appearance: none; // rollback normalize.less
      // reset browser styles
      background: none;
      border: 0;
      padding: 0;
      border-radius: 0;

      &:hover {
        text-decoration: underline;
      }
    }

    &-symbol {
      width: 24px;
      height: 16px;
      margin-right: 4px;
    }

    &-acceo {
      line-height: 16px;

      .f-footer__places-symbol {
        width: 16px;
      }
    }
  }

  &__payment-mean {
    @margin: 7px;

    font-size: 35px;
    color: @color-white;

    &-large {
      width: 3em;
    }

    &-link {
      margin: (20px - @margin) (-@margin);
    }

    &-list {
      flex: 1 1 auto;
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      &.f-footer__places-grp {
        margin: @places-margin - @margin;
      }
    }

    &-li {
      margin: @margin;
    }

    &-more {
      flex: 1 1 auto;
      margin: 0 0 0 10px;
    }
  }

  &__award {
    @margin: 7px;
    margin: @margin;

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;

      &.f-footer__places-grp {
        margin: @places-margin - @margin;
      }
    }
  }

  &__legal {
    font-size: 10px;
  }
}

/*
Special custom logo 
*/
html[lang='fr-FR'] .f-footer:not(.f-footer--pro) {
  @homepage-logo-custom: 153px;
  .f-footer-homepage {
    max-height: unset;
    height: unset;
    width: @homepage-logo-custom;
    /*@media (max-width: @screen-tablet-landscape) {
      width: 170px;
    }*/
  }
  .f-footer__featured {
    background: #141414;
    &-grp {
      &-item {
        flex: 0 0 calc(50% - (@homepage-logo-custom / 2));
        /*@media (max-width: @screen-tablet-landscape) {
          flex: 0 0 calc(50% - 85px);
        }*/
      }
    }
    &-grp--2 {
      background: #141414;
    }
  }
}
