//
// Typography
// --------------------------------------------------


// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: bold;
  line-height: 1.1;
  color: inherit;

  small,
  .small {
    font-weight: normal;
    line-height: 1;
    color: @color-gray;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: @line-height-computed;
  margin-bottom: (@line-height-computed / 2);

  small,
  .small {
    font-size: 65%;
  }
}
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: (@line-height-computed / 2);
  margin-bottom: (@line-height-computed / 2);

  small,
  .small {
    font-size: 75%;
  }
}

h1, .h1 { font-size: @font-size-h1; }
h2, .h2 { font-size: @font-size-h2; }
h3, .h3 { font-size: @font-size-h3; }
h4, .h4 { font-size: @font-size-h4; }
h5, .h5 { font-size: @font-size-h5; }
h6, .h6 { font-size: @font-size-h6; }


// Body text
// -------------------------

p {
  margin: 0 0 (@line-height-computed / 2);
}


// Emphasis & misc
// -------------------------

// Ex: 14px base font * 85% = about 12px
small,
.small  { font-size: 85%; }

// Undo browser default styling
cite    { font-style: normal; }

// Alignment
.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }


// Lists
// --------------------------------------------------

// Unordered and Ordered lists
ul,
ol {
  margin-top: 0;
  margin-bottom: (@line-height-computed / 2);
  ul,
  ol {
    margin-bottom: 0;
  }
}

// Description Lists
dl {
  margin-top: 0; // Remove browser default
  margin-bottom: @line-height-computed;
}
dt,
dd {
  line-height: @line-height-base;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0; // Undo browser default
}

// MISC
// ----

/* Abbreviations and acronyms */
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted @color-gray;
}

// Blockquotes
blockquote {
  padding: (@line-height-computed / 2) @line-height-computed;
  margin: 0 0 @line-height-computed;
  font-size: @blockquote-font-size;
  border-left: 5px solid @color-silver-light;

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // Note: Deprecated small and .small as of v3.1.0
  // Context: https://github.com/twbs/bootstrap/issues/11660
  footer,
  small,
  .small {
    display: block;
    font-size: 80%; // back to default font-size
    line-height: @line-height-base;
    color: @color-gray;

    &::before {
      content: '\2014 \00A0'; // em dash, nbsp
    }
  }
}

// Quotes
blockquote::before,
blockquote::after {
  content: "";
}

// Addresses
address {
  margin-bottom: @line-height-computed;
  font-style: normal;
  line-height: @line-height-base;
}
