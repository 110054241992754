@import (reference) '../../styles/_library.less';

.f-kamino-sponsored-popin {
  display: none;
  padding: 0;

  h1 {
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid @color-silver-darker;
    color: @color-orange;
    font-size: 20px;
    font-weight: 600;
  }

  &-content {
    padding: 0 20px 15px;
    font-size: 14px;

    h2 {
      font-size: 16px;
      line-height: 18px;
    }
    h3 {
      font-size: 14px;
      line-height: 16px;
    }

    h2,
    h3 {
      font-weight: 700;
      margin-top: 17px;
      margin-bottom: 8.5px;
    }

    ul {
      list-style-type: disc;
      padding: 0 0 0 20px;
      margin: 5px 0;
    }

    a {
      text-decoration: underline;
      color: @color-dark-lighter;
    }
  }

  .mfp-container .mfp-content > & {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    border-radius: 3px;
    border: 1px solid @color-silver-darker;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 0;
  }
}
