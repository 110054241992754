.seo-text {
  color: @color-gray;
  font-size: 13px !important;
  font-family: @typoFnac;
  padding: 15px 30px;

  @media(max-width: 767px) {
    display: none;
  }

  font[size] {
    font-size: 13px;
  }

  font[face] {
    font-family: @typoFnac;
  }

  a {
    color: @color-dark;
    text-decoration: underline;
  }

  p {
    &:empty, &:blank, &:-moz-only-whitespace {
      display: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
