// --------
// STARS LABO FNAC

// HOW TO USE IT
//
// Example      : f-laboStar f-laboStar--small L3
//
// BASE         : f-laboStar
// SIZE         : f-laboStar--small (small, medium, large)
// RATE         : Lx (added by backend)

// STARS BACKGROUNDS
@starlabo-bg: colorize(data-uri(icon('star-circle')), @color-silver-darker);
@starlabo-fg: colorize(data-uri(icon('star-circle')), @color-blueLabo);

// BASE
.f-laboStar {
  overflow: hidden;
  width: 5em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 15px;
  height: 1em;
  white-space: nowrap;
  text-indent: 100%;
  line-height: 1;

  &::before,
  &::after {
    content: '';
    background-image: @starlabo-bg;
    background-repeat: repeat-x;
    background-size: 1em;
    height: 1em;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &::after {
    background-image: @starlabo-fg;
    width: 0;
    overflow: hidden;
  }

  // SIZE
  &--small {
    font-size: 16px;
  }

  &--medium {
    font-size: 21px;
  }

  &--large {
    font-size: 30px;
  }

  // RATE
  &.L1::after {
    width: 20%;
  }

  &.L2::after {
    width: 40%;
  }

  &.L3::after {
    width: 60%;
  }

  &.L4::after {
    width: 80%;
  }

  &.L5::after {
    width: 100%;
  }
}
