.strate {
  .best-sells,
  .eventList {
    @media (max-width: 1280px) {
      padding: 10px 0 0;
    }
  }

  &.list {
    h2 {
      float: left;
      margin-right: 20px;

      @media (max-width: 1280px) {
        width: 100%;
      }

      @media (max-width: 992px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &.tabbed {
      .tabList {
        float: left;
      }
    }
  }

  // &.strateHistory {
  //   .Strate-allBtns {
  //     min-width: 355px;
  //     float: right;

  //     @media (max-width: 992px) {
  //       min-width: 355px;
  //       float: right;

  //       .seeAll-bt {
  //         float: right;
  //         display: inline-block;
  //       }
  //     }

  //     @media (max-width: 600px) {
  //       float: none;

  //       .seeAll-bt {
  //         display: inline-block;
  //         float: none;
  //         text-align: center;
  //         position: static;
  //         width: 40%;
  //         padding: 0;
  //       }
  //     }
  //   }
  // }

  .List h2,
  .best-sells {
    float: left;
    position: relative;
    height: 40px;
  }
}
