.f-header__store-title {
  font-size: 21px;
  padding: 0 0 20px;
}

.f-header__store-header {
  padding: 0 0 20px;
}

.f-header__store-advantages {
  background-color: #434343;
  padding: 0 25px;
  color: @color-white;
  margin-bottom: 20px;
  border-radius: @base-radius;
}

.f-header__store-advantages-list {
  padding: 10px 0;
}

.f-header__store-advantages-item {
  border-top: 1px solid #5f5f5f;
  padding: 25px 0;
  font-size: 13px;
  list-style: none;

  &:first-child {
    border-top: 0;
  }

  &:empty {
    display: none;
  }

  .f-header__store-advantages-title {
    color: @color-orange;
    font-weight: bold;
  }
}

.f-header__store-geoloc {
  &-fields {
    margin-bottom: 20px;
    position: relative;
    font-size: 12px;
  }

  &-input {
    background-color: @color-silver-lighter;
    border: 0;
    padding: 11px 50px 11px 15px;
    width: 100%;
    color: #5f5f5f;
  }

  &-submit {
    background: 0;
    border: 0;
    bottom: 0;
    outline: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;

    &-icon {
      color: @color-orange;
      font-size: 24px;
    }
  }
}

.f-header__store-infos {
  background-color: @color-silver-lighter;
  padding: 15px 25px;
  color: @color-white;
  margin-bottom: 20px;
}

.f-header__store-opening-icon {
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 8px;

  &.opened {
    background: @color-forestGreen;
  }

  &.closed {
    background: @color-red;
  }

  &.drive {
    background: @color-warning;
  }
}

.f-header__store-drive {
  color: @color-warning;
}

.f-header__store-openings {
  font-size: 13px;
}

.f-header__store-address {
  font-size: 13px;
}

.f-header__store-dayslist,
.non-legacy .f-header__store-dayslist {
  margin-bottom: 10px;
  padding: 0;
}

.f-header__store-day {
  color: @color-black;
  font-size: 0; // inline-block hack
  line-height: 18px;
  list-style: none;
}

.f-header__store-day-name {
  display: inline-block;
  width: 50%;

  font-size: 13px;
  font-weight: bold;
}

.f-header__store-day-time {
  display: inline-block;
  width: 50%;

  font-size: 13px;
  text-align: right;
}

.f-header__store-opening-details {
  // LINK
  display: block;
  color: @color-white;

  &--hours {
    &::before {
      content: '';
      display: block;
      height: 14px;
    }
  }

  &--events {
    &::before {
      content: '';
      display: block;
      height: 14px;
    }
  }
}

.f-header__store-outstanding,
.non-legacy .f-header__store-outstanding {
  display: inline-block;
  overflow: hidden;
  width: 100%;

  color: @color-forestGreen;
  font-size: 13px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;

  .f-header__store-outstanding-link {
    color: @color-forestGreen;
  }
}

.f-header__store-details-events,
.non-legacy .f-header__store-details-events {
  display: block;
  margin-top: 15px;
  color: @color-dark;
  font-weight: bold;
  font-size: 13px;

  &-icon {
    margin-right: 8px;
    font-size: 24px;
    vertical-align: bottom;
  }
}
