// SideNav-extra ---------------------------------------- //
.SideNav-extra {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    background: @color-silver-lighter;
    max-height: inherit;
    padding-left: 8px;
    line-height: 39px;
    // border-bottom: 1px solid @color-silver-darker;

    &:hover {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        height: 39px;
        width: 4px;
        background-color: @color-dark;
      }
    }

    html[lang*='-CH'] & {
      &:first-of-type {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          height: 39px;
          width: 4px;
          background-color: @color-gold;
        }
      }
    }

    html[lang*='-ES'] & {
      &:first-child {
        background-color: @color-gold;

        &:hover {
          background-color: @color-gold-hover;
        }
      }
    }

    .SideNav-extra__item-button {
      float: right;
      width: 30px;
      text-align: center;
      cursor: pointer;
      margin-right: 5px;

      .f-icon {
        font-size: 0.8em;
        margin-top: -2px;
      }
    }
  }

  &__item-icon {
    display: inline-block;
    color: @color-dark;
    font-size: 1.5em;
    margin-right: 10px;
    max-width: 20px;
  }

  &__item-link {
    color: @color-dark;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.CategoryNav-moreContentNav {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}

.CategoryNav-subContentItem {
  color: @color-dark;
  line-height: 20px;
  border-left: 5px solid transparent;
  padding: 5px 0 5px 8px;

  &:hover {
    border-left: 5px solid @color-dark;
    background: @color-silver-lighter;
    color: @color-dark;
  }

  a {
    color: @color-dark;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      text-decoration: none;
    }
  }
}

// Style for open close menu  ---------------------------------------- //
.js-moreContentNav-less,
.js-moreContentNav-plus {
  display: none !important;
}

.js-moreContentNav--active {
  display: inline !important;
}
