// See JavaScript usage of tether-drop

.drop-element,
.drop-element::after,
.drop-element::before,
.drop-element *,
.drop-element *::after,
.drop-element *::before {
  box-sizing: border-box;
}

.drop-element {
  position: absolute;
  display: none;
}

.drop-element.drop-open {
  display: block;
}

.drop-theme-arrows {
  max-width: 100%;
  max-height: 100%;
}

.drop-theme-arrows .drop-content {
  border-radius: 5px;
  position: relative;
  font-family: inherit;
  background: @color-white;
  border: 1px solid #efefef;
  color: #444;
  padding: 1em;
  font-size: 1.1em;
  line-height: 1.5em;
  transform: translateZ(0);
  /*filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));*/
}

.drop-theme-arrows .drop-content::before {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-width: 16px;
  border-style: solid;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-center .drop-content {
  margin-bottom: 16px;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-center .drop-content::before {
  top: 100%;
  left: 50%;
  margin-left: -16px;
  border-top-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-center .drop-content {
  margin-top: 16px;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-center .drop-content::before {
  bottom: 100%;
  left: 50%;
  margin-left: -16px;
  border-bottom-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-right.drop-element-attached-middle .drop-content {
  margin-right: 16px;
}

.drop-theme-arrows.drop-element-attached-right.drop-element-attached-middle .drop-content::before {
  left: 100%;
  top: 50%;
  margin-top: -16px;
  border-left-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle .drop-content {
  margin-left: 16px;
}

.drop-theme-arrows.drop-element-attached-left.drop-element-attached-middle .drop-content::before {
  right: 100%;
  top: 50%;
  margin-top: -16px;
  border-right-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom .drop-content {
  margin-top: 16px;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-left.drop-target-attached-bottom
  .drop-content::before {
  bottom: 100%;
  left: 16px;
  border-bottom-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom .drop-content {
  margin-top: 16px;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-right.drop-target-attached-bottom
  .drop-content::before {
  bottom: 100%;
  right: 16px;
  border-bottom-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top .drop-content {
  margin-bottom: 16px;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-top
  .drop-content::before {
  top: 100%;
  left: 16px;
  border-top-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top .drop-content {
  margin-bottom: 16px;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-top
  .drop-content::before {
  top: 100%;
  right: 16px;
  border-top-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left .drop-content {
  margin-right: 16px;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-right.drop-target-attached-left
  .drop-content::before {
  top: 16px;
  left: 100%;
  border-left-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right .drop-content {
  margin-left: 16px;
}

.drop-theme-arrows.drop-element-attached-top.drop-element-attached-left.drop-target-attached-right
  .drop-content::before {
  top: 16px;
  right: 100%;
  border-right-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left .drop-content {
  margin-right: 16px;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-right.drop-target-attached-left
  .drop-content::before {
  bottom: 16px;
  left: 100%;
  border-left-color: #efefef;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right .drop-content {
  margin-left: 16px;
}

.drop-theme-arrows.drop-element-attached-bottom.drop-element-attached-left.drop-target-attached-right
  .drop-content::before {
  bottom: 16px;
  right: 100%;
  border-right-color: #efefef;
}
