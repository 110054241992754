.f-ad {
  display: flex;
  align-content: center;
  justify-content: center;

  &__text {
    color: #757575;
    font-size: 0.7em;
    font-weight: normal;
    height: 17px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
  }
}
