@import (reference) '../../styles/_library.less';
// Carousel ---------------------------------------- //

//fix global position arrow carousel
.Carousel-arrow--right {
  right: 20px;

  html[lang*='-BE'] .Carousel--outsideNav.needHelp &,
  html[lang*='-BE'] .Carousel--outsideNav.suggestion--product & {
    right: 20px;
  }
}

.Carousel-arrow--rightFull {
  right: 10px;
}

.Carousel-arrow--left {
  left: 20px;

  html[lang*='-BE'] .Carousel--outsideNav.needHelp &,
  html[lang*='-BE'] .Carousel--outsideNav.suggestion--product & {
    right: 60px;
  }
}

.Carousel-arrow--leftFull {
  left: 10px;
}

//styles global
.Carousel {
  position: relative;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  &--full {
    overflow: visible;
  }

  .Carousel-arrow {
    display: flex;
    align-items: center;
    justify-content: center;

    // Devices that can't hover, hide arrows
    @media (hover: none) {
      display: none;
    }
  }

  &:hover,
  .Player & {
    .Carousel-arrow {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .Carousel-dotListItem {
      display: inline-block;
    }
  }
}

.Carousel--sponsor {
  .thumbnail-sponsor {
    left: 20px;
  }

  .Carousel-overflowContainer {
    padding: 10px;
    background: #efefef;
  }

  .Carousel-item {
    border: 10px #efefef solid;
    background: @color-white;
  }

  .thumbnail {
    border: 0;
  }
}

.Carousel-overflowContainer {
  overflow: hidden;
  margin: 0 30px;
  position: relative;
  -webkit-overflow-scrolling: touch;

  &--noMargin {
    margin: 0;
  }
}

.Carousel-container {
  font-size: 0;
  min-height: 0;
  position: relative;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
  transition: 0.1s ease-out all, 0.1s ease-in-out transform;
  user-select: none;
  white-space: nowrap;
  backface-visibility: hidden;

  &&--activeTouch {
    transition: 0.3s ease-in-out left;
  }

  &&--frozen {
    transition: none;
  }

  &&--loading {
    min-height: 455px; // thumbnail height
  }

  &&--inactive {
    white-space: normal;
    text-align: center;
  }
}

// Default item in DEFAULT viewport (4 items) TO remove after implementing js-NCarousel
.Carousel-item:not(.f-nCarousel__itemInner) {
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  width: (100% * 4.5);

  .Carousel-item--Expert {
    background: @color-silver-lighter;
  }

  img {
    max-width: 100%;
  }

  // Default item in LARGE viewport (4 items)
  @media (min-width: 1281px) {
    width: (100% * 4.5);
  }
  // Default item in XLARGE viewport (5 items)
  @media (min-width: 1600px) {
    width: (100% / 5.5);
  }
  // DEFAULT item in MEDIUM viewport (3 items)
  @media (max-width: 1110px) {
    width: (100% / 3.5);
  }
  // DEFAULT item in MEDIUM viewport (2 items)
  @media (max-width: @screen-md-min) {
    width: (100% / 2.5);
  }
}

.Carousel--XLItem .Carousel-item {
  width: 100%;
}

// L item in DEFAULT viewport (2 items)
.Carousel--LItem .Carousel-item {
  width: (100% / 2);
  // L item in XLARGE viewport (3 items)
  @media (min-width: 1600px) {
    width: (100% / 3);
  }
  // L item in LARGE viewport (3 items)
  @media (min-width: 1281px) {
    width: (100% / 3);
  }
  // L item in MEDIUM viewport (1 item)
  @media (max-width: @screen-md-min) {
    width: 100%;
  }
}

// LM item in DEFAULT viewport (3 items)
.Carousel--LMItem .Carousel-item {
  width: (100% / 3);
  // LM item in XLARGE viewport (3 items)
  @media (min-width: 1600px) {
    width: (100% / 3);
  }
  // LM item in LARGE viewport (3 items)
  @media (min-width: 1281px) {
    width: (100% / 3);
  }
  // LM item in MEDIUM viewport (1.5 item)
  @media (max-width: @screen-md-min) {
    width: (100% * 2 / 3);
  }
}

// M item in DEFAULT viewport (3 items)
.Carousel--MItem .Carousel-item {
  width: (100% / 3);
  // M item in XLARGE viewport (2 items)
  @media (max-width: 1600px) {
    width: (100% / 2);
  }
  // M item in LARGE viewport (2 items)
  @media (max-width: @screen-lg-min) {
    width: (100% / 2);
  }
  // M item in MEDIUM viewport (1 item)
  @media (max-width: @screen-md-min) {
    width: 100%;
  }
}

// SMALL item in DEFAULT viewport (4 items)
.Carousel--SMALLItem .Carousel-item {
  width: (100% / 4); // SMALL item in XLARGE viewport (4 items)
  @media (max-width: 1600px) {
    width: (100% / 4);
  }
  // SMALL item in LARGE viewport (4 items)
  @media (max-width: @screen-lg-min) {
    width: (100% / 4);
  }
  // SMALL item in MEDIUM viewport (3 items)
  @media (max-width: @screen-md-min) {
    width: (100% / 3);
  }
  // SMALL item in MEDIUM viewport (2 items)
  @media (max-width: 800px) {
    width: (100% / 2);
  }
}

// XS item in DEFAULT viewport (6 items)
.Carousel--XSItem .Carousel-item {
  width: (100% / 6);
  // XS item in XLARGE viewport (5 items)
  @media (max-width: 1600px) {
    width: (100% / 5);
  }
  // XS item in LARGE viewport (4 items)
  @media (max-width: @screen-lg-min) {
    width: (100% / 4);
  }
  // XS item in MEDIUM viewport (3 items)
  @media (max-width: @screen-md-min) {
    width: (100% / 3);
  }
}

// 3 items in ALL viewport (3 items)
.Carousel--TripleItem .Carousel-item {
  width: (100% / 3);
}

// 2 items in ALL viewport (2 items)
.Carousel--DualItem .Carousel-item {
  width: 50%;
}

.Carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  color: @color-white;
  background: rgba(@color-dark, 0.8);
  border: 0;
  display: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(@color-black, 0);

  i {
    display: inline-block;
    width: 10px;
    margin-left: -2px;
  }

  .f-icon {
    display: inline-block;
    width: 12px;
    margin-left: -2px;
  }

  &--right .f-icon,
  &--rightFull .f-icon {
    margin: 0;
  }

  &:disabled {
    opacity: 0.2;
  }

  &:hover {
    background: rgba(@color-dark, 0.95);
  }
  // Remove arrow when you can't slide
  // TODO: refactor this
  .js-Carousel-disableArrow & {
    display: none !important;
  }
}

// Carousel Dots ---------------------------------------- //
.Carousel-dotList {
  position: absolute;
  bottom: 0;
  left: 50%;
  list-style: none;
  text-align: center;
  margin: 0;
  padding: 0;
  width: 50%;
  margin-left: -25%;

  @media (max-width: 1200px) {
    display: none;
  }
}

.Carousel-dotListItem {
  overflow: hidden;
  text-align: center;
  opacity: 0.6;
  display: none;

  button {
    display: inline-block;
    margin: 10px 5px;
    width: 8px;
    height: 8px;
    padding: 0;
    background: @color-dark;
    border-radius: 50%;
    border: 0;
    text-indent: -9999px;
  }

  &.js-Carousel-dotListItem--active {
    button {
      background: @color-white;
    }
  }
}

// Outside nav properties
// TODO refactor that shit
// careful to /locale/ versions

// .Carousel--outsideNav {
//   .productStrateTop {
//     padding: 3em 220px 3em 30px !important;

//     @media (max-width: @screen-md-min) {
//       padding: 3em 30px 3em 30px !important;
//     }
//   }

//   .Strate-top {
//     padding-right: 220px;

//     @media (max-width: 1280px) {
//       padding-right: 30px;
//     }
//   }

//   // Specific rules for strate history
//   &.strateHistory {
//     .seeAll-bt {
//       display: block;
//     }
//     // /!\ There is a nl-BE override
//     .Strate-top {
//       padding-right: 400px;

//       @media (max-width: 768px) {
//         padding-right: 30px;
//       }
//     }
//   }
//   // Specific rules for strate with list dropdown
//   &.list {
//     @media (max-width: 1280px) {
//       .Strate-top .seeAll-group {
//         position: absolute;
//         right: 30px;
//         top: 2.4em;
//       }
//     }
//   }
//   // Specific rules for strate with tabss
//   &.tabbed {
//     .Strate-top .seeAll-group {
//       position: absolute;
//       right: 30px;
//       top: 2.4em;
//     }
//   }
// }
