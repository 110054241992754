.expertPhoto {
  background: @color-dark;
  color: @color-white;
  padding-top: 10px;
  padding-bottom: 30px;

  .Strate-top {
    margin: 5px 0 15px;
  }
}

.expertPhoto-thumb {
  display: inline-block;
  margin-right: -0.25em;
  width: 100%;
  text-align: center;
}

.expertPhoto-wrap {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
}

.expertPhoto-image {
  width: 160px;
  height: 160px;
  background-size: cover;
  background-position: center;
}

.expertPhoto-title {
  color: @color-gold;
  margin: 1.5em auto 0;
  max-width: 8.23529em;
  font-size: 1.7em;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.expertPhoto-subtitle {
  margin-top: 5px;
  font-size: 1.4em;
  display: block;
  color: @color-white;
}

.expertPhoto-desc {
  margin-top: 10px;
  font-size: 1em;
  text-decoration: underline;
  display: block;
  color: @color-white;
}

.expertPhoto-content {
  position: relative;
}

.expertPhoto-link {
  transform: translateZ(0);
  position: relative;
  overflow: hidden;
  .typoFnacLight();
  display: inline-block;
  color: @color-white;
  width: 160px;
  height: 160px;
  text-align: center;
  border-radius: 50%;
  background-position: center;
  margin: 0 auto;
  vertical-align: middle;
  text-decoration: none;

  &:hover::before {
    display: block;
  }

  &::before {
    content: '';
    display: none;
    width: 160px;
    height: 160px;
    background: @color-dark;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:hover,
  &:focus {
    text-decoration: none;

    .expertPhoto-wrap {
      display: block;
    }
  }
}
