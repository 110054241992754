@import (reference) '../../styles/_library.less';

.f-flashMessage {
  background: @color-silver-light;
  border-top: 1px solid @color-silver-darker;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 15px 35px 15px 15px;
  transform: translateY(100%);
  z-index: 9999;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform, opacity;
  opacity: 0;

  @media (min-width: 480px) {
    padding-right: 50px;
    padding-left: 50px;
  }

  &--visible {
    transform: translateY(0);
    opacity: 1;
  }

  &-title {
    color: @color-red;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-right: 0.5em;
  }

  &-close {
    position: absolute;
    right: 5px;
    top: 15px;
    font-size: 15px;
    border: 0;
    background: 0;

    @media (min-width: 480px) {
      right: 20px;
    }
  }

  &-icon {
    font-size: 18px;
    margin-top: -2px;
  }
}
