.thumbnail-price,
.userPrice--epub {
  .icon--epub,
  .userPriceNumerical,
  .userPriceNumerical-old {
    color: @color-skyBlue;
  }
}

.thumbnail-price.userPriceNumerical {
  color: @color-skyBlue;
}

.smallPricerFA .userPrice {
  color: @color-red;
}
