//
// Scaffolding
// --------------------------------------------------

// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

// Body reset
html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

body {
  font-family: @typoFnac;
  font-size: @font-size-base;
  line-height: @line-height-base;
  color: @color-dark-lighter;
  background-color: @color-white;
  overflow-x: hidden;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Links
a {
  color: @color-gray-dark;
  text-decoration: none;

  // &:focus,
  &:hover {
    color: @color-gray-darker;
    text-decoration: underline;
  }

  // &:focus {
  //   .tab-focus();
  // }
}

// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.
figure {
  margin: 0;
}

// Images
img {
  vertical-align: middle;
}

// Horizontal rules
hr {
  margin-top: @line-height-computed;
  margin-bottom: @line-height-computed;
  border: 0;
  border-top: 1px solid @color-silver-light;
}
