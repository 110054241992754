// ----------------------------------------
// MAIN
// ----------------------------------------
@mkt-header-height: 41px;
@main-padding-left: 200px;
@main-padding-top: @header-height + @mkt-header-height;

.Main {
  position: relative;
  left: 0;
  min-width: 100%;
  padding-top: @main-padding-top;

  @media (min-width: @screen-sm-plus) {
    padding-left: @main-padding-left;
  }

  // &--fixed {
  //   padding-bottom: 698px;
  // }

  // &.isActive--inverse {
  //   transform: translate(-260px);

  //   @media (min-width: @screen-sm-plus) {
  //     transform: translate(0px);
  //   }
  // }
}

// .Main--adherent {
//   position: relative;
// }

.Main--fullWidth {
  padding-left: 0;
}

// TODO
// .Main--grey {
//   background: @color-silver-lighter;
//   border-bottom: 1px solid @color-silver-dark;
// }

// ----------------------------------------
// Header Bottom
// ----------------------------------------
.Main-marketingHeader {
  .container-fixed();
  position: absolute;
  top: @header-height;
  left: 0;
  right: 0;
  display: none;
  height: @mkt-header-height;
  background: @color-white;
  border-bottom: 1px solid @color-silver-darker;
  z-index: 10;

  @media (min-width: @screen-sm-plus) {
    display: block;
  }

  .home &,
  .home-page &,
  .search &,
  .js-Adherent & {
    left: 200px;
  }
}

.home-page,
.home {
  .Main-marketingHeader {
    right: 0;
    width: auto;
  }
}

.Marketing-banner {
  display: none;
  float: right;
  margin-right: 10px;
  overflow: hidden;

  @media (min-width: @screen-md-min) {
    display: block;
    height: 40px;
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

// spec b2b
.b2bRefGU {
  color: @color-orange;
  font-weight: bold;
}

// .Main-reduce-paddingtop-mobile {
//   padding-top: 30px !important;

//   @media (min-width: @screen-sm-plus) {
//     padding-top: 0;
//   }
// }
