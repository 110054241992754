// flashSales ---------------------------------------- //
.flashSales-title {
  color: @color-red;
}

.flashSales-moreContainer {
  padding: 2.5em 0;
  text-align: center;
}

//.flashSales-more {
//}

.flashSales-more--disabled {
  display: none;
}

.flashSales-product {
  float: left;
  margin-top: -1px;

  .tooltip {
    &.top .tooltip-arrow {
      border-top-color: @color-red;
    }

    .tooltip-inner {
      background: @color-red;
      color: @color-white;
      white-space: nowrap;
    }
  }

  @media (max-width: @screen-md-min) {
    width: 50%;

    .tooltip {
      display: none !important;
    }
  }

  @media (min-width: @screen-md-min) and (max-width: 1280px) {
    width: (100% / 3);
  }

  @media (min-width: 1281px) {
    width: 25%;
  }

  @media (min-width: 1600px) {
    width: 20%;
  }
}
