@import (reference) '../../styles/_library-mobile.less';
@import '../../Nav/Css/Less/common/components/miniFA.less';

.vCarousel__container {
  overflow: hidden;
  padding-top: calc(100% * 4 / 3); /* 3:4 Aspect Ratio (133.33%) */
  position: relative;
  width: 100%;

  &::before {
    background: linear-gradient(180deg, rgba(@color-black, 0) 0%, rgba(@color-black, 0.99) 100%, @color-black 100%);
    bottom: 0;
    content: '';
    height: 320px;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
}

.vCarousel__controls {
  align-items: center;
  color: @color-white;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  margin: 0 15px;
  pointer-events: none;
  right: 0;
  bottom: 135px;
  z-index: 3;
}

.vCarousel__btn {
  color: @color-white;
  font-size: 18px;
  line-height: 1;
  pointer-events: auto;
}

.vCarousel__controls_nav {
  align-items: center;
  display: flex;
  margin: 0 auto;
}

.vCarousel__playpause {
  pointer-events: auto;
}

.vCarousel__playpause_label {
  border-color: transparent transparent transparent @color-white;
  border-style: double;
  border-width: 0 0 0 10px;
  display: block;
  height: 12px;
  outline: 0;
  transition: 0.1s ease;
  width: 0;
}

.vCarousel__playpause_input {
  position: absolute;
  left: -9999px;

  &:checked + .vCarousel__playpause_label {
    border-style: solid;
    border-width: 6px 0 6px 10px;
  }
}

.vCarousel__nav {
  letter-spacing: 1px;
  margin: 0 20px;
}

.vCarousel__video {
  height: 100%;
  object-fit: fill;
  object-position: center;
  pointer-events: none;
  width: 100%;
}

.vCarousel__slides {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  padding: 0;
}

.vCarousel__cards {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  z-index: 3;
  height: 115px;
}

.vCarousel__card {
  align-items: center;
  background: @color-white;
  border-radius: @base-radius;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05), 0 5px 8px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  height: 100px;
  justify-content: space-between;
  overflow: hidden;
  padding: 16px;
  position: absolute;
  transition: transform 0.25s;
  width: 100%;
  z-index: 3;

  &.hidden {
    transform: translateY(115px);
  }

  .miniFA__priceList {
    height: auto;
  }

  .miniFA__priceLabel {
    font-weight: normal;
  }

  .miniFA__amount {
    font-size: 21px;

    sup {
      font-size: inherit;
      vertical-align: baseline;
    }
  }
}

.vCarousel__card_meter {
  background: @color-orange;
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.1s linear;
  will-change: transform;

  &.progress {
    animation: 5s progressBar linear forwards;
    transform-origin: 0 0;
  }

  &.paused {
    animation-play-state: paused;
  }

  @keyframes progressBar {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(100%);
    }
  }
}

.vCarousel__card_img {
  max-width: 50px;
  max-height: 50px;
  width: auto;
  height: auto;
  margin: 0 15px 0 0;
}

.vCarousel__card_icon {
  font-size: 18px;
  margin: 0 0 0 auto;
  padding-left: 15px;
}

.vCarousel__card_link {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.cardOverflow(@lineHeight, @lines) {
  display: block; // fallback
  display: -webkit-box;
  line-height: @lineHeight;
  max-height: @lineHeight * 2; // fallback
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @lines;
}

.vCarousel__card_title1 {
  .cardOverflow(21px, 2);

  color: @color-dark;
  font-size: 15px;
  margin-bottom: 4px;
}

.vCarousel__card_title2 {
  .cardOverflow(21px, 2);

  color: @color-orange;
  font-size: 18px;
  font-weight: bold;
}

.vCarousel__card_subtitle {
  .cardOverflow(18px, 2);

  color: @color-dark;
  font-size: 15px;
}

.vCarousel__terms {
  color: @color-gray;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  padding: 8px 18px;

  &_asterisk {
    vertical-align: text-bottom;
  }
}
