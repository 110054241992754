.StrateComOpe {
  // col-md-offset-1 col-md-5
  min-height: 1px;
  padding-left: 0;
  padding-right: 0;
  float: left;

  @media (min-width: 992px) {
    margin-left: (100% / 24);
    width: (100% * 5 / 24);
  }

  &-iconList {
    padding-left: 0;
    list-style: none;

    margin-left: 0;
    margin-right: 0;
  }

  &-iconListItem {
    // col-xs-offset-1 col-xs-7 col-md-offset-0 col-md-24
    min-height: 1px;
    padding-left: 0;
    padding-right: 0;
    float: left;
    width: (100% * 7 / 24);
    margin-left: (100% / 24);

    @media (min-width: 992px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
