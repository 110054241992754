// ------------------------
// BUTTONS mixins
// ------------------------

// -------
// STYLE
// -------
// Button class & <button>
// 1: @color
// 2: @color-text

.ff-button-base-style(@color, @color-text) {
  color: @color-text;
  background-color: @@color;

  .ff-button-style-hover(@color, @color-text);
  .ff-button-style-focus(@color, @color-text);
}

.ff-button-style-hover(@color, @color-text) {
  //variable couleur
  @color-hover: '@{color}-hover';

  // hovered background color
  &:hover {
    color: @color-text;
    background-color: @@color-hover;
    border-color: @@color-hover;
  }
}

.ff-button-style-focus(@color, @color-text) {
  //variable couleur

  @color-focus: '@{color}-focus';

  // pressed background color
  &:active {
    color: @color-text;
    background-color: @@color-focus;
    border-color: @@color-focus;
  }
}
//
// OUTLINE STYLE MIXIN
//
// Button class & <button>
// 1: @color-outline-background
// 2: @color-outline-border
// 3: @color-outline-text
// 4: @color-name
// 5: @color-text-state (hover/focus)
//
.ff-button-outline(@color-outline-background, @color-outline-border, @color-outline-text, @color-name, @color-text-state) {
  background-color: @color-outline-background;
  border: 1px solid @color-outline-border;
  color: @color-outline-text;

  .ff-button-style-hover(@color-name, @color-text-state);
  .ff-button-style-focus(@color-name, @color-text-state);
}

//
// OUTLINE STYLE MIXIN
//
// Button class & <button>
// 1: @color-outline-background
// 2: @color-outline-border
// 3: @color-outline-text
// 4: @color-outline-background--hover
// 5: @color-outline-border--hover
// 6: @color-outline-text--hover

.ff-button-outline-hovered-style(@color-outline-background--hover, @color-outline-border--hover, @color-outline-text--hover) {
  background-color: @color-outline-background--hover;
  border-color: @color-outline-border--hover;
  color: @color-outline-text--hover;
}

.ff-button-outline-style(@color-outline-background, @color-outline-border, @color-outline-text, @color-outline-background--hover, @color-outline-border--hover, @color-outline-text--hover) {
  background-color: @color-outline-background;
  border: 1px solid @color-outline-border;
  color: @color-outline-text;

  &:focus {
    .ff-button-outline-hovered-style(@color-outline-background--hover, @color-outline-border--hover, @color-outline-text--hover);
  }

  @media (hover: hover) {
    &:hover {
      .ff-button-outline-hovered-style(@color-outline-background--hover, @color-outline-border--hover, @color-outline-text--hover);
    }
  }
}
