@import (reference) '../../styles/_library.less';

@import './header-panel-shipping.less';
@import './header-panel-store.less';
@import './header-panel-account.less';
@import './header-panel-connected.less';
@import './header-panel-basket.less';

.f-header__panel {
  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: @color-dark-lighter;
  }

  &-wrapper {
    margin-bottom: auto;
    .customScrollbars(auto, auto);
  }

  &-item {
    background-color: @color-white;
    position: fixed;
    right: 0;
    top: 0;
    width: 375px;
    height: 100%;
    border-radius: 8px 0 0 8px;
    padding: 16px;
    z-index: @z-indexSidePanel;
    transition: transform 0.2s @ease-out-quad;
    left: 100%;

    &.open {
      transform: translateX(-100%);
    }
  }

  &-closeBtn,
  .non-legacy &-closeBtn {
    background: 0;
    border: 0;
    color: @color-dark;
    display: flex;
    font-size: 24px;
    margin: 0 0 16px calc(100% - 24px);
    padding: 0;
  }
}
