@import (reference) '../../../mixins/_buttons.less'; // mixins for components "buttons.less"
@import (reference) '../../../Nav/Css/Less/common/base/_colors.less';

// ---------
// BUTTONS

// HOW TO USE IT
// HTML markup: <a> or <button> with 2 children (first for icon, second for label)
//
// <button class="ff-button ff-button--orange ff-button--block" type="button">
//     @Html.Icon("shopping-bag", "ff-button-icon")     //[Icon HTML here with class ff-button-icon]
//     <span class="ff-button-label">Ajouter au panier</span>
// </button>

//
// Plain button
//
.ff-button {
  .ff-button-base-style('color-button-dark', @color-white);
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  transform: translate3d(0, 0, 0);
  transition: @button-transition;
  transition-property: background-color, border-color, color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 13px;
  text-align: center;
  font-weight: normal;
  // TODO: the previous value was 5px (@base-radius), check if we can update it everywhere to 4px
  border-radius: 4px;
  line-height: 16px;
  padding: 11px 20px;
  min-height: 40px;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &--block {
    // display: block;
    width: 100%;
  }

  &--minWidth {
    min-width: 180px;
  }

  &-icon {
    margin-right: 8px;
    font-size: 16px;
  }
}

//
// Plain Button colors
//
// All the colors for the button background
//
// .button-color-style ("color-dark", @color-dark) {
//   .ff-button-base-style(color-button-dark);
// }
// 1: string -> name color
// 2: variable @color-text

// ORANGE (brand / primary)
@color-button-orange: @color-orange;
@color-button-orange-hover: #f7c052;
@color-button-orange-focus: #f9d07d;
.ff-button--orange {
  .ff-button-base-style('color-button-orange', @color-dark);
}

// DARK (secondary)
@color-button-dark: @color-dark;
@color-button-dark-hover: #323232;
@color-button-dark-focus: #555555;
.ff-button--dark {
  .ff-button-base-style('color-button-dark', @color-white);
}

// WHITE
@color-button-white: @color-white;
@color-button-white-hover: @color-silver-lighter;
@color-button-white-focus: @color-silver-darker;
.ff-button--white {
  .ff-button-base-style('color-button-white', @color-dark);
}

// BLUE (digital)
@color-button-blue: @color-skyBlue;
@color-button-blue-hover: #0f4f61;
@color-button-blue-focus: #0a3440;
.ff-button--blue {
  .ff-button-base-style('color-button-blue', @color-white);
}

// EMERALD (presale / DLE)
@color-button-emerald: @color-caribbeanGreen;
@color-button-emerald-hover: #006854;
@color-button-emerald-focus: #004b3d;
.ff-button--emerald {
  .ff-button-base-style('color-button-emerald', @color-white);
}

// GREEN (book / précommande)
@color-button-green: @color-forestGreen;
@color-button-green-hover: #395e01;
@color-button-green-focus: #153f01;
.ff-button--green {
  .ff-button-base-style('color-button-green', @color-white);
}

// GREY (tertiary)
@color-button-gray: @color-gray-light;
@color-button-gray-hover: #555555;
@color-button-gray-focus: #323232;
.ff-button--grey {
  .ff-button-base-style('color-button-gray', @color-white);
}

// FACEBOOK
@color-button-facebook: #3b5998;
@color-button-facebook-hover: @color-button-facebook;
@color-button-facebook-focus: @color-button-facebook;
.ff-button--facebook {
  .ff-button-base-style('color-button-facebook', @color-white);
}

// TWITTER
@color-button-twitter: @color-black;
@color-button-twitter-hover: @color-black;
@color-button-twitter-focus: @color-black;
.ff-button--twitter {
  .ff-button-base-style('color-button-twitter', @color-white);
}

//
// Old button
//
.btn:extend(.ff-button) {
  /* Extend old buttons CSS class */

  &:hover:extend(.ff-button:hover) {
    /* Extend old buttons CSS class */
  }

  &:active:extend(.ff-button:active) {
    /* Extend old buttons CSS class */
  }
}

//
// Outline button
//
// 1: @color-outline-background
// 2: @color-outline-border
// 3: @color-outline-text
// 4: @color-name
// 5: @color-text-state (hover/focus)

.ff-button--outline {
  // DARK
  &.ff-button--dark {
    .ff-button-outline(transparent, @color-dark, @color-dark, 'color-button-dark', @color-white);
  }
  // WHITE
  &.ff-button--white {
    .ff-button-outline(transparent, @color-white, @color-white, 'color-button-white', @color-dark);
  }
  // WHITE
  &.ff-button--grey {
    .ff-button-outline(transparent, @color-gray-light, @color-dark,'color-button-gray', @color-white);
  }
}

//
// Circle button
//
.ff-button--circle {
  @circle-size: 40px;
  height: @circle-size;
  width: @circle-size;
  padding: 0;
  text-align: center;
  border-radius: 50%;

  .ff-button-icon {
    margin: 0;
    font-size: 20px;
  }
}

//
// Alert button
// TODO -> A supprimer ticket en cours côté UX/UI
// utiliser dans l'ajout d'un avis dans un produit sur une FA
.ff-button-alert {
  .ff-button-outline-style(transparent, @color-gray, @color-black, transparent, @color-black, @color-black);

  &--inactive {
    .ff-button-outline-style(transparent, @color-forestGreen, @color-forestGreen, transparent, @color-gray, @color-black);

    cursor: default;
  }
}

//
// Square button
//
.ff-button-square {
  @square-size: 48px;
  // display: flex;
  // justify-content: center;
  height: @square-size;
  width: @square-size;
  // align-items: center;
  // border-radius: @base-radius;
  // color: @color-white;

  .ff-button-icon {
    margin: 0;
    font-size: 24px;
    // height: 1em;
    // line-height: 1em;
    // position: static; // override
  }

  .ff-button-label {
    display: none;
  }
}

//
// Link button
//
.ff-button-link {
  padding: 0;
  text-align: left;
  background: none;
  border: none;
  appearance: none;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

//
// Disabled button style
//
.ff-button[disabled] {
  background-color: @color-silver-lighter;
  color: @color-gray;
  cursor: default;
  opacity: 0.85;
  pointer-events: none;
  border-color: @color-gray;
}
