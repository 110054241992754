@import (reference) '../../../../../styles/_library.less';

// ----------------------------------------
// Strate
// ----------------------------------------

.strate {
  display: block;
  width: 100%;
  position: relative;
  clear: both;
  margin-top: -1px;
  border-top: 1px solid @color-silver-darker;

  &.strate-altGrey {
    background: @color-silver-dark;
  }

  .f-nCarousel {
    .nCarouselRoundedArrows();
    .nCarouselPositionArrows(10px);
  }
}

.Strate-title {
  .typoFnacLight();
  display: inline-block;
  margin: 0;
  overflow: hidden;
  font-size: 27px;
  line-height: 40px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: @screen-md-min) {
    font-size: 22px;
  }

  @media (max-width: @screen-sm-min) {
    font-size: 18px;
  }

  &--red {
    color: @color-red;
  }
}

.Strate-subtitle {
  font-size: 0.55em;
  font-weight: initial;
  text-transform: initial;
  color: @color-gray;
  display: block;
  line-height: 1.2em;
}

.Strate-top {
  margin: 30px 0;
  padding: 0 30px;
  width: 100%;
  min-height: 40px;

  @media (max-width: @screen-sm-min) {
    margin: 15px 0;
    padding: 0 15px;
  }

  &:empty,
  &:blank,
  &:-moz-only-whitespace {
    display: none;
  }

  html[lang*='-BE'] .Carousel--outsideNav.strateHistory & {
    padding-right: 425px;
  }

  .Carousel--sponsor & {
    margin-bottom: 0;
  }

  .tabbed & {
    margin-bottom: 0;
    min-height: 90px;

    @media (min-width: 1280px) {
      min-height: 100px;
    }
  }

  // .tabbed & .seeAll-group {
  //   position: absolute;
  //   right: 30px;
  //   top: 26px;
  // }

  .seeAll-group {
    display: flex;
    position: absolute;
    right: 30px;
    top: 28px;

    [aria-hidden='false'] {
      display: none;
    }
  }

  .seeAll-bt {
    // float: left;
    margin: 0 0 0 8px;
    // display: none;

    // @media (max-width: @screen-xs-min) {
    //   float: none;
    //   text-align: center;
    // }

    // & + .seeAll-bt {
    //   display: none;
    // }

    // TODO: do it back-end in \Nav\Core\Homes\Common\HeaderBloc\Node\HeaderBlocSeeAllNode.ascx
    &:nth-child(1) {
      &:only-of-type {
        display: inherit !important;
      }

      @media (scripting: none) {
        display: inherit !important;
      }
    }
  }

  .seeAll-tabButtonGroup {
    display: flex;
  }

  // strong {
  //   font-weight: bold;
  // }
}

// CLASSIC SEE ALL BTN ---------------------------------------- //
// .seeAll-bt {
//   font-size: unit((15px / 12px), em);
//   color: @color-dark;
//   background: none;
//   border: 1px solid @color-dark;
//   border-radius: @base-radius;
//   padding: 0 17px;
//   height: 42px;
//   line-height: 42px;
//   display: none;

//   &.isVisible {
//     display: block;
//   }

//   &:hover,
//   &:focus {
//     color: @color-white;
//     background: @color-dark;
//     text-decoration: none;
//   }
// }

// .Strate-allBtns {
//   & > .seeAll-bt {
//     display: inline-block;
//     position: absolute;
//     right: 26px;
//     top: 29px;
//     cursor: pointer;

//     &.deleteHistory + .seeAll-bt {
//       right: 206px;
//     }
//   }

//   @media (max-width: @screen-sm-min) {
//     & > .seeAll-bt {
//       top: 14px;
//     }
//   }
// }

// .history,
// .brand-list {
//   & > .seeAll-bt {
//     margin: 0 30px;
//   }
// }

.Brand {
  .Strate-top {
    float: left;
    width: 100%;
  }
}

.list {
  @media (max-width: 1280px) {
    .Strate-top {
      min-height: 80px;
    }
  }
}

.strate-NoTitle {
  .Strate-top {
    display: none;
  }
}

.strateHero .f-nCarousel__item {
  min-width: 50%;

  @media (min-width: @screen-md-min) {
    min-width: calc(100% / 3);
  }

  @media (min-width: @screen-lg-min) {
    min-width: 25%;
  }

  @media (min-width: @container-lg) {
    min-width: 20%;
  }

  @media (min-width: 1800px) {
    min-width: calc(100% / 6);
  }
}

.strate-greyBg {
  background: @color-silver-lighter;
  padding: 0 30px 30px;
  clear: both;

  &.strate-NoTitle {
    padding-top: 15px !important;
  }

  .Strate-top {
    margin: 0;
    padding: 30px 0;
  }

  .strate-title {
    text-transform: uppercase;
  }

  &::after {
    clear: left;
    content: '';
    display: block;
    width: 100%;
  }

  // .whiteContent {
  //   .typoFnacRegular();
  //   background: @color-white;
  //   display: block;
  //   padding: 30px;
  //   font-size: 13px;
  //   line-height: 21px;
  //   transition: max-height ease-in-out 0.8s;
  //   overflow: hidden;

  //   img {
  //     max-width: 100%;
  //     height: auto;
  //   }

  //   & + .whiteContent {
  //     margin-top: 10px;
  //   }
  // }
}

.strate--full {
  &.strate-greyBg {
    padding: 0 0 30px;

    .Strate-top {
      padding: 30px;
    }
  }
}

.expander {
  .Strate-title {
    float: none;
    display: block;
    line-height: 27px;
  }
  .Strate-top {
    margin: 0;
    padding: 15px;

    @media (min-width: @screen-sm-min) {
      padding: 30px;
    }
  }
}

/* Kamino */
.kamino .Strate-top {
  margin: 30px 0 0;
}
