// pagination ----------------------------------------------
// https://fnacdarty.invisionapp.com/dsm/fnac-darty/fnac-design-system/asset/components/5e4668368728f6140e9f6d8c
.f-pagination {
  list-style: none;
  padding: 10px;
  color: @color-dark;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 1px solid @color-silver-darker;
  background: @color-silver-lighter;

  &-numberPage {
    background: none;
    border: 0;
    padding: 7px 11px;
    margin: 0 2px;

    &:hover {
      color: @color-dark;
      cursor: pointer;
    }

    &--active {
      background: @color-white;
      border: 1px solid @color-orange;
      border-radius: @base-radius;
      color: @color-orange;
    }
  }
}
