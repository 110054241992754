.f-trends {
  border-top: 1px solid @color-silver-darker;

  &__title {
    display: block;
    font-size: 27px;
    color: @color-dark;
    padding: 30px;
    margin: 0;
    border: 0;
    border-bottom: 1px solid @color-silver-darker;
    text-transform: uppercase;

    // in case it's a button
    background: none;
    border-radius: 0;
    width: 100%;
    text-align: left;

    &[aria-controls] {
      cursor: pointer;
    }

    // Sometimes the title is wrapped
    &-wrapper {
      margin: 0;
    }

    &-link {
      color: inherit;
    }

    &-marker {
      margin-left: 5px;

      .f-trends__title[aria-expanded='true'] &--expand,
      .f-trends__title[aria-expanded='false'] &--collapse {
        display: none;
      }
    }
  }

  &__blocs {
    @margin: 5px;

    &-list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 20px - @margin 28px - @margin;
    }

    &-li {
      flex: 1 1 15%;
      margin: @margin;

      @media (max-width: @screen-lg-min) {
        flex-basis: 30%;
      }
    }
  }

  &__bloc {
    font-size: 13px;
    line-height: 19px;

    &-title {
      text-transform: uppercase;
      font-weight: bold;
      margin: 0 0 0.3em;
    }

    &-links {
      &-list {
        list-style: none;
        padding: 0;
      }
    }
  }
}
