@import (reference) '../../../../../styles/_library.less';

.f-wishlist-dialog {
  //@container-padding: 18px;
  //@article-item-visual-list-width: 250px;
  //@article-item-padding: 30px;

  //position: absolute;
  //top: 0;
  //left: 0;
  //z-index: 11; // over buy buttons
  //height: 100%;
  width: 100%;
  margin: 0 auto;
  .mfp--modal:not(.mfp--mobile) & {
    max-width: 300px;
    height: 400px;
  }

  // &--modal {
  //   height: 400px;
  //   // Use a higher specificity
  //   &.f-mfp--small {
  //     max-width: 300px;
  //   }
  // }

  &:not(&--modal):not(&--open) {
    display: none;
  }

  .ff-button-icon {
    font-size: 16px;
  }

  // .Article-itemGroup & {
  //   width: @article-item-visual-list-width + @article-item-padding + @container-padding;
  //   // It's approximation, on iPad the image (.Article-itemVisualImg) height is auto and (.Article-item) the padding use percentage and em
  //   // See Nav/Css/Less/views/search/components/article-list.less:51
  //   // See Nav/Css/Less/views/search/components/article-list.less:70
  //   @media (max-width: @screen-md-min) {
  //     height: 246px + 25px;
  //     width: 100%;
  //   }
  // }

  &__container {
    display: flex;
    flex-direction: column;
    //padding: @container-padding;
    height: 100%;
    //background: @color-white;
    //border-radius: 5px;

    // .miniFA & {
    //   padding: 25px 10px 10px;
    // }
  }

  &__content {
    flex: 1 1 40%;
    display: flex;
    flex-direction: column;
    min-height: 0; // this allow to skrink to force overflow != visible
    max-height: 400px; // avoids overflowing the page on mobile
  }

  &__list {
    overflow: auto;
    flex: 1 1 40%;
    min-height: 0; // this allow to skrink to force overflow != visible
  }

  &__general-msg {
    flex: 1 1 40%;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 125px;

    &-icon {
      color: @color-lime;
      font-size: 50px;
      margin-bottom: 15px;
    }

    &-text {
      font-size: 18px;
      margin: 0;
    }
  }

  // &__action {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 12px 15px;
  //   margin: 5px auto;
  //   background-color: @color-orange;
  //   border: 1px solid @color-orange;
  //   border-radius: 5px;
  //   color: @color-white;
  //   font-size: 13px;
  //   line-height: 1;
  //   text-align: center;
  //   width: 100%;

  //   &:last-child {
  //     margin-bottom: 0;
  //   }

  //   &:hover,
  //   &:focus {
  //     background-color: @color-orange-hover;
  //     color: @color-white;
  //     text-decoration: none;
  //   }

  //   &--alt {
  //     background-color: @color-white;
  //     border-color: @color-dark;
  //     color: @color-dark;

  //     &:hover,
  //     &:focus {
  //       background-color: @color-white;
  //       color: @color-dark;
  //       text-decoration: none;
  //     }
  //   }

  //   &-icon {
  //     font-size: 16px;
  //     margin-right: 5px;
  //   }
  // }

  &__title {
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
  }
  // in title
  &__list-count {
    font-size: 11px;
    margin-left: 5px;
    color: @color-gray;
  }

  &__li {
    //vertical-align: middle;
    margin-bottom: 14px;
    position: relative;
    padding-left: 4px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    &-input {
      display: inline-block !important;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      position: absolute;
      left: 2px;
      top: -4px;
    }

    &-label {
      font-size: 13px;
      font-weight: inherit;
      margin-left: 8px;
    }

    &-count {
      //vertical-align: middle;
      color: @color-gray;
      font-size: 11px;
    }
  }

  &__add {
    &-fieldset {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      padding-bottom: 1px;
    }

    &-input {
      border: solid @color-gray;
      border-width: 0 0 1px 0;
      flex-grow: 1;
      margin-right: 8px;
    }

    // &-action {
    //   width: auto;
    //   margin: 0 0 0 8px;
    // }
  }
}
