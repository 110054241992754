@import (reference) '../../../styles/_library.less';

.f-kamino-totem {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  overflow: hidden;
  padding: 5px;
  transition: 0.2s;
  position: sticky;
  top: 62px;

  &__wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    position: relative;
    overflow: hidden;
  }

  &__media {
    position: relative;
    width: 189px;
    max-width: 189px;
    height: max-content;
    display: flex;
    flex-basis: content;
    flex-shrink: 0;
    /* Image fomat */
    a {
      display: flex;
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
        & when (@ismobile) {
          object-fit: contain;
          min-width: 0;
          min-height: 0;
          max-height: 100%;
          width: auto;
        }
      }
    }
    /* Video format */
    video {
      width: 189px;
      height: auto;
      max-width: 189px;
    }
  }

  /* Title */
  .f-productLayer__title {
    padding: 0 0 16px 0;
  }

  .miniFA__title {
    color: @color-dark;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: wrap;
    height: auto;
    font-size: 12px;
  }

  /* Carousel */
  .f-nCarousel {
    margin: 0;
    overflow-x: hidden;
    transition: transform 0.3s ease;
    z-index: 2;

    &--hidden {
      transform: translateX(80%);
    }

    & when (@ismobile) {
      overflow-x: auto;
    }
    .nCarouselRoundedArrows(@size: 25px);
    .nCarouselPositionArrows(10px);

    &__wrapper {
      width: calc(100% - 38px);
      height: 100%;
      margin-left: 18px;
      border-radius: 5px;
    }

    &--arrows {
      padding: 0;
      height: auto;
      position: absolute;
      width: 189px;
      bottom: 8px;
    }

    &__arrow {
      width: 25px;
      height: 25px;
      &--next {
        right: 5px;
      }
      &--prev {
        left: 5px;
      }
    }

    /* miniFA */
    .miniFA--macro {
      width: 149px;
      max-width: 149px;
      padding: 10px;
      max-height: 100px;

      .miniFA {
        &__link::before {
          top: -10px;
          left: -45px;
          height: 50px;
        }
        &__top {
          flex-direction: row;
          padding-top: 8px;
        }
        &__image {
          height: 33px;
          width: 33px;
          min-width: 33px;
          margin: 0 5px 5px 0;
        }
        &__button.ff-button--iconOnly {
          min-width: 32px;
          width: 32px;
          height: 32px;
          margin: 0;
          min-height: 32px;
        }

        &__rating--no-rating {
          height: 0;
          line-height: 0;
        }
        &__spacer {
          display: none;
        }
        &__bottom {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;
        }
        &__priceList {
          flex-direction: column;
        }
        &__oldPrice {
          align-self: flex-start;
        }
        &__rating {
          display: none;
          // position: absolute;
          // left: 47px;
          // top: 26px;
        }
        &__greenLabel {
          position: absolute;
          top: 23px;
          bottom: unset;
          left: 49px;
          .thumbnail-greenLabel {
            position: absolute;
            top: -0;
            bottom: unset;
            left: 0;
            width: 30px;
            height: auto;
          }
        }
      }
    }

    .ff-button-icon {
      margin-right: 0;
    }
  }

  /* Omnibus */
  .f-info {
    position: absolute;
    bottom: 0px;
    z-index: 3;
    &-tooltip {
      font-size: 9px;
      transform: translate(-87%);
      min-width: 130px;
      &::after {
        transform: translate(390%, 60%) rotate(45deg);
      }
    }
  }

  .f-kamino {
    &-video-controls::before {
      transform: rotate(180deg);
    }
    &-video-sound-icon[data-pos='right'] {
      top: 8px;
    }
    &-video-progress {
      position: absolute;
      top: 0;
    }
    &-video-controls {
      top: 0;
    }
  }

  /* Sponsored */
  .f-kamino {
    &-sponsored {
      background: white;
      display: flex;
      justify-content: flex-end;
      color: @color-gray;
      align-items: center;
      border: 0;
      align-self: flex-end;
      margin-top: 4px;

      & when (@ismobile) {
        font-size: 12px;
        margin-right: 16px;
      }

      &--icon {
        font-size: 18px;
        & when (@ismobile) {
          font-size: 12px;
        }
        margin-left: 5px;
      }
    }
  }
}
