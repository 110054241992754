.f-header__connected {
  &-header {
    background-color: @color-white;
    padding: 0 0 20px;
    color: @color-dark;
    text-align: center;
  }

  &-picture {
    margin-bottom: 5px;
  }

  &-img-wrapper {
    // background: @black;
    border-radius: 50%;
    display: inline-block;
    height: 70px;
    width: 70px;
    color: @color-dark;
    line-height: 70px;
    text-align: center;
    vertical-align: middle;
  }

  &-img {
    background: colorize(data-uri(icon('person')), @color-dark) center / contain no-repeat;
    height: 72px;
    width: 72px;

    &--social {
      border-radius: 50%;
    }
  }

  &-name {
    font-size: 18px;
    text-transform: capitalize;

    & + :not(.f-header__connected-status) {
      margin-top: 10px;
    }
  }

  &-status {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;

    &-text {
      vertical-align: middle;
    }

    &-icon {
      vertical-align: middle;
      line-height: inherit;

      &--fnac {
        width: 3.29em;
        color: #e1a925;
      }

      &--fnac-plus {
        width: 3.63em;
      }

      &--fnac-one {
        width: 5.817em;
      }

      &--fnac-one-plus {
        width: 8em;
        height: auto;
      }

      &--fnacandme {
        width: 5.817em;
      }

      &--techCultura {
        width: 6em;
      }
    }
  }

  &-content {
    background-color: @color-silver-lighter;
    padding: 16px;
    color: @color-dark;
  }

  &-list {
    padding: 0;
  }

  &-item {
    padding: 8px 0;
    list-style: none;
  }

  &-item-link,
  .non-legacy &-item-link {
    display: block;
    position: relative;
    color: @color-dark;
    font-size: 13px;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &-text {
      vertical-align: middle;
    }

    &:hover &-text,
    &:focus &-text {
      text-decoration: underline;
    }

    &-icon {
      vertical-align: middle;
      font-size: 24px;
      margin-right: 16px;
    }
  }

  &-expired {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 2px;
    width: auto;
    font-size: 10px;
    text-decoration: underline;
  }

  &-logout {
    background: @color-black;
    padding: 16px;
    text-align: right;
  }

  &-logout-link,
  .non-legacy &-logout-link {
    color: @color-white;
    font-size: 13px;

    &-icon {
      font-size: 24px;
      vertical-align: middle;
      margin-right: 8px;
    }

    &:hover,
    &:focus {
      color: @color-white;
    }
  }

  &-orders {
    border-radius: 16px;
    height: 16px;
    min-width: 16px;
    display: inline-block;
    background: @color-dark;
    color: @color-white;
    text-align: center;
    line-height: 16px;
    padding: 0 4px;
    margin: 0 0 0 8px;
  }
}
