.f-gaugeChart {
  font-size: 0;
  .tooltipReview-actionChart & {
    padding: 20px;
  }
}

.f-gaugeChart-item {
  display: inline-block;
  font-size: 13px;
  position: relative;
  width: 100%;

  .f-review-keypointsBlock--split & {
    @media (min-width: 1280px) {
      width: 50%;

      &:nth-child(even) {
        padding-left: 20px;
      }

      &:nth-child(odd) {
        border-right: 1px solid @color-silver-lighter;
      }
    }
  }

  .tooltipReview-actionChart & {
    margin-bottom: 10px;
    text-align: left;
  }

  .f-chart--review & {
    text-align: left;
    height: 100%;

    .f-gaugeChart-text {
      @media (min-width: 1280px) {
        width: 50%;
      }

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .f-gaugeChart-background {
      @media (max-width: 1400px) {
        position: relative;
        right: 0;
        display: block;
        margin-top: 0;
      }
    }
  }

  &--1 {
    .f-gaugeChart-foreground {
      width: 20%;
    }
  }

  &--2 {
    .f-gaugeChart-foreground {
      width: 40%;
    }
  }

  &--3 {
    .f-gaugeChart-foreground {
      width: 60%;
    }
  }

  &--4 {
    .f-gaugeChart-foreground {
      width: 80%;
    }
  }

  &--5 {
    .f-gaugeChart-foreground {
      width: 100%;
      border-radius: 10px;
    }
  }
}

.f-gaugeChart-text {
  display: block;
  height: 26px;
  line-height: 26px;
  @media (max-width: 1400px) {
    width: 90%;
  }

  .tooltipReview-actionChart & {
    width: 100%;
  }
}

.f-gaugeChart-foreground {
  position: absolute;
  width: 0;
  height: 10px;
  left: 0;
  top: 0;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: 0;
  background: @color-orange;
}

.f-gaugeChart-background {
  position: absolute;
  width: 120px;
  height: 10px;
  border-radius: 10px;
  background: #d8d8d8;
  right: 20px;
  top: 0;
  margin-top: 8px;

  .tooltipReview-actionChart & {
    position: relative;
    margin-top: 0;
    right: 0;
    display: block;
  }
}

.f-gaugeChart-separator {
  width: 60%;
  left: 20%;
  border-left: 1px solid @color-white;
  border-right: 1px solid @color-white;
  height: 10px;
  position: absolute;
  z-index: 1;

  &::before {
    content: '';
    border-left: 1px solid @color-white;
    border-right: 1px solid @color-white;
    left: 33%;
    width: 36%;
    height: 10px;
    position: absolute;
  }
}
