@path: if(@ismobile, '../../styles/_library-mobile.less', '../../styles/_library.less');
@import (reference) '@{path}';

@main-padding-left: 200px;

// Calculate the banner button height so it stays visible when collapsing it.
@compare-button-line-height: 1.2;
@compare-button-font-size: 15px;
@compare-button-padding: 16px;
@compare-button-height: @compare-button-padding * 2 + @compare-button-font-size * @compare-button-line-height;

// Ensure proper spacing between elements.
@compare-wrapper-margin: 5px;
@compare-item-margin: 3px;
@compare-action-margin: 5px;

@compare-wrapper-margin-md: 15px;
@compare-item-margin-md: 7.5px;
@compare-action-margin-md: 15px;

.f-compare-banner {
  & when (@ismobile) {
    .FA &.f-compare-bottomStickyCTA {
      bottom: 62px;
    }
  }

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: @color-white;
  transform: translateY(100%);
  transition: transform 0.25s;
  z-index: 1002;

  @media (min-width: @screen-sm-plus) {
    // Accomodate the banner width with the side nav
    .Main:not(.Main--fullWidth) & {
      left: @main-padding-left;
      width: calc(100% - @main-padding-left);
    }
  }

  &--opened {
    transform: translateY(0);
  }

  &--closed {
    transform: translateY(100%) translateY(-@compare-button-height);
  }

  &__button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: @compare-button-padding;
    background-color: @color-orange;
    border: none;
    color: @color-white;
    font-size: @compare-button-font-size;
    line-height: @compare-button-line-height;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
  }

  &__label {
    display: none;

    .f-compare-banner--opened &--hide {
      display: block;
    }

    .f-compare-banner--closed &--show {
      display: block;
    }
  }

  &__icon {
    font-size: 16px;
    margin-left: 15px;
    transform: rotate(0);
    transform-origin: center;
    transition: transform 0.25s;

    .f-compare-banner--opened & {
      transform: rotate(180deg);
    }
  }

  &__content {
    visibility: hidden;

    .f-compare-banner--opened & {
      visibility: visible;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: -@compare-wrapper-margin;

    @media (min-width: @screen-md-min) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 30px;
      margin: -@compare-wrapper-margin-md;
    }
  }

  &__items {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: @compare-wrapper-margin (@compare-wrapper-margin - @compare-item-margin);
    overflow: auto;
    scroll-snap-type: x proximity;

    @media (min-width: @screen-md-min) {
      flex: 1 1 0;
      margin: @compare-wrapper-margin-md (@compare-wrapper-margin-md - @compare-item-margin-md);
    }
  }

  &__actions {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: (@compare-wrapper-margin - @compare-action-margin);

    @media (min-width: @screen-md-min) {
      flex: 0 1 160px;
      flex-direction: column;
      margin: (@compare-wrapper-margin-md - @compare-action-margin-md);
    }
  }

  &__action {
    flex: 1 0 0;
    padding: 12px 9px;
    margin: @compare-action-margin;
    background-color: @color-orange;
    border: 1px solid @color-orange;
    border-radius: 5px;
    color: @color-white;
    font-size: 13px;
    line-height: 1.1;
    text-align: center;

    &:hover,
    &:focus {
      color: @color-white;
      text-decoration: none;
    }

    @media (min-width: @screen-md-min) {
      flex: 0 0 auto;
      margin: @compare-action-margin @compare-action-margin-md;
    }

    &--alt {
      background-color: @color-white;
      border-color: @color-dark;
      color: @color-dark;

      &:hover,
      &:focus {
        color: @color-dark;
        text-decoration: none;
      }
    }

    &--disabled {
      background-color: @color-silver-darker;
      border-color: @color-silver-darker;
      color: @color-white;
      cursor: not-allowed;

      &:hover,
      &:focus {
        color: @color-white;
        text-decoration: none;
      }
    }
  }
}

.f-compare-item {
  flex: 1 0 112px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin: 0 @compare-item-margin;
  border: 1px solid @color-silver-darker;
  border-radius: 5px;
  list-style-type: none;
  text-align: center;
  scroll-snap-align: start;

  @media (min-width: @screen-md-min) {
    margin: 0 @compare-item-margin-md;
  }

  &--empty {
    background: @color-silver-lighter;
  }

  &__link {
    color: @color-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  &__image {
    display: block;
    margin: 0 auto;
    max-width: 70%;
    max-height: 57px;
  }

  &__name {
    display: none;
    width: 100%;
    height: 30px;
    margin: 9px 0 0;
    font-size: 13px;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: @screen-md-min) {
      display: block;
    }
  }

  &__rating {
    margin-top: 12px;

    @media (min-width: @screen-md-min) {
      margin-top: 7px;
    }
  }

  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 0;
    background: 0;
    border: none;
    color: @color-dark;
    font-size: 16px;
    line-height: 1;
  }

  &__placeholder {
    width: 100%;
    height: 40px;
    opacity: 0.5;
  }
}

.f-compare-error {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 18px;
  margin-top: 10px;
  border: 1px solid @color-silver-lighter;
  border-left: none;
  border-right: none;
  color: @color-error;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: @color-error;
  }

  &__icon {
    font-size: 16px;
    margin-right: 8px;
  }

  &__message {
    margin: 0;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.1;
  }
}
