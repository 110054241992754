// Scaffold
//
// Generic elements styles
//
::selection {
  background: @color-orange;
  color: @color-white;
}

h1, .h1 {
  .typoFnacLight();
  font-size: 27px;
  margin-top: 0;
}

h2, .h2 {
  .typoFnacLight();
  font-size: 20px;
}

ul {
  margin: 0;
}

// txtColor
//
// Dynamic Txt colors from New Ref
//
// /!\ Never use these classes, use a dedicated BEM class instead or inlined styles!
.txtColor-Noir {
  color: @color-dark !important;
}

.txtColor-GrisFilet {
  color: @color-gray-darker !important;
}

.txtColor-GrisTypo {
  color: @color-gray !important;
}

.txtColor-dark {
  color: @color-silver-darker !important;
}

.txtColor-Gris {
  color: @color-silver-lighter !important;
}

.txtColor-Orange {
  color: @color-orange !important;
}

.txtColor-Jaune {
  color: @color-yellow !important;
}

.txtColor-Rouge {
  color: @color-red !important;
}

.txtColor-VertForet {
  color: @color-forestGreen !important;
}

.txtColor-Violet {
  color: @color-violet !important;
}

.txtColor-Bleu {
  color: @color-skyBlue !important;
}

.txtColor-BleuFonce {
  color: @color-blue-dark !important;
}

.txtColor-Vert {
  color: @color-green-lighter !important;
}

.txtColor-Marron {
  color: @color-brown !important;
}

.txtColor-Turquoise {
  color: @color-turquoise !important;
}

.txtColor-VertClair {
  color: @color-olive !important;
}

.txtColor-BleuMarine {
  color: @color-blue-light !important;
}

.txtColor-Rose {
  color: @color-violetRed !important;
}

.txtColor-VertFonce {
  color: @color-green-light !important;
}

.txtColor-Abricot {
  color: @color-tangerine !important;
}

.txtColor-Blanc {
  color: @color-white !important;
}

// Text block
//
// This new ref html txt injection
//
.txt-new {
  background: @color-turquoise;
  padding: 1px 3px 2px 3px;
  color: @color-white !important;
  line-height: 12px;
  font-size: 11px;
  text-transform: lowercase;
}

.txt-christmas {
  background: @color-red-light;
  padding: 1px 3px 2px 3px;
  color: @color-white !important;
  line-height: 12px;
  font-size: 11px;
  text-transform: lowercase;
}

.txt-adh {
  background: @color-yellow;
  padding: 1px 3px 2px 3px;
  color: black !important;
  line-height: 12px;
  font-size: 11px;
  text-transform: lowercase;
}

.txt-bold {
  font-weight: bold;
}

.txt-underline {
  text-decoration: underline;
}
