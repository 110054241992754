//
// Grid system
// --------------------------------------------------

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  .container-fixed();

  @media (min-width: @screen-sm-min) {
    width: @container-sm;
  }
  @media (min-width: @screen-md-min) {
    width: @container-md;
  }
  @media (min-width: @screen-lg-min) {
    width: @container-lg;
  }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  .container-fixed();
}

// Row
//
// Rows contain and clear the floats of your columns.

.row {
  margin-left: 0;
  margin-right: 0;
  .clearfix();
}

// Columns
//
// Common styles for small and large grid columns

// TODO remove when unused
[class*='col-xs-'] {
  position: relative;
  // Prevent columns from collapsing when empty
  min-height: 1px;
  // Inner gutter via padding
  padding-left: 0;
  padding-right: 0;
}

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

[class*=' col-'],
[class^='col-'] {
  float: left;
}

// To list used classes:
// 1. search with regexp (!*.exclude, !*.css, !*.less): col-(xs|sm|md|lg)(-offset|-pull)?-\d+
// 2. export the IDE result to text
// 3. https://regexr.com/ use the same regex and the result text, then list with: "$&",\n
// 4. go to a JS console: [...new Set([/*past here the result*/])].sort()

.col-xs-24 {
  width: 100%;
}
.col-xs-23 {
  width: (100% * 23 / 24);
}
.col-xs-22 {
  width: (100% * 22 / 24);
}
.col-xs-21 {
  width: (100% * 21 / 24);
}
.col-xs-20 {
  width: (100% * 20 / 24);
}
.col-xs-19 {
  width: (100% * 19 / 24);
}
.col-xs-18 {
  width: (100% * 18 / 24);
}
.col-xs-17 {
  width: (100% * 17 / 24);
}
.col-xs-16 {
  width: (100% * 16 / 24);
}
.col-xs-15 {
  width: (100% * 15 / 24);
}
.col-xs-14 {
  width: (100% * 14 / 24);
}
.col-xs-13 {
  width: (100% * 13 / 24);
}
.col-xs-12 {
  width: (100% * 12 / 24);
}
.col-xs-11 {
  width: (100% * 11 / 24);
}
.col-xs-10 {
  width: (100% * 10 / 24);
}
.col-xs-9 {
  width: (100% * 9 / 24);
}
.col-xs-8 {
  width: (100% * 8 / 24);
}
.col-xs-7 {
  width: (100% * 7 / 24);
}
.col-xs-6 {
  width: (100% * 6 / 24);
}
.col-xs-5 {
  width: (100% * 5 / 24);
}
.col-xs-4 {
  width: (100% * 4 / 24);
}
.col-xs-3 {
  width: (100% * 3 / 24);
}
.col-xs-2 {
  width: (100% * 2 / 24);
}
.col-xs-offset-18 {
  margin-left: (100% * 18 / 24);
}
.col-xs-offset-8 {
  margin-left: (100% * 8 / 24);
}
.col-xs-offset-7 {
  margin-left: (100% * 7 / 24);
}
.col-xs-offset-4 {
  margin-left: (100% * 4 / 24);
}
.col-xs-offset-3 {
  margin-left: (100% * 3 / 24);
}
.col-xs-offset-2 {
  margin-left: (100% * 2 / 24);
}
.col-xs-offset-1 {
  margin-left: (100% * 1 / 24);
}

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: @screen-sm-min) {
  .col-sm-24 {
    width: 100%;
  }
  .col-sm-22 {
    width: (100% * 2 / 24);
  }
  .col-sm-18 {
    width: (100% * 18 / 24);
  }
  .col-sm-16 {
    width: (100% * 16 / 24);
  }
  .col-sm-14 {
    width: (100% * 14 / 24);
  }
  .col-sm-12 {
    width: (100% * 12 / 24);
  }
  .col-sm-11 {
    width: (100% * 11 / 24);
  }
  .col-sm-10 {
    width: (100% * 10 / 24);
  }
  .col-sm-8 {
    width: (100% * 8 / 24);
  }
  .col-sm-7 {
    width: (100% * 7 / 24);
  }
  .col-sm-6 {
    width: (100% * 6 / 24);
  }
  .col-sm-5 {
    width: (100% * 5 / 24);
  }
  .col-sm-4 {
    width: (100% * 4 / 24);
  }
  .col-sm-3 {
    width: (100% * 3 / 24);
  }
  .col-sm-2 {
    width: (100% * 2 / 24);
  }
  .col-sm-offset-4 {
    margin-left: (100% * 4 / 24);
  }
  .col-sm-offset-2 {
    margin-left: (100% * 2 / 24);
  }
  .col-sm-offset-1 {
    margin-left: (100% * 1 / 24);
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
}

// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: @screen-md-min) {
  .col-md-24 {
    width: 100%;
  }
  .col-md-22 {
    width: (100% * 22 / 24);
  }
  .col-md-18 {
    width: (100% * 18 / 24);
  }
  .col-md-16 {
    width: (100% * 16 / 24);
  }
  .col-md-15 {
    width: (100% * 15 / 24);
  }
  .col-md-14 {
    width: (100% * 14 / 24);
  }
  .col-md-12 {
    width: (100% * 12 / 24);
  }
  .col-md-10 {
    width: (100% * 10 / 24);
  }
  .col-md-8 {
    width: (100% * 8 / 24);
  }
  .col-md-7 {
    width: (100% * 7 / 24);
  }
  .col-md-6 {
    width: (100% * 6 / 24);
  }
  .col-md-5 {
    width: (100% * 5 / 24);
  }
  .col-md-4 {
    width: (100% * 4 / 24);
  }
  .col-md-3 {
    width: (100% * 3 / 24);
  }
  .col-md-2 {
    width: (100% * 2 / 24);
  }
  .col-md-offset-3 {
    margin-left: (100% * 3 / 24);
  }
  .col-md-offset-2 {
    margin-left: (100% * 2 / 24);
  }
  .col-md-offset-1 {
    margin-left: (100% * 1 / 24);
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
}

// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @screen-lg-min) {
  .col-lg-24 {
    width: 100%;
  }
  .col-lg-20 {
    width: (100% * 20 / 24);
  }
  .col-lg-15 {
    width: (100% * 15 / 24);
  }
  .col-lg-8 {
    width: (100% * 8 / 24);
  }
  .col-lg-6 {
    width: (100% * 6 / 24);
  }
  .col-lg-3 {
    width: (100% * 3 / 24);
  }
  .col-lg-offset-2 {
    margin-left: (100% * 2 / 24);
  }
  .col-lg-offset-1 {
    margin-left: (100% * 1 / 24);
  }
}
