// new categoryMenu nav for PT/PE

.categoryMenu-list {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 0;
}

.categoryMenu-title {
  background: @color-white;
  color: @color-dark;
  font-weight: bold;
  padding: 11px 22px 11px 12px;
  line-height: 20px;
  text-transform: uppercase;
  margin-right: 1px;
  position: relative;
  cursor: pointer;

  &:hover,
  .isClose & {
    background: @color-silver-lighter;
  }

  .icon,
  .f-icon {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .categoryMenu-icon-plus {
    display: none;
  }

  .categoryMenu-icon-minus {
    display: block;
  }

  .isClose & {
    .categoryMenu-icon-plus {
      display: block;
    }

    .categoryMenu-icon-minus {
      display: none;
    }
  }

  &--demat {
    padding-left: 33px;
    text-transform: none;

    a {
      text-decoration: none;
      color: @color-dark;
      display: block;

      &:hover {
        text-decoration: none;
      }
    }

    .f-icon {
      top: 13px;
      right: auto;
      left: 10px;
      color: @color-skyBlue;
      font-size: 16px;
      display: block;
    }
  }
}

.categoryMenu-link {
  color: @color-dark;
  display: block;

  &--all {
    font-weight: bold;
  }

  &:hover,
  &:focus {
    color: @color-dark;
    text-decoration: none;
  }
}

.categoryMenu-grpTitle {
  display: block;
  padding: 4px 12px;
  color: @color-dark;
  font-weight: bold;

  &--link {
    &:hover,
    &:focus {
      color: @color-dark;
      text-decoration: none;
    }
  }
}

.categoryMenu-menuItem {
  display: block;

  .isClose & {
    display: none;
  }

  .categoryMenu-link {
    display: block;
    padding: 4px 12px;
    color: @color-dark;

    &:hover,
    &:focus {
      background: @color-silver-lighter;
      text-decoration: none;
    }
  }
}

.categoryMenu-menuItem--special {
  .categoryMenu-link {
    color: #c00;

    &:hover,
    &:focus {
      border-left: 5px solid #c00;
    }
  }
}
