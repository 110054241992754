// christmasDelivery
.christmasDelivery {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: @color-noel;
  font-size: 13px;
  font-style: italic;
}

.christmasDelivery-icon {
  font-size: 20px;
  vertical-align: bottom;
}

.christmasDelivery-newicon {
  min-width: 24px;
  min-height: 24px;
}

.christmasDelivery-label {
  display: inline-block;
  margin: 0;
}

.christmasDelivery-link {
  display: inline-block;
  color: @color-noel;

  &:hover,
  &:focus {
    color: @color-noel;
  }
}

// Buy Box
.f-buyBox {
  .christmasDelivery {
    margin-bottom: 10px;

    &-label {
      margin-left: 4px;
    }
  }

  .christmasDelivery-icon {
    position: absolute;
    top: -4px;
    left: -5px;
  }
  .christmasDelivery-newicon {
    margin-top: 0;
    margin-right: 4px;
    margin-left: -8px;
  }
}

// Layer Basket
.f-header__panel-item {
  .christmasDelivery {
    padding: 0 0 20px 30px;
    margin-top: 10px;
    background-color: @color-silver-lighter;
  }

  .christmasDelivery-icon {
    position: absolute;
    top: -4px;
    left: 0;
  }

  .christmasDelivery-more {
    display: block;
    text-decoration: underline;
  }
}

// Shipping Pop-in
.Popup-ShippingInfo {
  .christmasDelivery {
    padding: 0 20px 20px;
    background-color: #fff;
  }

  .christmasDelivery-icon {
    margin-right: 5px;
  }

  .christmasDelivery-description {
    margin: 0;
    color: @color-gray;
  }

  .christmasDelivery-newicon {
    width: 18px !important;
    height: 18px !important;
    margin-right: 6px !important;
    margin-left: -3px !important;
  }
}

// Shipping Page
.shipping {
  .christmasDelivery-push {
    margin-top: 10px;
    font-size: 18px;

    .christmasDelivery-icon::before {
      line-height: 0.6;
    }
  }

  .f-ShippingMode-body,
  .OneClick-PostalShippingMethods {
    .christmasDelivery-icon {
      top: inherit;
      left: inherit;
      position: absolute;
      right: 28px;
      margin-top: 20px;
    }

    .f-ModeShop .christmasDelivery-icon {
      right: 18px;
    }
  }
}

// Shipping Mobile Page
@media (max-width: 480px) {
  .shipping {
    .christmasDelivery-push {
      font-size: 15px;

      .christmasDelivery-icon {
        position: inherit;
        right: inherit;

        &::before {
          font-size: 16px;
        }
      }
    }

    .christmasDelivery-icon {
      position: absolute;
      right: 4%;
      font-size: 16px;
      margin-top: 22px;
    }

    .relay {
      .christmasDelivery-icon {
        margin-right: (100% / 24);
        padding-right: inherit;
      }
    }

    .shop {
      .christmasDelivery-icon {
        margin-top: 0;
        padding-right: 0;
      }
    }
  }

  .shippingsummary-OneClic {
    .christmasDelivery-icon {
      margin-top: 0;
      right: 0;
    }
  }

  .ShippingPopin {
    .christmasDelivery-icon {
      position: absolute;
      right: 70px;
      font-size: 16px;
      margin-top: 12px;
    }
  }
}

.f-js-BasketView {
  .christmasDelivery-push {
    margin-bottom: 20px;
  }
}

.thumbnail {
  .christmasDelivery {
    position: absolute;
    width: calc(100% - 40px);
    top: calc(100% - 60px);
    min-height: 40px;
    justify-content: center;
    padding: 3px 0;
    background-color: white;

    &-label {
      padding-left: 6px;
    }
  }
  .countdown-position {
    z-index: 10;
  }
}

// Article Page
.Article-item {
  .christmasDelivery-icon {
    position: absolute;
    top: -4px;
    left: -5px;
  }

  .christmasDelivery-newicon {
    margin-left: -8px !important;
  }

  .christmasDelivery-label,
  .christmasDelivery-link {
    font-size: 13px;

    b {
      font-size: 13px;
    }
  }
}

.Seller-item .christmasDelivery {
  margin-top: 5px;
}
