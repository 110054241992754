@import (reference) '../../styles/_library.less';

@side-nav-width: 200px;
@side-nav-panel-width: 100%;
@side-nav-panel-max-width: 1150px;

.SideNavPanel {
  position: fixed;
  top: 0;
  left: calc(-1 * @side-nav-panel-width);
  z-index: @z-indexSideNav;
  width: @side-nav-panel-width;
  max-width: @side-nav-panel-max-width;
  height: 100%;
  transition: transform 0.2s @ease-out-quad;
  display: flex;

  @media (min-width: @side-nav-panel-max-width) {
    left: calc((-1 * @side-nav-panel-max-width) - 1px);
    transition: transform 0.5s @ease-out-quad;
  }

  &-logo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: @z-indexSideNav;
    height: @header-height;
    background-color: @color-white;
    width: @side-nav-width;
    display: none;
    flex-direction: column;
    justify-content: flex-end;

    &--pro .f-header__logo {
      background-image: colorize(data-uri(icon('icon_i_logo_fnac-pro')), @color-dark);
    }
  }

  &.isActive {
    transform: translateX(100%); // one extra pixel !!!

    .SideNavPanel-logo {
      display: flex;
    }
  }

  &--home {
    width: @side-nav-width;
    left: 0;
    z-index: 1;

    @media (max-width: @screen-sm-min) {
      display: none;
    }

    &.isActive {
      transform: translateX(0);
      width: @side-nav-panel-width;
      z-index: @z-indexSideNav;
    }

    .SubSideNavPanel {
      display: block;
      transition: transform 0.2s @ease-out-quad;
      position: relative;
      left: -100%;
      &.isActive {
        transform: translateX(calc((100% + @side-nav-width) - 1px)); // extra pixel added by browser !!!
      }

      @media (min-width: @side-nav-panel-max-width) {
        transition: transform 0.5s @ease-out-quad;
      }
    }
  }

  .close {
    background: 0;
    color: @color-dark;
    display: flex;
    font-size: 24px;
    border: 0;
    padding: 0;
    position: absolute;
    right: 2px;
    top: 16px;
    z-index: @z-indexOverlayInsideSidePanel + 1;
  }

  &__title {
    align-items: center;
    background: @color-white;
    border-bottom: 1px solid @color-silver-darker;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    height: @header-subnav;
    left: 0;
    padding: 0 0 0 8px;
    position: absolute;
    top: @header-nav;
    width: @side-nav-width;
    z-index: 1;
  }

  /* SIDE NAV PANEL LIST all links that trigger subPanels */
  &-list {
    width: @side-nav-width;
    padding: 0;
    margin: 0;
    list-style: none;

    &Wrapper {
      background-color: @color-white;
      position: relative;
      top: @header-height;
      height: calc(100% - @header-height);
      width: 200px;
      perspective: 1px; // for scroll-y overlap
      z-index: @z-indexSideNav;
      .customScrollbars();
    }

    &Item {
      position: relative;
      margin: 0;
      padding: 0;
      // border-bottom: 1px solid @color-silver-darker;

      // Billetterie, Concerts, Spectacles
      [lang='fr-BE'] &:nth-child(2) a,
      [lang='nl-BE'] &:nth-child(2) a,
      [lang='fr-CH'] &:nth-child(15) a,
      [lang='pt-PT'] &:nth-child(3) a {
        border-left-color: @color-violetRed !important;
      }
    }

    &Link {
      color: @color-dark;
      margin: 0;
      padding: 0 0 0 8px;
      text-decoration: none !important; // prevent ipad safari text-decoration
      display: block;
      max-height: 40px;
      line-height: 40px;
      .text-overflow();

      &.isActive {
        // border-left: 5px solid @color-dark;
        color: @color-black;
        background: @color-silver-lighter;
        text-decoration: none;
        // box-shadow: 0 -1px 0 0 @color-silver-lighter, 0 1px 0 0 @color-silver-lighter;
        @media (hover: hover) {
          &::before,
          &::after {
            content: '';
            display: block;
            height: 40px;
            position: absolute;
            right: 0;
            bottom: 40px;
            border-bottom: 80px solid transparent;
            border-left: 120px solid transparent;
            clip-path: polygon(
              100% 0%,
              97% 18%,
              90% 36%,
              83% 49%,
              71% 63%,
              59% 75%,
              43% 85%,
              32% 92%,
              17% 96%,
              6% 99%,
              1% 100%,
              100% 100%
            );
            z-index: 1;
          }

          &::after {
            top: 40px;
            border-bottom: 0;
            border-top: 80px solid transparent;
            clip-path: polygon(
              100% 100%,
              97% 82%,
              90% 64%,
              83% 51%,
              71% 37%,
              59% 25%,
              43% 15%,
              32% 8%,
              17% 4%,
              6% 1%,
              1% 0%,
              100% 0%
            );
          }
        }
      }

      // for the new flag...
      .txt-new,
      .txt-christmas,
      .txt-adh {
        position: absolute;
        line-height: 12px;
        font-size: 11px;
        height: 14px;
      }
    }
  }
}
