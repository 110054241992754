.Video-content {
  position: relative;

  span {
    background: rgba(@color-white, 0.5);
    border-radius: 50%;
    height: 100px;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    position: absolute;
    top: 50%;
    width: 100px;
    z-index: 1;

    .icon {
      position: relative;
      left: -10px;
      top: -10px;

      color: @color-white;
      font-size: 120px;
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}
