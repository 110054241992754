.f-skipLinks {
  background: #fff;
  box-shadow: 0 1px 2px #c0c0c0;
  display: flex;
  font-size: 14px;
  list-style: none;
  padding: 0;
  position: fixed;
  top: 0;
  transform: translateY(calc(-100% - 2px));
  transition: transform 0.2s;
  width: 100%;
  z-index: 1552;
}

.f-skipLinks:focus-within {
  transform: translateY(0);
}

.f-skipLinks__link {
  color: #232323;
  display: block;
  padding: 20px;
  text-decoration: underline;
}

#FnacFooter {
  scroll-margin-top: 64px;
  .search & {
    scroll-margin-top: 116px;
  }
}

#FnacContent {
  scroll-margin-top: -84px;
  .search & {
    scroll-margin-top: 116px;
  }
}
