@import '../Product-components/ratingTight.less';

.f-offerList {
  margin: 0 auto;

  &__header {
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
    position: sticky;
    top: 0;
    z-index: 1;
    padding-top: 30px;
    background-color: @color-white;
    border-radius: 10px 10px 0 0;
  }

  &__title {
    width: 50%;
    margin: 0 auto;
    padding: 0 0 30px;
    text-align: center;

    &Label {
      font-size: 18px;
      font-weight: bold;
      display: inline;
    }
  }

  &__link {
    display: block;
    font-size: 13px;
    color: @color-gray-darker;
    line-height: 17px;
    text-decoration: underline;

    & when (@ismobile) {
      margin-bottom: unit((16px/ 13px), em);
    }
  }

  &__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px 24px;
  }

  &__filters {
    display: flex;
    gap: 15px;
  }

  &__sort {
    margin-left: auto;
  }

  &__filter {
    font-size: 13px;

    // Generated class name
    .f-otherOffers-filter-value {
      color: @color-gray;
      font-weight: normal;
      white-space: nowrap;
    }
  }

  &__list {
    padding: 0;
  }

  &__container {
    border: 0 none;
    overflow-y: scroll;
    max-height: 400px;
    padding: 0 24px 24px;

    &::-webkit-scrollbar {
      width: 5px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: #f5f5f5; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: @color-gray; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 3px solid transparent; /* creates padding around scroll thumb */
    }
  }

  &__item {
    width: 100%;
    margin-bottom: 16px;
    padding: 15px;
    text-align: left;
    cursor: pointer;
    background: @color-white;
    border: 1px solid @color-silver-darker;
    border-radius: @base-radius;

    &:hover {
      background: @color-grey-light;
    }

    &--seller {
      border-bottom: 1px solid;
      border-bottom-color: @color-silver-lighter;
      margin-bottom: 9px;

      & > * {
        margin-bottom: 6px;
        line-height: 1;
      }
    }

    &--info {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    &--terms {
      font-size: 13px;
    }

    &Price {
      display: block;
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      color: @color-marketplace;

      html[lang='fr-CH'] & {
        sup {
          font-size: 1em;
          top: 0;
        }
      }

      .fnacDarty & {
        color: @color-red;
      }

      &--old {
        color: @color-gray;
        font-size: 13px;
        font-weight: normal;
      }
    }

    .f-faMpSeller__newLabel {
      line-height: 16px;
      font-size: 1.3rem;
      font-weight: bold;
    }
  }

  &__OPC {
    margin-bottom: 6px;
    .stimuliOPC-label {
      text-transform: lowercase;
      border-radius: 10px;
      line-height: 16px;
      font-size: 1.3rem;
      font-weight: bold;
    }
  }

  &__shipping {
    display: block;
    float: right;
    color: @color-gray-darker;
    line-height: 14px;

    sup {
      font-size: 1em;
      top: 0;
    }
  }

  &__stock {
    font-weight: bold;

    &--available {
      color: @color-forestGreen;
      line-height: 16px;
      margin-bottom: 4px;
      text-align: right;
    }
  }

  &__productStatus {
    line-height: 1.2;
    display: block;
    margin: 0;
  }

  &__delivery,
  &__condition,
  &__sellerPicture {
    display: block;
  }

  &__sellerName {
    display: flex;
    column-gap: 5px;
    color: @color-blue-dark;
    font-size: 14px;
    font-weight: bold;
    margin-left: 2px;
    margin-top: -3px;

    &Review {
      color: @color-gray-darker;
      font-weight: 400;
    }
  }

  &__sellerIcon {
    vertical-align: middle;
    height: 14px;
  }

  &__fnacSeller {
    height: 50px;
  }

  &__sellerStatus {
    color: @color-dark;
    display: block;
    font-weight: bold;
  }

  &__toggleOffers {
    background: @color-silver-lighter;
    border-top: 1px solid @color-silver-darker;
    cursor: pointer;
    font-size: 13px;
    padding: 11px 0;
    text-align: center;
    text-decoration: none;
    transition: all 0.25s ease-out;

    &:hover {
      background: @color-white;
      text-decoration: none;
    }
  }

  &__popin.ff-modal:not(.mfp-loading) {
    max-width: 600px;
    padding: 0;
  }

  .f-faMpSeller__statusLabel {
    display: inline-block;
    color: @color-gray-darker;
    font-weight: normal;
    &::before {
      display: none;
    }
  }
}
