.reassurance {
  border-bottom: 1px solid @color-silver-darker;
  display: table;
  table-layout: fixed;
  width: 100%;
  background: @color-dark;
  height: 77px;
}

.reassurance-block {
  width: (100% / 3);
  text-align: left;
  display: table-cell;
  vertical-align: middle;

  &:hover,
  &:focus {
    background: lighten(@color-dark, 5);
    text-decoration: none;
  }

  &:first-child {
    border: 0;

    .reassurance-content {
      border-left-color: transparent;
    }
  }
}

.reassurance-content {
  padding: 0 15px;
  margin-top: 15px;
  border-left: 1px solid @color-gray-darker;
}

.reassurance-pics {
  float: left;
  display: inline-block;
  top: -8px;
  position: relative;
  margin-right: 10px;
  height: 50px;
}

.reassurance-title {
  font-size: unit((15px / 12px), em);
  color: @color-white;
  line-height: 1.3;
  display: block;
  .text-overflow();
}

.reassurance-text {
  font-size: 1em;
  color: @color-gray;
  .text-overflow();
  display: block;
}

.reassurance-main {
  display: block;
  padding-top: 0;
}

@media (max-width: @screen-md-min) {
  .reassurance {
    display: none;
  }

  .reassurance-block {
    border-left: 0;
    border-top: 1px solid @color-silver-darker;
    display: block;
    width: 100%;

    &:first-child {
      border: 0;
    }
  }

  .reassurance-title {
    text-align: left;
    font-weight: normal;
  }

  .reassurance-text {
    line-height: 20px;
  }

  .reassurance-pics {
    display: inline-block;
  }

  .reassurance-content {
    max-width: 100%;
    padding: 20px;

    img {
      margin: 0;
    }
  }

  .reassurance-main {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
  }
}

.reassurance--dark {
  background: @color-white;

  .reassurance-title {
    color: @color-dark;
  }

  .reassurance-content,
  .reassurance-block {
    border-color: #efefef;

    &:hover {
      background: #efefef;
    }
  }

  a {
    color: @color-white;
  }
}
