@import (reference) '../../styles/_library-mobile.less';

.f-autocomplete {
  @searchHeight: 64px;

  display: none;
  position: fixed;
  z-index: 4000;
  top: @searchHeight;
  left: 0;
  width: 100%;
  background: @color-white;
  overflow: auto;
  height: calc(100vh - @searchHeight);
  -webkit-overflow-scrolling: touch;

  &-item {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 18px;

    &:active {
      background: @color-silver-darker;
    }

    b {
      font-weight: bold;
    }
  }

  &-button {
    background: none;
    border: none;
    padding: 10px 16px;
    color: @color-dark;

    &--historic {
      padding: 10px 0 10px 16px;
    }

    &--title {
      width: 100%;
      text-align: left;
      .text-overflow();
    }

    &--close {
      display: flex;

      &:active {
        .f-autocomplete-icon {
          color: @color-dark;
        }
      }

      .f-autocomplete-icon {
        //width: 10px;
        margin: 0;
      }
    }
  }

  &-icon {
    color: @color-gray;
    margin: 0 16px 0 0;
    font-size: 16px;
  }

  &.active {
    display: block;
  }

  // When header is scrolled, with translateY(-51px)
  .scrolled-header & {
    top: @searchHeight;
  }
}
