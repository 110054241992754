//-------------------- COMMON ---------------------//
//-------------------------------------------------//

.strateLifestyle {
  .lifeStyle-container {
    padding: 0 30px 10px 30px;
    max-width: 1400px;
    margin: 0 auto;
    .clearfix();

    .lifestyle-Item {
      z-index: 1;

      .thumbnail {
        &-quantityContainer {
          bottom: -50px;
          padding-top: 0px;
        }
        &-content .thumbnail-btn {
          bottom: -85px;
        }
      }

      &--postControlImage {
        .expertItem-categorie {
          display: none;
        }

        .expertItem-title {
          margin-top: 0;
        }

        .expertItem-description {
          margin-bottom: 10px;
        }
      }
    }

    @media (max-width: @screen-lg-min) {
      max-width: 1024px;
      padding: 10px 10px 15px 10px;
    }

    @media (max-width: @screen-tablet-landscape) {
      padding: 0 20px 10px 20px;
    }

    &:hover {
      .lifestyle-Item {
        opacity: 0.5;
      }

      .lifestyle-Item:hover {
        opacity: 1;
      }
    }
  }

  .lifestyle-Article__Content {
    display: block;
    text-align: center;
    padding: 20px;

    @media screen and (min-width: @screen-sm-min) and (max-width: @screen-tablet-landscape) {
      font-size: 0.9em;
      padding: 15px;

      .thumbnail-title {
        height: 18px;
      }

      .thumbnail-imgWrap {
        margin-bottom: 5px;
      }
    }

    .Article-itemVisual {
      .thumbnail-imgContent {
        height: auto;
        max-height: 90px;
      }
    }

    .thumbnail-sub {
      height: auto;

      @media (max-width: @screen-tablet-landscape) {
        display: none;
      }
    }
  }

  .Strate-top {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px 45px;

    @media (max-width: @screen-tablet-landscape) {
      padding: 30px;
    }
  }

  .thumbnail {
    padding: 0;
    border: 0;

    @media (max-width: @screen-tablet-landscape) {
      max-height: 400px;
    }
  }

  .thumbnail-content {
    position: relative;

    .thumbnail-btn {
      display: block;
      bottom: -60px;

      @media (max-width: @screen-tablet-landscape) {
        bottom: -50px;
      }
    }

    .thumbnail-btnOpen {
      display: none;

      @media (max-width: @screen-tablet-landscape) {
        display: block;
      }
    }
  }

  .thumbnail-btnOpen {
    right: 0;
    top: 0;
  }

  .stimuliOPC {
    z-index: 9999;
    left: -20px;
    top: -20px;

    @media (max-width: @screen-tablet-landscape) {
      left: -15px;
      top: -15px;
    }
  }
}

.lifestyle-wrapper {
  &--black {
    color: @color-white;
    background-color: @color-dark-light;
  }

  &--blackless {
    color: @color-white;
    background-color: @color-dark-light;
  }

  &--middle {
    color: @color-white;
    background-color: @color-gray;
  }

  &--whiteless {
    color: @color-black;
    background-color: @color-gray;
  }

  &--white {
    color: @color-black;
    background-color: @color-white;
  }

  .lifestyle-ItemContent {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    margin: 0 10px;
    padding-right: 9px;
    background: @color-white;

    &--bottom {
      bottom: 0;
      padding: 20px;
      top: inherit;
      height: auto;
      background: @color-white;
      z-index: 1;

      .lifestyle-ItemContent__Text {
        font-size: 14px;
        line-height: 1.4;
        font-weight: 300;
        max-height: initial;
        overflow: hidden;

        @media (max-width: @screen-tablet-landscape) {
          font-size: 13px;
          max-height: 34px;
          overflow: hidden;
        }

        @media (max-width: @screen-sm-min) {
          font-size: 15px;
          max-height: 46px;
          overflow: hidden;
        }
      }
    }

    &.Texte-content {
      padding: 20px;
    }

    &__Title {
      margin-top: 0;
      margin-bottom: 18px;
      font-size: 20px;
      line-height: 1.4;
      color: @color-dark-lighter;
      font-family: @typoFnac;
      font-weight: 300;

      @media (max-width: @screen-tablet-landscape) {
        font-size: 14px;
        margin-bottom: 10px;
      }

      @media (max-width: @screen-sm-min) {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    &__Text {
      color: @color-dark-lighter;
      font-size: 14px;
      line-height: 1.4;
      margin: 0;
      font-weight: 300;
    }

    .lifestyle-Article__head {
      cursor: pointer;
      width: 100%;
      height: 100%;

      .lifestyle-Article__Image {
        width: 100%;
        height: 100%;
        max-height: 100%;
        display: block;
        background-size: cover;
        background-position: center center;
      }
    }

    &.Video-content {
      background: transparent;
      height: 100%;

      a {
        display: block;
        height: 100%;
        background-position: center center;
        background-size: cover;
      }

      .icon {
        top: -22px;
      }

      .lifestyle-Article__Image {
        border-radius: 0;
        left: 0;
        top: 0;
        margin-left: 0;
        margin-top: 0;
        position: absolute;
      }

      @media (max-width: @screen-tablet-landscape) {
        height: 90%;
      }
    }

    @media (max-width: @screen-tablet-landscape) {
      .expertItem-more {
        right: 20px;
        color: @color-orange;
      }
    }
  }
  /* PRODUCT */
  .lifestyle-Item--Product {
    .lifestyle-Article__head + .lifestyle-Article__Content {
      display: none;
    }

    &:hover {
      .lifestyle-Article__head {
        display: none;
      }

      .lifestyle-Article__Content {
        display: block;
      }
    }

    .thumbnail-priceOld {
      color: @color-black;
    }
  }
  /* COMMUNAUTAIRE */
  .expertItem-header {
    padding: 20px 40px 0 120px;

    .expertItem-vignette {
      top: 20px;
      left: 20px;
    }

    .expertItem-author {
      padding-top: 20px;
    }

    .expertItem-authorLink {
      color: @color-black;
    }

    ~ .expertItem-content {
      padding: 0 20px 30px 20px;
    }
  }

  .expertItem-content {
    padding: 4% 5% 5% 5%;
    max-height: 75px;
    overflow: hidden;

    @media (max-width: @screen-lg-min) {
      padding: 5% 5% 5% 5%;
      max-height: 100px;
    }
  }

  .expertItem-image {
    max-height: 71%;
    height: 260px;
  }

  .expertItem-more {
    right: 20px;
    color: @color-orange !important;
  }

  .expertItem-image + .expertItem-content {
    .expertItem-title {
      margin-top: 0;
    }
  }

  .expertItem-categorie {
    padding: 0;
    display: inline-block;
    color: @color-dark;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    top: 20px;
    left: 120px;
  }

  .expertItem-title {
    color: @color-dark;
    font-size: 20px;
    min-height: 0;
    overflow: hidden;
    display: table-cell;
    vertical-align: middle;

    @media (max-width: @screen-lg-min) {
      max-height: 38px;
      font-size: 16px;
      line-height: 18px;
    }

    @media (max-width: @screen-tablet-landscape) {
      font-size: 20px;
      line-height: 20px;
      height: 65px;
    }

    @media (max-width: @screen-sm-min) {
      font-size: 20px;
      line-height: 22px;
    }
  }

  .expertItem-description {
    display: none !important;
    font-size: 14px !important;
    color: @color-dark;
    margin-top: 0 !important;

    @media (max-width: @screen-tablet-landscape) {
      font-size: 12px !important;
      line-height: 16px !important;
    }

    @media (max-width: @screen-sm-min) {
      font-size: 15px !important;
      line-height: 16px !important;
    }
  }

  .expertItem-bottom {
    display: none;
    position: absolute;
    bottom: 0;
    background: @color-white;
    padding: 5px 20px 20px 20px;
    width: 97.5%;
    margin: 0 10px;
    z-index: 9999;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 20%);
    background-repeat: repeat-x;

    .expertItem-published-date {
      margin-right: 10px;
    }

    @media (max-width: @screen-sm-min) {
      padding: 12px 20px 20px 20px;
    }

    span {
      color: #999;
      line-height: 14px;
    }

    .expertItem-published-date,
    .expertItem-num-views {
      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

//--------- Template 5 vignettes vertical ---------//
//-------------------------------------------------//
.lifestyle-wrapper--fiveBlocksVertical {
  &:first-child {
    padding-top: 55%;
    .expertItem-image {
      height: 40%;
    }
  }
  .lifestyle-Item {
    width: (100% / 3);
    padding-top: 27%;
    position: relative;
    float: left;
    overflow: hidden;
    margin-bottom: 1%;
    &.nb1 {
      padding-top: 55%;
      @media (max-width: @screen-tablet-landscape) {
        padding-top: 61%;
      }
      .expertItem-image {
        max-height: 85%;
        height: 85%;
      }
    }
    &:nth-child(1n + 6) {
      display: none;
    }
  }
  .lifestyle-Article__head {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  .lifestyle-Article__Image {
    width: 100%;
    display: block;
    background-size: cover;
    background-position: center center;
    max-height: 71%;
    height: 260px;
  }
  .lifestyle-Article__Content {
    display: none;
    color: blue;
  }
  @media (max-width: @screen-tablet-landscape) {
    .lifestyle-Item {
      width: 50%;
      padding-top: 49.55%;
      &.nb1 {
        padding-top: 100%;
      }
    }
  }
}

// Template 5 vignettes horizontal //
.lifestyle-wrapper--fiveBlocksHorizontal {
  .lifestyle-Item {
    width: (100% / 3);
    padding-top: 27%;
    position: relative;
    float: left;
    overflow: hidden;
    margin-bottom: 0.6%;
    &.nb1 {
      width: (100% * 2 / 3);
    }
    &:nth-child(1n + 6) {
      display: none;
    }
  }
  @media (max-width: @screen-tablet-landscape) {
    .lifestyle-Item {
      width: 50%;
      padding-top: 50%;
      margin-bottom: 1.05%;
      &.nb1 {
        width: 100%;
        padding-top: 40%;
      }
    }
  }
}

// Template 4 vignettes vertical //
.lifestyle-wrapper--fourBlocksHorizontal {
  .lifestyle-Item {
    width: (100% / 3);
    padding-top: 27%;
    position: relative;
    float: left;
    overflow: hidden;
    margin-bottom: 10px;
    &.nb1,
    &.nb3 {
      width: (100% * 2 / 3);
    }
    &:nth-child(1n + 5) {
      display: none;
    }
  }
  @media (max-width: @screen-tablet-landscape) {
    .lifestyle-Item {
      padding-top: 50%;
      &.nb1,
      &.nb2 {
        width: 100%;
      }
      &.nb3,
      &.nb4 {
        width: 50%;
      }
    }
  }
}

// Template 4 vignettes horizontal //
.lifestyle-wrapper--fourBlocksVertical {
  .lifestyle-Item {
    width: (100% / 3);
    padding-top: 27%;
    position: relative;
    float: left;
    overflow: hidden;
    margin-bottom: 10px;
    &.nb1,
    &.nb2 {
      padding-top: 55%;
      .expertItem-image {
        height: 60%;
      }
    }
    &:nth-child(1n + 5) {
      display: none;
    }
    .thumbnail-imgContent {
      max-height: inherit;
      width: 80%;
    }
    @media (max-width: @screen-tablet-landscape) {
      &.nb1,
      &.nb2 {
        padding-top: 61%;
      }
    }
  }
  @media (max-width: @screen-tablet-landscape) {
    .lifestyle-Item {
      width: 50%;
      &.nb1,
      &.nb2 {
        padding-top: 100%;
        .expertItem-image {
          height: 70%;
          max-height: 70%;
        }
      }
      &.nb3,
      &.nb4 {
        padding-top: 50%;
      }
    }
  }
}

// Template 3 vignettes //
.lifestyle-wrapper--threeBlocks,
.lifestyle-wrapper--twoBlocksVertical {
  .lifestyle-Item {
    width: (100% / 3);
    padding-top: 27%;
    position: relative;
    float: left;
    overflow: hidden;
    margin-bottom: 10px;
    &.nb1 {
      width: (100% * 2 / 3);
      padding-top: 55%;
      @media (max-width: @screen-tablet-landscape) {
        padding-top: 61%;
      }
    }
    &:nth-child(1n + 4) {
      display: none;
    }
    .lifestyle-ItemContent .expertItem-image {
      height: 60%;
    }
  }
  @media (max-width: @screen-tablet-landscape) {
    .lifestyle-Item {
      width: 50%;
      padding-top: 50%;
      &.nb1 {
        padding-top: 100%;
        width: 100%;
      }
    }
  }
}
