// Popup ---------------------------------------- //
// Used in popup using magnific-popup plugin


.Popup {
  background-color: @color-white;
  width: 700px;
  max-width: 100%;
  margin: 0 auto 20px;
  position: relative;
  padding: 50px;
}

.Popup--small {
  width: 600px;
}

.Popup--medium {
  width: 800px;
}

.Popup--large {
  width: 900px;
}
