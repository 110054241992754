@import (reference) '../../styles/_library.less';

.drop-open {
  .f-header when (@ismobile) {
    max-width: 100%;
  }
}

.f-sortingProducts {
  align-items: center;
  background: unset;
  border-radius: @base-radius;
  border: 0;
  color: @color-dark;
  display: inline-flex;
  font-size: 24px;
  height: 42px;
  justify-content: center;
  margin: 0 0 0 8px;
  padding: 0;
  width: 42px;
  & when (@ismobile) {
    margin-right: 16px;
  }
  .f-productSection__header & {
    margin: 0;
  }

  // strates Smile mobile
  .mobileProductList & {
    height: 24px;
    margin-right: 16px;
    width: 32px;
  }

  // tether-drop custom classes
  &__drop.drop-element {
    z-index: 2;
  }

  &__drop_offers.drop-element {
    z-index: 1001;
  }

  &__drop_basket.drop-element {
    z-index: 10046;
  }

  &__menu {
    background: @color-white;
    border-radius: 8px;
    box-shadow: 0 0 12px 0 #0000000d, 0 5px 8px 0 #0000000d, 0 2px 4px 0 #0000000d;
    color: @color-dark;
    display: none;
    flex-direction: column;
    font-size: 13px;
    line-height: 16px;
    width: 260px;

    // tether-drop element classes
    .drop-element.drop-open & {
      display: flex;
    }
  }

  &__title {
    display: block;
    font-weight: bold;
    padding: 16px;
  }

  &__link {
    background: 0;
    border: 0;
    color: @color-dark;
    display: flex;
    padding: 16px;
    width: 100%;

    &:hover,
    &:focus-visible {
      background: @color-silver-lighter;
      text-decoration: none;
    }

    &--terms {
      border-top: 1px solid @color-silver-darker;
      text-decoration: underline;

      &:hover,
      &:focus-visible {
        background: 0;
        text-decoration: underline;
      }
    }

    .f-icon {
      font-size: 16px;
      margin: 0 16px 0 0;
      opacity: 0;
    }

    &.selected {
      cursor: default;
      text-decoration: none;

      .f-icon {
        opacity: 1;
      }
    }
  }
}
