// BasketPopin ---------------------------------------- //
.BasketPopin {
  background: @color-white;
  margin: 0 auto;
  max-width: 100%;
  min-height: 200px;
  position: relative;
  width: 1000px;

  // NCarousel
  .f-nCarousel {
    .nCarouselRoundedArrows();
    .nCarouselPositionArrows(10px);
    // Arrows are over the slides
    &.f-nCarousel--arrows {
      padding: 0;
    }
  }

  &-closeButton {
    margin: 15px 0 0;
    border: none;
    background-color: inherit;
    font-size: 13px;
    line-height: 19px;

    .BasketPopin--withWarranty &,
    .BasketPopin--multiProductMax &,
    .BasketPopin--multiProduct & {
      margin: 5px 15px 10px 0;
    }
  }

  &-title {
    text-transform: uppercase;
    margin-bottom: 10px;
    padding: 30px;
    padding-bottom: 0;

    b {
      font-weight: bold;
    }
  }

  &-errorMessage {
    padding: 30px;
    padding-bottom: 0;
    font-size: 18px;
    font-weight: 300;
  }

  &-subtitle {
    color: @color-gray;
    font-size: 14px;
    padding: 0 30px;
    margin-left: 5px;

    &-fr {
      color: @color-orange;
    }
    @media (max-width: @screen-md-min) {
      margin-left: 0;
    }
  }

  &-action {
    position: absolute;
    right: 30px;
    top: 115px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-height: @screen-sm-min) {
      top: 85px;
    }

    .BasketPopin--withWarranty &,
    .BasketPopin--multiProductMax &,
    .BasketPopin--multiProductCarousel & {
      position: absolute;
      right: 20px;
      top: 20px;
      flex-direction: row-reverse;
    }

    @media (max-width: @screen-sm-min) {
      .BasketPopin--multiProduct & {
        position: absolute;
        right: 20px;
        top: 16px;
        width: 320px;
      }
    }
  }

  &-actionButton {
    margin: auto;

    .BasketPopin--multiProduct & {
      margin-bottom: 5px;
    }

    html[lang='nl-BE'] & {
      width: 210px;
    }

    .f-icon {
      margin-left: 5px;
      margin-right: 0;
    }
  }

  .f-addBasketQuantity {
    &-container {
      position: absolute;
      top: -50px;
      left: 10px;

      .BasketPopin--withWarranty &,
      .BasketPopin--multiProductMax & {
        top: 50px !important;
        right: 10px !important;
        left: auto !important;
      }
    }

    &-content {
      display: flex;
      width: 150px;
      padding-top: 0;
      margin: 0 auto;
      z-index: 999;
      background: 0;
      position: initial;
      text-align: center;
    }

    &-quantity,
    &-decrease,
    &-increase {
      transition: color 0.3s ease;
      border: 0;
      padding: 0;
      position: relative;
      float: left;

      &:hover {
        transition: color 0.3s ease;
        color: @color-dark;
      }
    }

    &-quantity {
      text-align: center;
      height: 25px;
      width: 80px;
      border: 1px solid @color-orange;
      font-size: 15px;
      line-height: 20px;
      color: @color-dark;
      font-weight: normal;
    }

    &-decrease,
    &-increase {
      background: none;
      width: 35px;
      height: 26px;
      font-size: 26px;
      line-height: 26px;
      color: @color-orange;
    }

    &-buyBox {
      padding: unit((20px / 12px), em) 0;
    }
  }

  // PRODUCTS CONTAINER STYLE ---------------------------------------- //
  &-products {
    display: flex;
    padding-right: 0;
    width: 100%;

    html[lang='nl-BE'] & {
      padding-right: 290px;
    }
  }

  &-productTitle {
    font-size: 1.1em;
    margin-bottom: 0;
    margin-top: 10px;
    font-weight: normal;
    height: 30px;
    overflow: hidden;
    display: block;
    -webkit-line-clamp: 2;

    &--solo {
      text-align: left;
      vertical-align: middle;
      height: 100px;
      line-height: 100px;
      margin: 0;
      padding: 0 0 0 10px;

      @media screen and (max-height: @screen-sm-min) {
        height: 70px;
        line-height: 70px;
      }

      span {
        vertical-align: middle;
        display: inline-block;
        max-width: 56%;
        line-height: 1.2;
        padding-left: 5px;

        @media screen and (max-width: @screen-sm-min) {
          max-width: 55%;
        }
      }
    }
    .f-nCarousel & {
      width: 100%;
      padding: 0 10px;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }

  &-product {
    position: relative;
    min-height: 140px;
    overflow: hidden;
    margin-bottom: 20px;
    font-size: 1em;
    margin-top: 20px;
    font-weight: normal;
    padding: 0 15px;
    color: @color-dark;
    text-align: center;

    &:not(:first-child) {
      padding-left: 40px;

      &::before {
        content: '+';
        position: absolute;
        font-size: 40px;
        top: 33%;
        left: 0;
      }
    }

    .f-nCarousel & {
      width: 100%;
      flex-direction: column;
      justify-content: inherit;
      min-height: auto;
    }

    &--partner {
      font-size: 1.1em;
      .promoODR-logo {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &-Warranty {
    border: 1px solid @color-silver-darker;
    border-left: 0;
    box-shadow: 10px 0 0 @color-green-lighter inset;
    margin: 10px 0 10px 30px;
    padding: 0 20px 0 30px;
    position: relative;

    @media (max-width: @screen-md-min) {
      margin: 0 15px 15px;
    }

    &::before {
      content: '+';
      position: absolute;
      font-size: 40px;
      color: @color-gray;
      transform: translate(-60px, -50%);
      top: 50%;

      @media (max-width: @screen-md-min) {
        content: none;
      }
    }
  }

  &-WarrantyBlock {
    float: left;
    width: 67%;

    @media (max-width: @screen-md-min) {
      width: 100%;
      transform: none;
    }
  }

  &-WarrantyItem {
    font-size: 14px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + & {
      border-top: 1px dashed @color-silver-darker;
    }
  }

  .f-basketPopin-basketAdd {
    &:hover {
      background: @color-orange-hover;
    }

    &--added {
      background: @color-forestGreen;
      color: @color-white;
      border: none;

      &:hover {
        background: @color-forestGreen;
        cursor: default;
      }
    }
  }

  &-WarrantyTitle {
    font-size: 18px;
    color: @color-gray;
  }

  &-detail {
    font-weight: normal;
    font-size: 12px;
    padding-left: 30px;
  }

  &-WarrantyPrice {
    color: @color-dark;
    font-weight: normal;
  }

  &-WarrantyMonthly {
    color: @color-green-lighter;
    font-weight: bold;
  }

  &-WarrantySubTitle {
    font-weight: bold;
  }

  &-img {
    max-width: 100px;
    max-height: 100px;

    &--solo {
      max-width: 170px;

      @media screen and (max-height: @screen-sm-min) {
        max-height: 70px;
      }
    }

    &Container {
      min-height: 100px;
      margin: auto;
      align-items: center;
      display: flex;
      justify-content: center;

      &--solo {
        float: left;
      }
    }
  }

  &-productSolo {
    font-size: 1.2em;
  }

  // WARRANTIES -------------------------------------- //
  &-warrantyWrap {
    background: @color-green-lighter;
    line-height: 50px;
    padding: 10px;
    color: @color-white;
    font-size: 16px;
    padding-left: 30px;
  }

  // Recommendation ---------------------------------- //
  &-reco-header {
    align-items: center;
    background: @color-silver-lighter;
    border-top: 1px solid @color-silver-darker;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 0 16px;
  }

  &-reco-title {
    margin: 0;
  }

  // TODO move that code elsewhere: use a miniFA modifier to provide a context that say "here the product thumbnail can be smaller"
  //  or generalize (on every pages) the possibility on small screen to reduce product thumbnail sizes (padding, height, etc.)
  @media screen and (max-height: @screen-sm-min) {
    width: 800px;

    .miniFA {
      max-height: 320px;
      padding-top: 20px;

      &__image {
        max-height: 90px;
        min-height: initial;
      }

      &__title {
        max-height: @line-height-base * 2em;
      }

      &__subtitle {
        max-height: 18px;
      }

      &__greenLabel {
        top: calc(90px + 24px);
      }
    }
  }

  .miniFA {
    &__button {
      padding: 12px;
    }
  }

  &-products .f-nCarousel__item {
    &:not(:first-child) {
      position: relative;

      &::before {
        content: '+';
        position: absolute;
        font-size: 40px;
        top: 33%;
        left: -12px;
      }
    }
  }

  &-progress-text {
    font-size: 13px;

    &--completed::before {
      background-image: colorize(data-uri(icon('check-circle')), @color-forestGreen);
      content: '';
      width: 17px;
      height: 17px;
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
    }
  }

  & .f-progressBar {
    background: @color-silver-darker;
  }

  &-progress-container {
    padding: 20px 40px;
    background: @color-silver-lighter;
    border-top: 1px solid @color-silver-darker;
    #darcosInfoTooltip {
      display: none;
    }
  }
  &-progress-tooltip-icon {
    background-image: colorize(data-uri(icon('help')), @color-black);
    width: 13px;
    display: inline-block;
    height: 13px;
    transform: translateY(2px);
  }
}

.BasketPopin-progress-container ~ .thumbnail-reco .BasketPopin-reco-header {
  border-top: none;
}
