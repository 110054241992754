@path: if(@ismobile, '../../_library-mobile.less', '../../_library.less');
@import (reference) '@{path}';

// --------------------------------------------------------------------------------
// MODALS
// How to use it:
// In your JS view, add this option to your Magnific Popup (MFP) custom function:
// https://dimsemenov.com/plugins/magnific-popup/documentation.html#options
// {
//   mainClass: 'mfp--modal', // CSS class of the modal OR via a "data-class" attribute for inline MFP
// }
// Or use MFP from inline element with "js-PopupInline" class :
// https://dimsemenov.com/plugins/magnific-popup/documentation.html#inline-type
// <a href="#myModal" data-class="mfp--modal" class="js-PopupInline">Open Modal</button>
// OR
// <button data-mfp-src="#myModal" data-class="mfp--modal" class="js-PopupInline">Open Modal</button>
//
// MODAL HTML STRUCTURE :
// Code generated by MFP
// <div class="mfp mfp--modal">
//   <div class="mfp-container">
//     <div class="mfp-content">
//      ... modal content ...
//     </div>
//   </div>
// </div>
//
// EXAMPLE OF MODAL CONTENT :
// <div id="myModal" class="ff-modal mfp--modal">
//   <p class="ff-modal__title">...</p>
//   <div class="ff-modal__content">...</div>
//   <div class="ff-modal__text">...</div>
//   <button title="Fermer" type="button" class="mfp-close" aria-label="Fermer">×</button> // Default code generated by MFP
// </div>
// --------------------------------------------------------------------------------

// MFP OVERRIDES
// Add the "mfp--modal" class with the "mainClass" MFP option
// https://dimsemenov.com/plugins/magnific-popup/documentation.html#mainclass
// Or with a "data-class" attribute if you use "js-PopupInline"
.mfp--modal {
  // .mfp-content {
  //   width: 100%;
  // }

  .mfp-preloader {
    width: 100px;
    height: 100px;
    top: calc(50% - 50px);
    margin: auto;
    background: url('../../../images/loader.svg') no-repeat;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  .mfp-close {
    color: @color-white;
    height: @modal-close-size;
    line-height: @modal-close-size;
    opacity: 1;
    width: @modal-close-size;

    &:hover {
      opacity: 0.7;
    }
  }

  &.mfp-close-btn-in .mfp-close {
    position: absolute;
    top: -@modal-close-size;
    color: @color-white;
    right: 0;

    &:active {
      top: -@modal-close-size + 1;
    }
  }
}

// CSS CLASS TO USE ON THE CONTENT OF THE POPIN
.ff-modal {
  @modalPadding: 30px;
  & when (@ismobile) {
    bottom: 0;
    left: 0;
    padding: unset;
    max-height: calc(100% - @modal-close-size);
    overscroll-behavior: contain;
    position: fixed;
    right: 0;
    transform: translateY(calc(100% + @modal-close-size)) translateZ(0);
    transition: transform @modal-removal-delay ease-in-out;
    -webkit-overflow-scrolling: touch;
  }
  & when not (@ismobile) {
    position: relative;
  }
  &:not(.mfp-loading) {
    padding: @modalPadding;
    border-radius: @base-radius;
    background: @color-white;
    color: @color-dark;
    & when (@ismobile) {
      margin: 0;
      padding: @modal-padding-mobile;
      border-radius: @base-radius @base-radius 0 0;
    }
    & when not (@ismobile) {
      margin: @modal-close-size auto;
    }
    &.ff-modal--nopadding {
      padding: 0;
    }
  }
  & when (@ismobile) {
    .mfp-ready & {
      transform: translateY(0);
    }
    .mfp-removing & {
      transform: translateY(calc(100% + @modal-close-size));
    }
    &__inner {
      overflow: hidden auto;

      @supports (height: 100dvh) {
        max-height: calc(100dvh - @modal-close-size - @modal-padding-mobile * 2);
      }

      @supports not (height: 100dvh) {
        max-height: calc(100vh - @modal-close-size - @modal-padding-mobile * 2);
      }
    }
  }

  &.mfp-loading .mfp-close {
    display: none;
  }

  &__title,
  .mfp-title {
    display: inherit;
    margin: 0 0 25px;
    padding: 0;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: currentcolor;
    .typoFnacBold();

    &:empty {
      display: none;
    }

    .mfp-loading & {
      display: none;
    }
  }

  &__text {
    font-size: 13px;
    line-height: 19px;
  }

  &__content {
    font-size: 13px;
    line-height: 18px;
  }

  // SIZE
  &--small {
    max-width: 380px;
  }

  &--medium {
    max-width: 600px;
  }

  &--large {
    max-width: 800px;
  }
}

/****************************************************************************/
/*                               OLD STYLES                                 */
/*                              DON'T USE IT!                               */
/****************************************************************************/
/* TODO: Replace all the old classes by the new ones                        */
/****************************************************************************/
.f-mfp {
  // TITLE
  .f-mfp-title {
    color: @color-white;
    font-size: 21px;
    font-weight: normal;
    margin: 0;
    position: absolute;
    text-transform: uppercase;
    top: -37px;
  }
  // CONTENT
  .f-mfp-content {
    margin: 45px auto 20px !important;
    position: relative;
    // BODY
    .f-mfp-body {
      background: @color-white;
      color: @color-dark;
      padding: 20px;

      &__center {
        text-align: center;
      }

      &__img {
        height: 44px;
        margin-left: 10px;
      }

      &__label {
        padding-top: 20px;
      }
    }
    // button to Close
    .mfp-close {
      color: @color-white;
      font-size: 36px;
      opacity: 1;
      right: -14px;
      top: -45px;
      text-align: center;

      &:hover {
        opacity: 0.65;
      }
    }
    // FOOTER
    // .f-mfp-footer {
    //   background: @color-white;
    //   padding: 0 20px 20px;

    //   .icon_i_102_check_03 {
    //     color: @color-forestGreen;
    //   }

    //   .ff-button {
    //     margin-bottom: 20px;
    //   }
    // }
  }
  // SIZE
  // .f-mfp--small {
  //   width: 100%;
  //   max-width: 380px;
  // }

  .f-mfp--medium {
    width: 100%;
    max-width: 600px;
  }
}

// POPIN
//
// GENERIC
// .f-popin {
//   bottom: 0;
//   position: fixed;
//   // display: none;
//   left: 0;
//   outline: 0;
//   right: 0;
//   top: 0;
//   z-index: 10050;
//   overflow-x: hidden;
//   overflow-y: auto;
//   opacity: 1;
// }

// .f-popinDialog {
//   margin: 70px auto 0;
//   position: relative;
//   width: auto;
// }

// .f-popin--small {
//   .f-popinDialog {
//     width: 500px;
//   }
// }

// .f-popin--medium {
//   .f-popinDialog {
//     width: 600px;
//   }
// }

// .f-popinContent {
//   position: relative;
// }

// .f-popinHeader {
//   display: block;
//   padding: 15px 0;
//   color: @color-white;
//   position: relative;
// }

// .f-popinHeader-title {
//   font-size: 21px;
//   font-weight: normal;
//   margin: 0;
//   text-transform: uppercase;
// }

// .f-popinHeader-close {
//   font-size: 18px;
//   position: absolute;
//   right: 0;
//   top: 15px;
// }

// .f-popinBody {
//   background: @color-silver-lighter;
//   display: block;
//   padding: 15px;
// }

// .f-popinFooter {
//   display: block;
//   padding: 15px;
// }
