.centerVisual-container {
  width: 100%;
  max-width: 300px;
  height: 270px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
}

.centerVisual {
  text-align: center;
  overflow: hidden;
}

.centerVisual-image {
  max-width: 100%;
  transition: all 0.2s linear;
  backface-visibility: hidden;
  transform: scale(1);

  &:hover {
    transform: scale(1.05);
  }
}
