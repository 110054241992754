.strate-opc {
  &-head,
  &-content {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &-head {
    padding: 30px 0 70px;

    &--dark {
      color: @color-dark;
    }

    &--light {
      color: @color-white;

      .seeAll-bt {
        color: @color-white;
        border-color: @color-white;

        &:hover {
          border-color: transparent;
        }
      }

      .thumbnail-priceOld {
        color: @color-dark;
      }
    }

    .Strate-top {
      margin: 0;
    }

    .Strate-title {
      float: none;
      display: block;

      &:nth-child(2) {
        font-size: unit((15px / 12px), em);
        text-transform: initial;
      }
    }
  }

  &-content {
    .f-nCarousel {
      top: -55px;
      max-width: calc(100% - 20px);
      background: @color-white;
    }

    .f-nCarousel__itemInner {
      width: 240px;

      .thumbnail {
        width: 100%;
        border: 0;
      }
    }
  }
}
