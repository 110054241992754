@import (reference) '../../../styles/_library.less';

.f-kamino-sword {
  --omnibus-height: 18px;
  --omnibus-height-mt: 8px;

  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #fff;
  padding: 30px 30px 10px 30px;
  & when (@ismobile) {
    padding: 0 16px 10px;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
  }

  &__media {
    position: relative;
    width: 240px;
    max-width: 240px;

    & when (@ismobile) {
      width: 220px;
      max-width: 220px;
    }
    height: max-content;
    display: flex;
    flex-basis: content;
    flex-shrink: 0;
    /* Image fomat */
    a {
      display: flex;
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
        & when (@ismobile) {
          object-fit: contain;
          min-width: 0;
          min-height: 0;
          max-height: 100%;
          width: auto;
        }
      }
    }
    /* Video format */
    video {
      width: 240px;
      height: auto;
      max-width: 240px;
    }
  }

  /* Title */
  .f-productLayer__title {
    padding: 0 0 16px 0;
  }

  /* MiniFa */
  .miniFA.thumbnail {
    width: 240px;
    max-width: 240px;
    & when (@ismobile) {
      width: 220px;
      max-width: 220px;
    }
    border: solid 1px @color-silver-lighter;
  }
  .miniFA__oldPrice {
    margin-bottom: 0;
    display: block;
  }

  .miniFA__button {
    margin-top: calc(var(--omnibus-height) + var(--omnibus-height-mt));
  }
  .f-info + .miniFA__button {
    margin-top: var(--omnibus-height-mt);
  }

  .miniFA__title {
    white-space: normal;
  }

  /* Carousel */
  .f-nCarousel {
    margin: 0;
    overflow-x: hidden;
    & when (@ismobile) {
      overflow-x: auto;
    }
    .nCarouselRoundedArrows();
    .nCarouselPositionArrows(10px);

    &__wrapper {
      width: 100%;
      height: 100%;
    }

    &--arrows {
      padding: 0;
      height: auto;
    }
  }

  /* Sponsored */
  .f-kamino {
    &-sponsored {
      background: white;
      display: flex;
      justify-content: flex-end;
      color: @color-gray;
      align-items: center;
      border: 0;
      align-self: flex-end;
      margin-top: 10px;

      & when (@ismobile) {
        font-size: 12px;
        margin-right: 16px;
      }

      &--icon {
        font-size: 18px;
        & when (@ismobile) {
          font-size: 14px;
        }
        margin-left: 5px;
      }
    }
  }
}

// Sub Homes / Universes
[data-component-id='kamino'] {
  .Strate-top {
    margin: 30px 0 0;
  }
}
