.f-popover-trigger {
  position: relative;
  cursor: pointer;
  display: flex;
}

.f-popover-container {
  display: none;
  left: 50%;
  padding: 30px 0 0 0;
  position: absolute;
  top: 0;
  transform: translate(-50%);
  z-index: 1100;

  &.isActive {
    display: block;
  }
}
.f-popover {
  background: @color-white;
  border: 1px solid @color-silver-darker;
  border-left: 5px solid @color-red-light;
  color: @color-dark;
  font-weight: normal;
  height: auto;
  padding: 10px;
  position: relative;
  text-align: left;
  white-space: normal;
  width: 200px;

  &::before {
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent @color-silver-darker transparent;
    content: '';
    height: 0;
    left: 50%;
    line-height: 0;
    position: absolute;
    top: -10px;
    transform: translate(-50%);
    width: 0;
  }

  &::after {
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent @color-white transparent;
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    line-height: 0;
    top: -8px;
    transform: translate(-50%);
    width: 0;
  }
}

// Position
.f-popover-container--top {
  transform: translate(-50%, -100%);
  left: 50%;
  top: 30px;
  margin-left: -2px;
  padding: 0 0 40px 0;

  .f-popover {
    &::before {
      bottom: -10px;
      top: auto;
      left: 50%;
      border-width: 10px 10px 0 10px;
      border-color: @color-silver-darker transparent transparent transparent;
      transform: translate(-50%);
    }

    &::after {
      bottom: -8px;
      top: auto;
      left: 50%;
      border-width: 8px 8px 0 8px;
      border-color: @color-white transparent transparent transparent;
      transform: translate(-50%);
    }
  }
}

.f-popover-container--right {
  right: -210px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 0 0 25px;

  .f-popover {
    &::before {
      top: 50%;
      left: -10px;
      border-width: 10px 10px 10px 0;
      border-color: transparent @color-silver-darker transparent transparent;
      transform: translateY(-50%);
    }

    &::after {
      top: 50%;
      left: -8px;
      border-width: 8px 8px 8px 0;
      border-color: transparent @color-white transparent transparent;
      transform: translateY(-50%);
    }
  }
}

.f-popover-container--bottom {
  left: 50%;
  top: 0;
  transform: translate(-50%);
  padding: 30px 0 0 0;

  .f-popover {
    &::before {
      top: -10px;
      left: 50%;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent @color-silver-darker transparent;
      transform: translate(-50%);
    }

    &::after {
      top: -8px;
      left: 50%;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent @color-white transparent;
      transform: translate(-50%);
    }
  }

  &-right {
    left: -70px;

    .f-popover {
      &::before {
        left: inherit;
        right: 2px;
      }

      &::after {
        left: inherit;
        right: 6px;
      }
    }
  }
}

.f-popover-container--left {
  left: -210px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 25px 0 0;

  .f-popover {
    &::before {
      top: 50%;
      right: -10px;
      left: inherit;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent @color-silver-darker;
      transform: translateY(-50%);
    }

    &::after {
      top: 50%;
      right: -8px;
      left: inherit;
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent @color-white;
      transform: translateY(-50%);
    }
  }
}
// / Position

.popover-topVendor {
  font-size: 14px;
}

.popover-topVendor-header {
  font-size: 13px;
  margin-bottom: 3px;
  // version Xmas
  font-weight: normal !important;
  color: @color-dark;

  b {
    color: @color-red-light;
    font-weight: 700;
  }
  // end : version Xmas
}

.popover-topVendor-criteria {
  margin: 0;
  font-size: 13px;

  dt {
    font-weight: 700;
  }
}

// POPOVER VENDOR IN CAROUSSEL

.Carousel-item {
  .f-popover-container--bottom-right {
    left: -30px;
  }
  .f-popover-container {
    position: absolute;
    top: -185px;
  }

  .f-popover::before,
  .f-popover::after {
    transform: rotate(180deg);
  }
  .f-popover::before {
    right: 52px;
    top: 138px;
  }
  .f-popover::after {
    right: 54px;
    top: 138px;
  }
}
