.miniFA-loading-carousel {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.miniFA-placeholder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  min-width: 220px;
  height: 100%;
  padding: 10px;
  margin: 0 0 0 16px;
  background-color: @color-white;
  overflow: hidden;

  @media (min-width: @screen-sm-min) {
    padding: 0 20px 0 0;
    margin: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
  }

  &__top,
  &__bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 120px;
    margin-bottom: 15px;
    background-color: @color-silver-dark;
  }

  &__text {
    width: 100%;
    height: 20px;
  }

  &__star {
    width: 70px;
    height: 15px;
  }

  &__bar {
    width: 50%;
    height: 2px;
  }

  &__text,
  &__star,
  &__image,
  &__bar {
    .thumbnailPlaceholderAnimation();
    margin-bottom: 6px;
  }
}

.thumbnailPlaceholderAnimation() {
  animation: productThumbnailLoading 2s infinite linear;
  background-image: linear-gradient(to left, @color-silver-dark 0%, #f2f2f2 20%, #e5e5e5 40%, @color-silver-dark 100%);
  background-size: 200%;
}

@keyframes productThumbnailLoading {
  0% {
    background-position-x: 200%;
  }
  100% {
    background-position-x: 0;
  }
}
