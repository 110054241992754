// --------
// STARS

// HOW TO USE IT
//
// Example      : f-star f-star--small f-star--blue S30
//
// BASE         : f-star
// SIZE         : f-star--small (small, medium, large, xlarge)
// COLOR        : f-star--blue
// RATE         : Sx (added by backend) OR .f-star-rate child with inline CSS width

// STARS BACKGROUNDS

@star-bg: colorize(data-uri(icon('star+fill')), @color-silver-darker);

@star-fg: colorize(data-uri(icon('star+fill')), @color-orange);

@star-fg-mp: colorize(data-uri(icon('star+fill')), @color-marketplace);

// BASE
.f-star {
  overflow: hidden;
  width: 5em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 10px;
  height: 1em;
  white-space: nowrap;
  text-indent: 100%;
  line-height: 1;

  &::before,
  &::after,
  & .f-star-rate {
    content: '';
    background-image: @star-bg;
    background-repeat: repeat-x;
    background-size: 1em;
    height: 1em;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &::after,
  & .f-star-rate {
    background-color: @color-white; // blurry outline without it
    background-image: @star-fg;
    width: 0;
    overflow: hidden;
  }

  // SIZE
  &--small {
    font-size: 16px;
  }

  &--medium {
    font-size: 19px;
  }

  &--large {
    font-size: 24px;
  }

  &--xlarge {
    font-size: 30px;
  }

  // COLOR
  &--marketplace,
  &--blue {
    &::after,
    & .f-star-rate {
      background-image: @star-fg-mp;
    }
  }

  // RATE
  &.S05::after,
  &.S0\.5::after {
    width: 10%;
  }

  &.S1::after,
  &.S10::after {
    width: 20%;
  }

  &.S15::after,
  &.S1\.5::after {
    width: 30%;
  }

  &.S2::after,
  &.S20::after {
    width: 40%;
  }

  &.S25::after,
  &.S2\.5::after {
    width: 50%;
  }

  &.S3::after,
  &.S30::after {
    width: 60%;
  }

  &.S35::after,
  &.S3\.5::after {
    width: 70%;
  }

  &.S4::after,
  &.S40::after {
    width: 80%;
  }

  &.S45::after,
  &.S4\.5::after {
    width: 90%;
  }

  &.S5::after,
  &.S50::after {
    width: 100%;
  }

  &--single {
    width: 1em;

    &::before {
      width: 0;
    }

    &.S1::after {
      width: 100%;
    }
  }
}
