@import (reference) '../../styles/_library.less';

@side-nav-width: 200px;
@promos-width: 200px;
@promos-sm-width: 168px;

.SubSideNavPanel {
  display: none;
  flex: 1;

  &.isActive {
    display: block;
  }

  &Bloc {
    // Side panel content
    background-color: @color-white;
    overflow-y: auto;
    scrollbar-gutter: stable;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    opacity: 1;
    backface-visibility: hidden;
    perspective: 1000px;
    height: 100%;
    border-radius: 0 8px 8px 0;
    .customScrollbars(hidden, auto);

    // promos exists
    &--promos {
      .quicklinks {
        width: calc(100% - @promos-width);

        @media (max-width: 1175px) and (max-height: 768px) {
          width: calc(100% - @promos-sm-width);
        }

        @media (max-width: 1023px) {
          width: calc(100% - 20px);
        }
      }
    }

    .childBloc {
      margin-top: 10px;
    }

    &-links {
      flex: 1;
      padding-right: 2.2em;

      &Row {
        border-bottom: 1px solid @color-silver-darker;
        padding: 16px 0;
        width: 100%;
        float: left;

        @media (max-width: 1175px) and (max-height: 768px) {
          padding: 10px 0;
        }
      }

      &Col {
        width: 25%;
        float: left;
        padding-right: 16px;

        // First column of the first row displayed as column
        // First column in the others rows displayed as row
        .SubSideNavPanelBloc-linksRow:not(:first-child) &:first-child {
          margin: 0 0 16px;
          padding-right: 0;
          width: 100%;

          // Only a title should be displayed on a row
          & > *:not(.SubSideNavPanelBloc-linksItem--title) {
            display: none;
          }
        }

        // @media (max-width: @screen-xs-max) {
        //   width: 50%;
        // }
      }

      &Title {
        color: @color-dark;
        display: inline-block;
        font-size: 28px;
        font-weight: bold;
        line-height: 32px;
        margin: 0 0 8px;
      }

      &Icon {
        height: 32px;
        margin: 0 0 0 8px;
        vertical-align: bottom;
      }

      &Item {
        color: @color-dark;
        line-height: 17px;
        display: block;
        margin-bottom: 8px;
        font-size: 13px;

        &--link {
          color: @color-dark;
          // line-height: 16px;
          display: block;
          // margin-bottom: 4px;

          &:hover {
            color: @color-orange;
            text-decoration: none;
          }
        }

        // Should not be rendered in the view
        &--seeall {
          // color: @color-orange;
          // font-size: 12px;
          // text-transform: none;
          // font-weight: normal;
          // text-decoration: underline;
          display: none;
        }

        &--title {
          // color: @color-dark;
          font-size: 18px;
          // font-weight: bold;
          // text-transform: uppercase;
          // position: relative;
          display: inline-block;
          line-height: 24px;
          margin-bottom: 0;

          & + & {
            margin-top: 12px;
          }

          & .SubSideNavPanelBloc-linksIcon {
            height: 24px;
          }

          a& {
            font-weight: bold;
            // text-decoration: underline;
          }

          // &:hover {
          //   text-decoration: none;
          // }

          &Icon {
            margin: 0 0 0 8px;
          }
        }

        &--subtitle {
          font-weight: bold;
          // text-transform: uppercase;
        }
      }

      &Image {
        width: auto;
        max-width: 100%;
        max-height: 45px;
      }
    }

    &-content {
      padding: 0 0 0 2.4em;

      &Inner {
        display: flex;
      }

      // Links as tags at the top of the panel
      .quicklinks {
        padding: 10px 28px 10px 0;
        list-style: none;
        margin: 15px 0 0;
        min-height: 88px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &-item {
          margin: 0 5px 5px 0;
        }

        &-link {
          color: @color-gray;
          background-color: transparent;
          border: 1px solid @color-silver-darker;
          padding: 3px 10px;
          border-radius: 50px;
          text-decoration: none;

          &:hover {
            background: @color-orange;
            border-color: @color-orange;
            color: @color-white;
          }
        }
      }

      // Promos as images at the right of the panel
      .autopromos {
        // useful?
        width: @promos-width;
        height: 694px;

        // useful?
        @media (max-width: 1175px) and (max-height: 768px) {
          width: @promos-sm-width;
          height: 583px;
        }

        @media (max-width: 1023px) {
          display: none;
        }

        &-item {
          display: block;
        }

        img {
          // useful?
          width: auto;
          height: auto;

          // seems to be useful
          @media (max-width: 1175px) and (max-height: 768px) {
            width: 168px;
            height: 288px;
          }
        }
      }
    }
  }
}
