@import (reference) '../../styles/_library.less';

.f-nCarousel {
  @arrow-size: 40px;
  @arrow-margin: 8px;

  position: relative;
  margin: 0 auto;

  // Default is browser native scroll
  &:not(.hasJs) {
    .scrollsnap();
    > * {
      scroll-snap-align: start;
    }
  }

  &,
  &__wrapper,
  &__scroller {
    height: 100%;
  }

  &__item {
    min-height: 100%;
    height: auto;
  }

  &__wrapper {
    width: 100%;
    overflow: hidden;
  }

  &__scroller {
    display: flex;
    white-space: nowrap;
    overflow-x: visible;
    list-style: none;
    margin: 0;
    padding: 0;
    will-change: transform;
  }

  &__itemInner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    // .focus-visible();
  }

  &__arrow {
    display: none;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    height: 100%;
    cursor: pointer;
    border: none;
    background: transparent;
    // .focus-visible();

    &Icon {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      pointer-events: none;
    }

    &--prev {
      left: 0;
    }

    &--next {
      &,
      .f-nCarousel__arrowIcon {
        right: 0;
        left: auto;
      }
    }

    &.disabled {
      opacity: 0.2;
      cursor: default;
    }
  }
  .nCarouselArrowSize(@arrow-size);

  &--arrows {
    padding: 0;
  }

  &--bullets {
    @bullet-size: 8px;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 28px;

    .f-nCarousel__itemInner {
      width: @bullet-size;
      height: @bullet-size;
      margin: 10px 5px;
      border-radius: 50%;
      background: @color-silver-darker;
      border: 0;
      padding: 0;

      &.active {
        background: @color-orange;
      }
    }
  }
}
