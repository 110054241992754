// 2020 Tight design
// Only one star is displayed instead of 5
.f-rating {
  @starSize: 14px;

  display: flex;
  align-items: center;
  height: @starSize;
  font-size: 15px;
  font-weight: normal;

  &__average,
  &__label {
    height: @starSize;
    line-height: 1;
  }

  &__label {
    margin-left: 6px;
    color: @color-gray;
  }

  &__star {
    width: @starSize;
    height: @starSize;
    margin-right: 4px;
  }

  &--client {
    .f-rating__star,
    .f-rating__average {
      color: @color-orange;
    }
  }

  &--labo {
    .f-rating__star,
    .f-rating__average {
      color: @color-blueLabo;
    }

    .f-rating__star {
      border: 2px solid @color-blueLabo;
      border-radius: 50%;
      padding: 1px;
      margin-top: 1px;
    }
  }

  &--seller {
    .f-rating__star,
    .f-rating__average {
      color: @color-marketplace;
    }

    .f-rating__star {
      font-size: 16px;
      height: 1em;
      width: 1em;
    }
  }
}

.f-rating-small() {
  font-size: 13px;

  &__average,
  &__label {
    line-height: 1.2;
  }
}
