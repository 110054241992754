.needHelp {
  .f-nCarousel__item {
    @media (min-width: 1281px) {
      min-width: 22.22%;
    }

    @media (min-width: 1600px) {
      min-width: 18.18%;
    }

    @media (max-width: 1110px) {
      min-width: 28.57%;
    }

    @media (max-width: @screen-md-min) {
      min-width: 40%;
    }
  }
  &-switch {
    border-top: 1px solid @color-silver-darker;
  }
  &-list {
    padding: 0;
    margin: 0;
  }
  &-item {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    white-space: normal;
    position: relative;
    transform: translate3d(0, 0, 0);
    flex-direction: column;
  }
  &-link {
    display: block;
    font-size: 15px;
    line-height: 19px;
    color: @color-dark;
    padding-bottom: 50px;

    // .needHelp-item fully clickable
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    &::after {
      transition: all 0.2s linear;
      z-index: -1;
    }

    &:hover::after {
      background: @color-silver-lighter;
    }
  }
  &-container {
    display: block;
    padding: 40px 0 20px;
    height: 200px;
  }
  &-image {
    max-width: 100%;
    max-height: 140px;
    width: auto;
  }
}
