@import (reference) '../../styles/_library.less';

.controlButton() {
  border: 0;
  padding: 0;
  background: transparent;
}

.videoPlayer {
  &__container {
    max-width: 700px;
    margin: auto;

    &.launched {
      .videoPlayer__mediaBtn {
        background-color: @color-black;
        background-image: none;
      }
      .videoPlayer__mediaVideo {
        opacity: 1;
      }
    }
  }

  &__content {
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover,
    &:focus-within {
      .videoPlayer__header {
        height: 30px;
        opacity: 1;
      }
      .videoPlayer__controls {
        bottom: 0;
        opacity: 1;
      }
    }

    &:fullscreen {
      padding-bottom: 100vh;
    }

    .immersiveZoom__listItem & {
      padding-bottom: 50%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: @color-white;
    font-size: 12px;
    position: absolute;
    z-index: 3;
    top: 0;
    width: 100%;
    height: 30px;
    background: rgba(@color-dark, 0.6);
    transition: opacity 0.2s ease-in 0.3s;
    opacity: 0;

    @media (min-width: @screen-sm-min) {
      font-size: 14px;
    }

    &Title,
    &Playlist {
      padding: 0 10px;
      border: 0;
      background: transparent;
      .icon {
        margin-top: -4px;
      }
    }

    &Title {
      .text-overflow();
    }

    &Playlist {
      min-width: 120px;
      text-align: right;
    }
  }

  &__playBtnBig {
    @ray-length: 35px;
    position: absolute;
    top: calc(50% - @ray-length);
    left: calc(50% - @ray-length);
    width: @ray-length * 2;
    height: @ray-length * 2;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @color-white;
    transition: transform 0.3s ease-in;
    opacity: 0;

    &:hover {
      transform: scale(1.2);
    }

    .paused & {
      opacity: 1;
    }
  }

  &__playlist {
    position: absolute;
    top: 30px;
    right: 0;
    height: 0;
    width: 320px;
    overflow: hidden auto;
    background-color: @color-dark;
    opacity: 0.9;
    z-index: 3;
    cursor: pointer;
    transition: height 0.2s ease-in 0.1s;

    &--show {
      height: calc(100% - 100px);

      @media (min-width: @screen-sm-min) {
        height: calc(100% - 115px);
      }
    }

    &-item {
      padding: 8px 6px 0 6px;
      border: 0;
      background: transparent;
      display: inline-block;
      width: 100%;

      &:hover {
        background-color: @color-gray-darker;
      }

      &-container {
        display: flex;
        align-items: center;
      }

      &Arrow {
        color: @color-white;
        margin-right: 2px;
        display: flex;
        justify-content: center;
        opacity: 0;
      }

      &Image {
        height: 55px;
        min-width: 75px;
        background-size: cover;
      }

      &Title {
        color: @color-white;
        flex-grow: 1;
        padding: 0 8px;
        font-size: 12px;

        @media (min-width: @screen-sm-min) {
          font-size: 14px;
        }
      }

      &Space {
        width: 90%;
        height: 1px;
        margin: 8px auto 0 auto;
        background-color: @color-gray-darker;
      }

      &--selected {
        .videoPlayer__playlist-itemArrow {
          opacity: 1;
        }
      }
    }
  }

  &__mediaBtn {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: @color-black;
  }

  &__mediaVideo {
    opacity: 0;
    width: 100%;
  }

  &__controls {
    padding: 0;
    position: absolute;
    z-index: 3;
    bottom: 0;
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba(@color-dark, 0) 10%, rgba(@color-dark, 0.6) 80%);
    transition: opacity 0.2s ease-in 0.3s;
    opacity: 0;

    @media (min-width: @screen-sm-min) {
      height: 48px;
    }

    &-main {
      width: calc(100% - 40px);
      margin: auto;
      height: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-pos {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;

      &--left {
        margin-left: 10px;
      }

      &--right {
        justify-content: flex-end;
      }
    }
  }

  &__time {
    position: absolute;
    right: 30px;
    bottom: 100%;
    padding-bottom: 14px;
    color: @color-white;
    font-size: 12px;
  }

  &__progress {
    height: 8px;
    width: calc(100% - 40px);
    background: rgba(@color-dark-lighter, 0.6);
    margin: auto;
    border-radius: 6px;
    position: absolute;
    left: 20px;
    bottom: 100%;
    transition: height 0.1s ease-in-out;
    overflow: hidden;

    &-filled {
      background: @color-orange;
      width: 0;
      height: 100%;
      border-radius: 6px;
      transition: all 0.1s;
    }

    &:hover {
      height: 10px;
    }
  }

  &__volume {
    display: flex;
    align-items: center;
    color: @color-white;

    &-btn {
      margin-right: 10px;
      .controlButton();

      &:hover,
      &:focus {
        color: @color-orange;
      }

      .icon {
        height: 1.4em;
        width: 1.8em;

        @media (min-width: @screen-sm-min) {
          height: 2em;
          width: 2.4em;
        }
      }

      &.loud {
        .icon-high {
          display: block;
        }

        .icon-low,
        .icon-muted {
          display: none;
        }
      }

      &.quiet {
        .icon-low {
          display: block;
        }

        .icon-high,
        .icon-muted {
          display: none;
        }
      }

      &.muted {
        .icon-muted {
          display: block;
        }

        .icon-high,
        .icon-low {
          display: none;
        }
      }
    }

    &-bar {
      height: 9px;
      width: 80px;
      background: rgba(60, 60, 60, 0.6);
      border-radius: 6px;
      position: relative;
      border: 0;
      padding: 0;

      &-filled {
        background: @color-white;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        transition: width 0.2s ease-in-out;
        pointer-events: none;
      }

      &:hover,
      &:focus {
        .videoPlayer__volume-bar-filled {
          background: @color-orange;
        }
      }
    }
  }

  &__navPlaylist {
    display: flex;
    align-items: center;

    &BtnRight,
    &BtnLeft {
      padding: 2px;
      color: @color-white;
      visibility: visible;
      border: 0;
      background: transparent;

      .icon {
        height: 1.5em;
        width: 1.5em;
      }

      @media (min-width: @screen-sm-min) {
        .icon {
          height: 2em;
          width: 2em;
        }
      }

      &:disabled {
        visibility: hidden;
      }

      &:hover,
      &:focus {
        color: @color-orange;
      }
    }

    &BtnRight {
      margin-left: 8px;
    }

    &BtnLeft {
      margin-right: 8px;
    }
  }

  &__playBtn {
    width: 1.5em;
    height: 1.5em;
    position: relative;
    margin-left: 2px;
    transform: rotate(-90deg) scale(0.8);
    transition: clip-path 0.3s ease-in 0.1s, shape-inside 0.3s ease-in 0.1s,
      transform 0.8s cubic-bezier(0.85, -0.25, 0.25, 1.425);
    .controlButton();

    @media (min-width: @screen-sm-min) {
      width: 2em;
      height: 2em;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      background: @color-white;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transition: inherit;
    }

    &::before {
      clip-path: polygon(0 10%, 100% 10%, 100% 40%, 0 40%);
    }

    &::after {
      clip-path: polygon(0 60%, 100% 60%, 100% 90%, 0 90%);
    }

    .paused & {
      transform: rotate(0deg);

      &::before {
        clip-path: polygon(10% 0, 90% 51%, 90% 51%, 10% 51%);
      }

      &::after {
        clip-path: polygon(10% 49.5%, 80% 49.5%, 90% 49.5%, 10% 100%);
      }
    }

    &:hover,
    &:focus {
      &::before,
      &::after {
        background: @color-orange;
      }
    }
  }

  &__fullscreen {
    display: flex;
    justify-content: center;
    .controlButton();

    &:hover,
    &:focus {
      .icon {
        color: @color-orange;
      }
    }

    .icon {
      width: 1.8em;
      height: 1.4em;
      color: @color-white;

      @media (min-width: @screen-sm-min) {
        width: 2.6em;
        height: 2.2em;
      }
    }
  }
}
