// See JavaScript usage of tether-drop

.drop-element,
.drop-element::after,
.drop-element::before,
.drop-element *,
.drop-element *::after,
.drop-element *::before {
  box-sizing: border-box;
}

.drop-element {
  position: absolute;
  display: none;
  z-index: 11;
}

.drop-element.drop-open {
  display: block;
}

.drop-element.drop-theme-basic {
  max-width: 100%;
  max-height: 100%;
}

.drop-element.drop-theme-basic .drop-content {
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  font-family: inherit;
  background: #eee;
  color: #444;
  padding: 1em;
  font-size: 1.1em;
  line-height: 1.5em;
}
