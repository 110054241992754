@import (reference) '../../styles/_library.less';

// if you see !important it is because our style is overrided by smile strate :(
.mediaPlaceholder {
  @margin: 16px;

  display: flex !important;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  padding: 20px;
  text-align: center;
  background-color: @color-gray-darker;
  color: @color-white;

  span {
    display: block;
  }

  &__controls {
    .ff-button {
      margin: @margin (@margin / 2) 0;
    }
  }

  // sorry but strate smile wbmkt always override our style
  &__text,
  &__link,
  &__btn {
    font: normal 13px @typoFnac !important;
    color: @color-white !important;
    text-transform: none !important;
  }

  &__link:hover {
    color: @color-black !important;
  }

  // Use same style for loading and error, because we don't anything else to show yet when the CMP (Consent Management Platform) script is not loaded
  // FIXME: show an error message
  &.loading,
  &.error {
    position: relative;
    visibility: hidden; // hide the content

    &::before {
      content: '';
      position: absolute;
      visibility: visible; // show only this loader
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      background: @color-gray-darker url('../../images/loader.svg') center / 76px 76px no-repeat;
    }
  }

  // specific style for smile strate
  .wbmkt-3videos-videoBox & {
    position: absolute;
    width: 100%;
  }
}
