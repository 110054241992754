// countdown bar
.f-progressBar {
  background: @color-silver-lighter;
  border-radius: 2px;
  height: 4px;
  font-weight: normal;
  position: relative;
  overflow: hidden;

  &-orange {
    background: @color-orange;
    border-radius: 2px;
    height: 4px;
    left: 0;
    position: absolute;
    overflow: hidden;
    transition: width 1s cubic-bezier(0.4, 0, 1, 1);

    &::before {
      animation: light-slide 2s 1s 1;
      background: linear-gradient(90deg, @color-orange, hsla(0, 0%, 100%, 0.6), @color-orange);
      content: '';
      height: 100%;
      left: -10%;
      position: absolute;
      width: 10%;
    }
  }
}

@keyframes light-slide {
  0% {
    left: -10%;
  }
  100% {
    left: 100%;
  }
}
