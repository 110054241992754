// CLASSIC TAB LIST ---------------------------------------- //
.tabList {
  clear: both;
  margin: 0;
  padding: 0;

  li {
    .typoFnacLight();
    display: inline-block;
    margin-right: unit((10 / 3), em);
    line-height: 55px;
    border-bottom: 5px solid transparent;
    cursor: pointer;
    color: @color-gray;
    font-size: unit((15px / 12px), em);

    &:hover,
    &:focus,
    &.selected {
      border-bottom: 5px solid @color-dark;
      color: @color-dark;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .selected {
    cursor: inherit;
  }

  @media (max-width: 1280px) {
    padding: 10px 0 0;
    /*height: 50px;*/
    li {
      line-height: 46px;
      max-width: 31%;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 3%;
    }
  }
}

@media (max-width: 1280px) {
  .ma-Main {
    .tabList {
      li {
        padding: 5px 8px;
        border-radius: 4px;
        white-space: normal;
        margin: 0 10px 10px 0;
        border: 1px solid;
        line-height: 35px;
        width: auto;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.Strate-top {
  .comboList {
    li {
      display: none;

      &:nth-child(-n + 5) {
        display: inline-block;
      }
    }
  }

  .tabList {
    li {
      display: none;

      &:nth-child(-n + 3) {
        display: inline-block;
      }
    }

    @media (max-width: 1280px) {
      li:nth-child(-n + 3) {
        display: inline-block;
      }
    }

    @media (max-width: 1280px) {
      .tabList {
        position: absolute;
      }
    }
  }
}
