@import (reference) '../../../../../../styles/_library.less';
@mackids: #2c909e;
@macboxset: #dd2a5c;

//
// mac General Style
//
.mac {
  overflow: visible;
  text-align: center;
  padding: 50px 0;
  position: relative;
  border: 0;

  .centerVisual {
    position: relative;
    min-height: 235px;
    width: 100%;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &-select {
    .f-selectList();
    font-weight: 700;
    font-size: 18px;
    max-width: 200px;
    text-transform: lowercase;
    padding: 10px 31px 10px 13px;
    background: colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @color-red2) right 10px top 50% / 12px auto
      no-repeat;
    option {
      background: @color-white;
      color: @color-black;
      &:checked {
        font-weight: 700;
      }
    }
  }
}

// mac Kids
.mac--kids {
  background: url('../../../../../Images/Mac/mac-kids.png');
  overflow: visible;
  text-align: center;

  form {
    width: 50%;
    margin: 0 auto;

    @media (max-width: 1700px) {
      width: 80%;
    }

    @media (max-width: 1200px) {
      width: 100%;
    }
  }
}

// mac Boxset
.mac--boxset {
  padding-top: 0;
  padding-bottom: 0;
  background: @macboxset;
  color: @color-white;
  font-weight: 100;
}

// mac Xmas
.mac--christmas {
  .Giftengine-Xmas-img {
    max-width: 100%;
    height: auto;
  }
}

// mac Title ------------------------------------------------- /
.mac-title {
  .typoFnacLight();
  color: @color-dark;
  text-transform: uppercase;
  font-size: 32px;
  margin: 60px 0 30px 0;

  em {
    font-weight: bold;
    font-style: normal;
  }
}

// mac Title Kids
.mac-title--kids {
  margin: 0 0 40px 0;
  span {
    position: relative;
  }
  em {
    font-family: 'Gloria Hallelujah', sans-serif;
    text-transform: none;
    transform: translateY(5px);
    display: inline-block;
    font-size: 40px;
    font-style: italic;
    letter-spacing: 1px;
    font-weight: 300;
  }
}

// mac Title Christmas
.mac-title--christmas {
  color: @color-dark;
}

// mac Title Boxset
.mac-title--boxset {
  color: @color-white;
}

// mac Btn ------------------------------------------------- /
.mac-btn {
  margin-top: 30px;
  font-size: 14px;
  padding: 10px 30px;
}

.mac-btnWrap {
  margin-top: 50px;
  padding: 50px 0;
  background: @color-white;

  .mac-btn {
    margin-top: 0;
  }
}

// mac Btn Kids
.mac-btn--kids {
  background: @color-white url('../../../../../Images/Mac/mac-kids-btn.png') no-repeat 12px 7px;
  color: @mackids;
  margin-top: 20px;
  padding-left: 50px;

  &:hover,
  &:focus {
    background: @mackids url('../../../../../Images/Mac/mac-kids-btn.png') no-repeat 12px -21px;
    color: @color-white;
  }
}

// mac Btn Boxset
.mac-btn--boxset {
  background: @macboxset;

  &:hover {
    background: none;
    color: @macboxset;
    border: 1px solid @macboxset;
  }
}

// Item combo mac ------------------------------------------ /
.itemCombo--mac {
  display: inline-block;
  font-size: 1.5em;
  .clearfix();
  margin-bottom: 10px;
  line-height: 2.5;

  .label {
    float: left;
    margin: 0 10px;
  }

  .combo {
    position: relative !important;
    float: left;
  }
}

// Item combo Christmas (Christmas Red)
//
// Saddly it's about children
//
.itemCombo--macChristmas {
  .mac-select {
    background: @color-white colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @color-noel) right 10px top 50% /
      12px auto no-repeat;
    border-color: @color-noel;
    color: @color-noel;

    &:hover {
      background: @color-noel colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @color-white) right 10px top 50% /
        12px auto no-repeat;
      color: @color-white;
    }
  }
}

// Item combo Kids
.itemCombo--kids {
  color: @color-white;

  .mac-select {
    background: @mackids colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @color-white) right 10px top 50% / 12px
      auto no-repeat;
    border-color: @mackids;
    color: @color-white;
    &:hover {
      background: @color-white colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @mackids) right 10px top 50% /
        12px auto no-repeat;
      color: @mackids;
    }
  }
}

// Item combo Boxset
.itemCombo--boxset {
  .mac-select {
    background: @macboxset colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @color-white) right 10px top 50% /
      12px auto no-repeat;
    border-color: @color-white;
    color: @color-white;

    &:hover {
      background: @color-white colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @macboxset) right 10px top 50% /
        12px auto no-repeat;
      color: @macboxset;
    }
  }
}

//--------------------------------------------------------- /
//------Special events redecorations------------------------/
//--------------------------------------------------------- /

// Christmas Kids
.mac--kids.xmasTime {
  background: url('../../../../../Images/Mac/kids-christmas/mac-kids-christmas.png');

  & form {
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 117px;
      height: 105px;
      background-image: url('../../../../../Images/Mac/kids-christmas/green-presents.png');
      position: absolute;
      bottom: -50px;
      left: 70%;

      @media (max-width: 560px) {
        display: none;
      }
    }
    //.mac--kids.xmasTime form::after
  }
  //.mac--kids.xmasTime form
  & .mac-title--kids {
    position: relative;

    & span::after {
      content: '';
      display: block;
      width: 94px;
      height: 73px;
      background-repeat: no-repeat;
      background-position: left top;
      position: absolute;
      bottom: -12px;
      right: 300px;

      @media (max-width: 560px) {
        display: none;
      }
    }
    //.mac--kids.xmasTime .mac-title--kids span::after
  }
  //.mac--kids.xmasTime .mac-title--kids
  & .itemCombo--kids .mac-select {
    background: @color-white colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @color-red2) right 10px top 50% /
      12px auto no-repeat;
    border-color: @color-white;
    color: @color-noel;
  }
  //.mac--kids.xmasTime .itemCombo--kids .mac-select
  & .mac-btn--kids {
    background: @color-green-soft url('../../../../../Images/Mac/kids-christmas/mac-kids-btn-christmas.png') no-repeat
      12px 7px;
    color: @color-white;

    &:hover,
    &:focus {
      background: @color-white url('../../../../../Images/Mac/kids-christmas/mac-kids-btn-christmas.png') no-repeat 12px -21px;
      color: @color-green-soft;
    }
  }
} //.mac--kids.xmasTime
