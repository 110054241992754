// #topBar-popin {
//   position: relative;
//   display: none;
//   width: 750px;
//   margin: 0 auto;
//   height: auto;
//   background: @color-white;
// }

// Popin Carte adhérent ---------------------------------------- //
#AdhCardInfo {
  padding: 0;
  width: 1000px;

  .mfp-close {
    color: @color-white;

    @media (min-width: @screen-md-min) {
      margin-top: -44px;
      width: auto;
    }
  }
}

.AdhCardInfo-title,
.AdhCardInfo-content {
  overflow: hidden;
  font-size: unit((15px / 12px), em);

  b {
    font-weight: bold;
  }
}

.AdhCardInfo-title {
  background: @color-dark;
  display: block;
  padding: 30px;
  width: 100%;
  color: @color-white;
  font-size: 1.4em;
  text-transform: uppercase;

  small {
    text-transform: lowercase;
  }

  @media (min-width: @screen-md-min) {
    display: table-cell;
    width: 25%;
  }
}

.AdhCardInfo-txt {
  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.AdhCardInfo-img {
  @media (min-width: @screen-md-min) {
    margin-bottom: 30px;
  }
}

.AdhCardInfo-content {
  display: block;
  width: 100%;

  @media (min-width: @screen-md-min) {
    display: table-cell;
    width: 75%;
  }
}

.AdhCardInfo-block {
  background: @color-white;
  border: 1px solid @color-silver-darker;
  border-width: 0 1px 1px 0;
  display: table-cell;
  height: 180px;
  float: left;
  color: @color-dark;
  text-align: center;
  text-transform: uppercase;
}

.AdhCardInfo-supText {
  display: block;
  margin-top: -0.2em;
  font-size: 0.2em;
  letter-spacing: normal;
  line-height: 0;
  text-align: center;
}

.AdhCardInfo-icon {
  // Text
  display: block;
  height: 80px;
  font-size: 60px;
  font-style: normal;
  position: relative;
  // Icon
  & > .icon {
    font-size: 80px;
    vertical-align: inherit;
  }
}

/* popin conditions de crédit renouvelable */
.conditionCred {
  font-size: 16px;
  font-weight: 100;

  &-ttl {
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 20px;
  }

  &-sst {
    font-size: 21px;
    font-weight: 500;
    margin: 20px 0 10px;
  }

  &-warning {
    font-size: 26px;
    margin: 40px 0;
  }

  &-mentions {
    font-size: 12px;
  }
}

/* popin membership (ES) */

.mfp-content .ff-modal.popinPubliSocioTC {
  padding: 0;
}
