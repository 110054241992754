@import (reference) '../../styles/_library.less';
@import './expertItem.less';

@only-one-item: 335px;
@only-one-item-mobile: 85%;

.f-expertPosts {
  // homes / nsh / ia
  background: @color-silver-lighter;
  .f-fsi-Container & {
    // NCarousel
    &.f-nCarousel {
      .nCarouselRoundedArrows();
      .nCarouselPositionArrows(10px);
      .f-icon {
        font-size: 12px;
      }
    }
  }

  // noJs style
  &:not(.hasJs) {
    margin: 0 60px;

    .f-fsi-Container & {
      // ia fiche intervenant
      margin: unset;

      @media (max-width: @screen-sm-min) {
        .expertItem {
          min-width: @only-one-item-mobile;
        }
      }

      &:only-child {
        // only one post
        .expertItem {
          max-width: @only-one-item;
        }
      }
    }

    // nsh / homes
    .expertItem {
      @media (min-width: @screen-sm-min) {
        min-width: calc(50% - 10px);
      }
      @media (min-width: @screen-lg-min) {
        min-width: calc((100% / 3) - 20px);
      }
    }
    // ia
    .f-fsi-Container & .expertItem {
      @media (min-width: @screen-sm-min) {
        min-width: calc((100% / 3) - 20px);
      }
      @media (min-width: @screen-lg-min) {
        min-width: calc((100% / 4) - 20px);
      }
    }
  }
}
