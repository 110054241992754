@import (reference) '../../styles/_library.less';
.f-faMpMoreOffers {
  &.f-productSection {
    margin-top: -7px;
  }

  &__price {
    color: @color-marketplace;
    font-weight: bold;
    text-wrap: nowrap;
  }

  &__link {
    line-height: 14px;
    font-size: 11px;
    color: @color-dark;
    &.ff-button-link {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .f-productPageMain__buyBox & {
      margin: 20px 0 0;
    }
    //spec FA mobile/desk
    // .f-productPageMain__buyBox &,
    // .FA .f-productSection & {
    //   width: 100%;
    //   font-size: 13px;
    //   line-height: 15px;
    //   padding-right:24px;
    //   background: data-uri(icon('chevron-right')) no-repeat right top;
    //   background-size: auto 14px;
    // }
  }
}

/* ****  Liste de resultat ****** */
.f-searchResults {
  .f-faMpMoreOffers.f-productSection {
    margin-top: 0;
  }
}
