@import (reference) '../../styles/_library.less';

// TODO: remove .f-header--promobile styles after adding the mobile header on Fnacpro

.Header__search {
  flex-basis: 40%;
  align-self: center;
}

// Temp style for Fnacpro responsive header
.f-header--promobile .Header__search {
  padding: 10px;
}

.Header__search-fields {
  position: relative;
  height: 100%;

  &:not(:focus-within) .Header__search-autocomplete {
    display: none;
  }

  &::before {
    content: none;
    border: 2px solid @color-silver-darker;
    border-radius: 8px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    top: -4px;
    position: absolute;
    z-index: -1;
  }

  &:hover {
    &::before {
      content: '';
    }
  }

  &:focus-within {
    &::before {
      content: '';
      border-color: @color-orange;
    }

    &::after {
      content: '';
      opacity: 0.24;
      border: 4px solid @color-orange;
      border-radius: 12px;
      left: -8px;
      right: -8px;
      bottom: -8px;
      top: -8px;
      position: absolute;
      z-index: -1;
    }
  }
}

.Header__search-input,
.non-legacy .Header__search-input {
  @height: 40px;

  background: @color-silver-lighter;
  border: 0;
  border-radius: 4px;
  padding: 0 88px 0 24px;
  width: 100%;
  color: @color-gray-darker;
  font-style: normal;
  font-size: 15px;
  line-height: @height;
  height: @height;

  &:focus,
  &:focus-visible {
    background: @color-white;
    color: @color-dark;
    outline: 0 !important;
  }

  // Remove IE/Edge input clear x
  &::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }

  // Remove Webkit input clear x
  &::-webkit-search-cancel-button {
    display: none;
  }

  &::placeholder {
    color: @color-gray-darker;
    font-style: italic;
  }
}

.Header__search-autocomplete {
  background: @color-white;
  display: none;
  padding: 10px 0;

  border-width: 0 4px 4px 4px;
  border-style: solid;
  border-color: rgba(@color-gray, 0.24);
  border-radius: 0 0 8px 8px;
  background-clip: padding-box;
  position: absolute;
  width: 100%;
  top: calc(100% + 4px);

  &.active {
    display: block;
  }

  &-item {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    width: 100%;

    &:hover,
    &.hover {
      background: @color-silver-lighter;
    }

    &:active,
    &:focus,
    &.active {
      background: @color-silver-darker;
    }

    b {
      font-weight: bold;
    }
  }

  &-button {
    background: none;
    border: none;
    padding: 12px 16px;
    color: @color-dark;

    &--historic {
      padding: 12px 0 12px 16px;
    }

    &--title {
      width: 100%;
      text-align: left;
      .text-overflow();
    }

    &--close {
      display: flex;

      &:hover {
        .Header__search-autocomplete-icon {
          color: @color-dark;
        }
      }

      .Header__search-autocomplete-icon {
        //width: 10px;
        margin: 0;
      }
    }
  }

  &-icon {
    color: @color-gray;
    margin: 0 8px 0 0;
    font-size: 16px;
  }
}

.Header__search-cross {
  display: flex;
  margin-top: -8px;
  position: absolute;
  right: 12px;
  top: 50%;
  color: @color-gray;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  transition: transform 0.5s;
  background: 0;
  border: 0;
  padding: 0;

  &.on {
    transform: translateX(-44px);
  }
}

.Header__search-submit {
  background: @color-orange colorize(data-uri(icon('search')), @color-dark) center / auto 20px no-repeat;
  right: 0;
  width: 40px;
  top: 50%;
  height: 40px;
  border-radius: 4px;
  border: 0;
  display: block;
  outline: none;
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);

  .focus-visible-dark();
}
