// Refactor me if you can find an event strate
.event {
  background: @color-silver-lighter;
  padding: (100% / 24);

  .Strate-title {
    float: left;
  }

  .eventList {
    float: left;
  }

  .Event-block {
    .thumb {
      margin-right: unit((50px / 12px), em);
    }
  }

  .Event-top {
    margin-bottom: 2.5%;
  }

  .Event-visual {
    height: 140px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: @color-white;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }

  .Event-content {
    background: @color-dark;
    clear: both;
    color: @color-silver-lighter;
    font-size: unit((13px / 12px), em);
    font-weight: bold;
    height: unit((280px / 13px), em);
    padding: 3.33333em 3.33333em 2.91667em;

    .Event-title {
      .typoFnacLight();
      color: @color-orange;
      font-size: unit((13px / 12px), em);
      margin-top: 0;
      float: none;
    }

    .Event-text {
      border-bottom: 1px solid @color-gray-darker;
      min-height: unit((85px / 13px), em);
      padding-bottom: unit((20px / 13px), em);
    }

    .tilePostEvent {
      color: @color-white;
      .typoFnacBold();
      white-space: normal;
    }

    .Event-date {
      .f-icon {
        font-size: 1.46154em;
        margin-right: 0.473684em;
        color: @color-orange;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        width: 11.1538em;
        white-space: normal;
      }
    }
  }
}

// Carousel sizing
.f-eventPosts {
  .f-nCarousel__item {
    @media (min-width: @screen-sm-min) {
      min-width: 50%;
    }
    @media (min-width: @screen-lg-min) {
      min-width: calc(100% / 3);
    }
  }
}
