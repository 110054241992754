@import (reference) '../_library.less';

.f-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  height: 1em; // else it will be 150px
  width: 1em; // else it will be 300px
  flex-shrink: 0; // prevents the icon from being shrinked in a flex element

  &--topSeller {
    color: @color-orange;
  }
}

// Fallback to hide old font icons
[class~='icon_i_']::before {
  content: none;
}
