// Hides every external script images, loaded at <body> root.
body > img {
  // position: fixed;
  // left: -9999px; Reflow performance issue
  width: 0;
  height: 0;
  overflow: hidden;
  display: block;
}

// APPNEXUS
img[src^="https://secure.adnxs.com"]
{
  display: none !important;
}
