@import (reference) '../../../../../styles/_library.less';

// ----------------------------------------
// Promo subheader links
// ----------------------------------------

.promo {
  position: absolute;
  height: 40px;
  overflow: hidden;
  width: 100%;
  background-color: @color-white;
  font-size: 1.1em;

  .home-page & {
    border-left: 0;
    left: 0;
  }

  &-List {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    &Item {
      flex: 1 1 auto;
      height: 40px;
      text-align: center;
      border-top: 1px solid @color-silver-darker;
      // border-width: 1px 0 0 1px;
      cursor: pointer;

      // box-shadow: 0 0 transparent inset;
      will-change: box-shadow;
      transition: box-shadow 0.2s;

      &:hover,
      &:focus {
        box-shadow: 0 -4px @color-dark inset;
      }

      &--flash {
        &:hover,
        &:focus {
          box-shadow: 0 -4px @color-red inset;
        }
      }

      // Too much items, ignore them
      &:nth-child(1n + 6) {
        display: none;
      }

      &Img {
        height: 20px;
        max-height: 20px;
        margin: 0 5px 0 0;
        width: auto;

        @media (max-width: 1280px) {
          height: 15px;
          max-height: 15px;
        }
      }

      &Label {
        color: @color-dark-light !important;
        font-weight: bold !important;
        text-transform: uppercase;
        font-size: 12px;
        text-overflow: ellipsis;
        padding-top: 0.3em; // for text optical vertical alignment

        @media (max-width: 1280px) {
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &Link {
        display: flex;
        flex: 1 1 100%;
        align-items: center;
        justify-content: center;
        height: 100%;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}
