.f-header__shipping-header-title {
  padding: 0 0 20px;
  line-height: 16px;
  text-align: center;
  color: @color-dark;
  font-size: 14px;
}

.f-header__shipping-content {
  background-color: @color-silver-lighter;
  padding: 24px 24px 17px;
  color: @color-dark;
  font-size: 14px;
  border-radius: @base-radius;

  &-name {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.f-header__shipping-info {
  display: flex;
  margin-top: 16px;
  color: @color-dark;
  padding: 0 8px;

  &-icon {
    margin-right: 8px;
    font-size: 16px;
  }
}

.f-header__shipping-bottom-link,
.non-legacy .f-header__shipping-bottom-link {
  position: fixed;
  width: calc(100% - 32px);
  bottom: 5%;
  font-size: 14px;
  text-align: center;
  color: @color-dark;
  text-decoration: underline;
}
