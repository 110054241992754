@import (reference) '../../styles/_library.less';

.f-koboRedirect {
  width: 600px;
  max-width: 100%;
  // min-height: 270px;
  // border: 10px solid @color-black;
  // background: @color-white;
  // margin: 0 auto;
  // position: relative;

  // @media (max-width: @screen-xs-min) {
  //   min-height: 340px;
  // }

  // .Popin-content,
  // .Popin-action {
  //   text-align: left;
  // }

  // .Popin-content,
  // h2 {
  //   padding: 25px;
  // }

  &__title {
    // background: @color-grey;
    color: @color-dark;
    // .typoFnacLight();
    margin: 0;
    font-size: 21px;
    // text-transform: uppercase;
    font-weight: bold;
    line-height: 27px;
    text-align: center;
  }

  &__text {
    margin: 24px 0;
    text-align: justify;
  }

  &__action {
    // position: absolute;
    // right: 30px;
    text-align: center;
  }

  &__logo {
    height: 13px;
    width: 62px;
    margin-left: 3px;
  }

  // .mfp-close {
  //   top: 15px;
  //   right: 15px;
  //   color: @color-black;
  // }

  // .ff-button-label {
  //   width: 120px;

  //   [lang='es-ES'] & {
  //     width: 150px;
  //   }
  // }
}

// .koboPartner-button {
//   height: 40px;
//   padding: 10px;
//   cursor: pointer;
//   font-weight: 700;
//   outline: 0;
//   text-decoration: none;
//   border: 1px solid @color-black;
//   display: inline-block;
//   font-size: 13px;
//   text-align: right;
//   color: @color-black;

//   &:hover,
//   &:focus {
//     text-decoration: none;
//   }
// }

// .koboPartner-logo {
//   height: 18px;
//   width: 100px;
// }
