@import (reference) '../../../styles/_library.less';

.f-kamino-native {
  padding: 25px 30px;
  & when (@ismobile) {
    padding: 0;
  }
  border-top: 1px solid @color-silver-darker;
  position: relative;

  .thumbnail-greenLabel {
    position: absolute;
    top: unset;
    bottom: 16px;
    right: unset;
    left: 16px;
    width: 40px;

    & when (@ismobile) {
      bottom: 8px;
      right: 8px;
      left: unset;
      width: 34px;
    }
  }

  /* Sponsored */
  .f-kamino {
    &-sponsored {
      position: absolute;
      right: 30px;
      bottom: 0;
      background: @color-white;
      display: flex;
      justify-content: flex-end;
      color: @color-gray;
      align-items: center;
      border: 0;
      align-self: flex-end;

      & when (@ismobile) {
        font-size: 12px;
        bottom: 10px;
      }

      &--icon {
        font-size: 18px;
        & when (@ismobile) {
          font-size: 12px;
        }
        margin-left: 5px;
      }
    }
  }
}
