// Best sales strate
//
// .bestSales : best sales main container (wrap the components)
// .bestSales-title : best sales main title (used as a trigger for the js)
//

// Trend item content
.Trend {
  &-content {
    padding: 30px;
    &Wrapper {
      .clearfix();
      display: table;
      width: 100%;
    }
  }

  // 1 LEFT BLOCK //
  &-left {
    display: table-cell;
    width: 40%;
    background: @color-gray-darker;
    .Trend-title {
      padding: 20px;
      margin: 0;
      &.light{
        color: @color-white;
      }
      &.Dark{
        color: @color-dark;
      }
    }
    .Trend-leftTitleLink {
      &:hover {
        text-decoration: none;
      }
    }
  }

  // TWO RIGHT BLOCKS //
  &-right {
    display: table-cell;
    width: 60%;
    background: @color-white;
    border-width: 1px 1px 1px 0;
    border-color: @color-gray-darker;
    border-style: solid;
    .clearfix();
    &Item {
      width: 50%;
      float: left;
      padding: 30px;
      &:first-child {
        border-right: 1px solid @color-gray-darker;
      }
      &:last-child {
        border-left: 1px solid @color-gray-darker;
        margin-left: -1px;
      }
      .Community-title {
        text-transform: uppercase;
        color: @color-white;
        font-weight: 700;
      }
      //.Community-text {
      //
      //}
      .Community-link {
        display: inline-block;
        padding: 5px 10px;
        border: 1px solid @color-white;
        border-radius: 5px;
      }
      .Trend-item-category {
        h3, a {
          text-decoration: none;
          color: @color-gray-darker;
        }
      }
    }
  }
}
