.stimuliOPC {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;

  &-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
  }

  // TODO : OLD STYLE ???
  &.promoGold {
    background: @color-gold;
  }
  // TODO : OLD STYLE ???
  .dispeblock {
    margin-left: 5px;
    margin-top: 5px;
  }

  &-color {
    text-transform: lowercase;
  }

  // Desktop from merge: usefull?
  @media (min-width: @screen-sm-min) {
    width: auto;

    .compare-table & {
      display: none;
    }

    // FnacShop configurator page
    .layoutLight & {
      display: none;
    }
  }
}

// OPC FLYER (THUMBNAIL & LIST) ------------------------------
.stimuliOPC-flyer {
  .typoFnacBold();

  img {
    height: 100%;
    max-height: 15px;
    margin-right: 4px;
    //vertical-align: baseline;
  }

  background: @color-silver-lighter;
  color: @color-dark;
  //display: inline-block;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  // Desktop from merge: usefull?
  @media (min-width: @screen-sm-min) {
    height: auto;
    line-height: 22px;
    padding: 0 5px;
  }

  &:empty {
    display: none;
  }

  &--RefundOfferPartner,
  &--Undefined {
    background: @color-white;
    color: @color-dark;
  }

  &--BlackFriday {
    background: @color-black;
    color: @color-white;
  }

  &--Red, /* OLD CSS CLASS */
  &--Other, /* OLD CSS CLASS */
  &--Sale,
  &--OutSale,
  &--FlashSale,
  &--GoodDeal,
  &--Discount,
  &--CostPrice,
  &--XForX,
  &--XEqualX,
  &--MultiBuy1,
  &--MultiBuy2,
  &--MultiBuy3,
  &--red,
  &--Exclusivity {
    background: @color-red;
    color: @color-white;
  }

  // OLD CSS STYLE
  &--Indigo {
    background: @color-violet;
    color: @color-white;
  }

  &--Gold, /* OLD CSS CLASS */
  &--Standard, /* OLD CSS CLASS */
  &--ReducedPrice, /* OLD CSS CLASS */
  &--AdhPrice,
  &--Adh5Percent,
  &--AdhDiscount,
  &--AdhPrivateSell,
  &--Adh10Percent,
  &--AdhXFree,
  &--XOnX,
  &--XFreeForX {
    background: @color-yellow;
    color: @color-dark;

    //Override specific color for FR
    &:lang(fr-FR) {
      background: @color-orange;
    }
  }

  &--Gamer {
    background: @color-violet;
    color: @color-white;
  }

  &--ExcluFnacPro {
    background: @color-blueB2B;
    color: @color-white;
  }

  &--RefundOffer,
  &--Delivery,
  &--Gift {
    background: @color-white;
    color: @color-red;
  }

  &--5Percent {
    background: @color-white;
    color: @color-green-dark;
    width: 100%;
    line-height: 14px;

    // Desktop from merge: usefull?
    @media (min-width: @screen-sm-min) {
      width: auto;
      line-height: inherit;
    }
  }

  &--New {
    background: @color-turquoise;
    color: @color-white;
  }

  .stimuliOPC-color {
    color: inherit;
  }

  .stimuliOPC-logo {
    display: none;
  }

  .stimuliOPC-logoText {
    display: inline-block;
  }

  & + .stimuliOPC-flyer {
    margin-left: 20px;
    position: relative;

    &::before {
      content: '/';
      position: absolute;
      left: -12px;
      color: @color-gray;
      font-size: 18px;
      font-weight: 300;
    }

    &.stimuliOPC-flyer {
      &--Undefined,
      &--RefundOffer,
      &--Delivery,
      &--Gift,
      &--5Percent {
        padding: 0;
      }
    }
  }

  /* Fix Stimulu opc flyer - mini Fa - Deezer  */
  // .f-productSpecialOffer-logo--deezer {
  //   display: inline;
  //   top: -3px;
  // }
}

.stimuliOPC-flyer-price {
  .typoFnacBold();
  color: @color-red;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  margin-left: 10px;
  padding: 0;
}

// OPC BOX  ----------------------------------------------- /
.stimuliOPC-box {
  border-top: 1px solid @color-silver-lighter;

  a {
    .typoFnacBold();
    color: @color-dark;
    display: block;
    padding: 20px 15px 15px 70px;
    position: relative;
    min-height: 60px;

    &:hover {
      background-color: rgba(244, 244, 244, 0.5); // = @color-grey-light alpha 50%
      text-decoration: none;
      border-left: 3px solid @color-black;
      padding-left: 67px;

      img {
        left: 7px;
      }
    }

    img {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--noImage {
    a {
      padding-left: 15px;

      &:hover {
        padding-left: 12px;
      }
    }
  }

  .stimuliOPC-logo {
    img {
      position: static;
      float: none;
    }
  }
}

// OPC LINK  ------------------------------------------------- /
.stimuliOPC-link {
  display: block;
  font-size: 13px;
  padding: 0;
  overflow: hidden;

  a {
    color: @color-dark;
  }

  .stimuliOPC-color {
    .typoFnacBold();
  }

  .stimuliOPC-logo {
    display: none;
  }

  .stimuliOPC-logoText {
    .typoFnacBold();
    display: inline-block;
  }

  &:first-of-type .stimuliOPC-link-plus {
    visibility: hidden;
  }

  &:only-child .stimuliOPC-link-plus {
    display: none;
  }

  &--Adh10Percent,
  &--5Percent,
  &--RefundOffer {
    border-top: 1px solid @color-silver-lighter;
    clear: both;
    position: relative;
    width: auto;
    margin-left: -15px;
    margin-right: -15px;

    // Desktop from merge: usefull?
    @media (min-width: @screen-sm-min) {
      margin-left: auto;
      margin-right: auto;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-left: 4px solid @color-orange;
    }

    &:last-child {
      margin-bottom: -15px;
    }

    &:hover {
      background-color: rgba(244, 244, 244, 0.5); // = @color-grey-light * 2 alpha 50%
    }

    .stimuliOPC-link-title {
      display: inline-block;
      vertical-align: top;
    }

    .stimuliOPC-link-subtitle {
      display: block;
      font-family: 'Gloria Hallelujah', sans-serif;
    }

    a {
      // dans le cas de deux liens
      color: #9d9d9d;
      clear: both;
      display: block;
      padding: 15px 15px 15px 11px;

      &::after {
        content: ' >';
      }

      &:first-child {
        // dans le cas normal d'un seul lien
        color: @color-dark;
        float: left;
        right: auto;
        text-decoration: none;
        text-align: left;

        &::after {
          content: none;
        }
      }

      &:nth-child(2) {
        // dans le cas de deux liens
        display: inline-block; // pour le margin negative
        margin-top: -35px;
        width: 100%; // pour le margin negative
        text-align: right;
      }

      &:only-child {
        float: none;
      }
    }

    .stimuliOPC-link-plus {
      display: none;
    }
  }

  &--5Percent::before {
    // 5% Livres
    border-color: @color-green-dark;
  }

  &--RefundOffer::before {
    // ODR
    border-left-color: @color-red;
  }

  .stimuliOPC-logo {
    display: inline-block;
  }

  .stimuliOPC-logoText {
    display: none;
  }
}

// OPC LABEL --------------------------------------------------/
.stimuliOPC-label {
  display: inline-block;
  line-height: 1.2;

  &::first-letter {
    text-transform: uppercase;
  }

  &--standard {
    padding: 0 3px;
  }

  &--standard::first-letter {
    text-transform: uppercase;
  }

  &--Undefined {
    background: @color-white;
    color: @color-dark;
    padding: 0;
  }

  &--Reduction5PourCentOuPrixVert, /* OLD CSS CLASS */
  &--Solde, /* OLD CSS CLASS */
  &--Other, /* OLD CSS CLASS */
  &--AffaireDeFnac, /* OLD CSS CLASS */
  &--OffreSpecialeInternet, /* OLD CSS CLASS */
  &--Sale,
  &--OutSale,
  &--FlashSale,
  &--GoodDeal,
  &--Discount,
  &--CostPrice,
  &--XForX,
  &--XEqualX,
  &--MultiBuy1,
  &--MultiBuy2,
  &--MultiBuy3,
  &--Exclusivity,
  &--red {
    background: @color-red;
    color: @color-white;
    padding: 0 4px;
  }

  &--BlackFriday {
    background: @color-black;
    color: @color-white;
    padding: 0 4px;
  }

  &--OffreAdherent, /* OLD CSS CLASS */
  &--Standard, /* OLD CSS CLASS */
  &--PrivateSale, /* OLD CSS CLASS */
  &--ReducedPrice, /* OLD CSS CLASS */
  &--ReducedPriceDeferred, /* OLD CSS CLASS */
  &--ReducedPriceDeferredMember, /* OLD CSS CLASS */
  &--RemiseAdherent, /* OLD CSS CLASS */
  &--AdhPrice,
  &--Adh5Percent,
  &--AdhDiscount,
  &--AdhPrivateSell,
  &--Adh10Percent,
  &--AdhXFree,
  &--XOnX,
  &--XFreeForX {
    background: @color-yellow;
    color: @color-dark;
    padding: 0 8px;

    //Override specific color for FR
    &:lang(fr-FR) {
      background: @color-orange;
    }
  }

  &--orange {
    background: @color-orange;
    color: @color-dark;
    padding: 0 8px;
  }

  &--yellow {
    background: @color-yellow;
    color: @color-dark;
    padding: 0 8px;
  }

  &--OffreGamer, /* OLD CSS CLASS */
  &--Gamer {
    background: @color-violet;
    color: @color-white;
    padding: 0 4px;
  }

  &--RefundOffer,
  &--Delivery,
  &--Gift {
    background: @color-white;
    color: @color-red;
    padding: 0;
  }

  &--5Percent {
    background: @color-white;
    color: @color-green-dark;
    padding: 0;
  }

  &--New {
    background: @color-turquoise;
    color: @color-white;
    padding: 0 4px;
  }

  &--green {
    background: @color-green-dark;
    color: @color-white;
    padding: 0 4px;
  }

  // Desktop from merge: usefull?
  @media (min-width: @screen-sm-min) {
    text-transform: uppercase;
    line-height: 22px;
    padding: 0 6px;

    &--Standard {
      padding: 0 2px;
    }
  }

  // .f-faPriceBox__labelOpc & {
  //   display: block;
  //   text-overflow: ellipsis;
  //   overflow: hidden;
  // }
}

// Merge from mobile
// OPC LABEL CAPSULE
// TO DO : this replace all the old stimuli
.stimuliOPCLabel {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: bold;
  vertical-align: middle;
  padding: 0 8px;

  &::first-letter {
    text-transform: uppercase;
  }

  &__capsule {
    border-radius: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-transform: lowercase;
    line-height: 1.2;
  }

  &--default {
    background: @color-red;
    color: @color-white;
  }

  &--specialDiscount {
    background: @color-yellow;
    color: @color-dark;

    //Override specific color for FR
    &:lang(fr-FR) {
      background: @color-orange;
    }
  }

  &--gamer {
    background: @color-violet;
    color: @color-white;
  }
}

/* exception promoMP */
// .Offer-price .stimuliOPC-label--Delivery {
//   padding: 0 10px;
//   background: transparent;
//   font-weight: 700;
// }

// OPC TAB ----------------------------------------------------/
.stimuliOPC-tab {
  .stimuliOPC-logo {
    display: inline-block;

    img {
      vertical-align: baseline;
    }
  }

  .stimuliOPC-logoText {
    display: none;
  }
}

// OPC -- CSS PATCH FOR OLD CSS CLASSES -----------------------/
/* thumbnail is for old miniFA, itemosaic if for xtra old miniFA */
.thumbnail,
.itemosaic {
  .stimuliOPC .HL.hlJaun {
    .typoFnacBold();
    background: @color-yellow;
    color: @color-dark;
    display: inline-block;
    font-size: 12px !important;
    /*height: 24px;
    line-height: 24px;*/
    margin: 0 !important;
    line-height: 22px;
    padding: 0 5px;
    text-align: center;
    text-transform: uppercase;
  }
}

.ProductPriceBox-item .stimuliOPC.HL.promoOffreAdherent {
  text-transform: uppercase;

  // Desktop from merge: usefull?
  @media (min-width: @screen-sm-min) {
    line-height: 22px;
  }
}

// OPC -- LISTE RESULTATS DE RECHERCHE -----------------------/
.resultList .Article-item {
  .stimuliOPC-flyer {
    &--RefundOffer {
      background: @color-red;
      color: @color-white;
      padding: 0 10px;

      // Desktop from merge: usefull?
      @media (min-width: @screen-sm-min) {
        padding: 0 5px;
      }
    }

    &--RefundOfferPartner > b {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &Action {
    .stimuliOPC {
      position: initial;

      &-flyer {
        &--RefundOffer {
          background: none;
          color: @color-dark;
          text-transform: initial;
          padding: 0;

          &::before {
            color: @color-dark;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }

      &-color {
        color: @color-red;
      }

      &-logo {
        display: inline-block;
      }
    }
  }

  // .f-productSpecialOffer-logo--deezer {
  //   max-height: 50%;
  // }
}

// Desktop from merge: usefull?
@media (min-width: @screen-sm-min) {
  // OPC MP
  .ProductPriceBox-item .ProductPriceBox-stimulusOpcMP {
    padding: 10px;
    border-left: 5px solid @color-red;
    margin-left: -15px;
    color: @color-black;

    .stimuliOPC-label {
      color: @color-black;
      background: transparent;
      padding: 0;

      &.stimuliOPC-label--BlackFriday {
        display: block;
        color: @color-black;
        font-weight: bold;
      }

      &.stimuliOPC-label--AdhPrice {
        display: block;
        color: @color-yellow;
        font-weight: bold;

        //Override specific color for FR
        &:lang(fr-FR) {
          color: @color-orange;
        }
      }
    }

    a:focus {
      outline: 0;
    }
  }

  .stimuliOPC-ContentPopin {
    width: 300px;
    border: 1px solid @color-gray-darker;
    background: @color-white;
    margin: 0 auto;
    padding: 20px 40px 20px 20px;
    position: relative;

    .mfp-close {
      position: absolute;
      z-index: 999;
      font-size: 50px;
    }
  }

  .ProductPriceBox-stimulusOpcMPDeclencheurContainer {
    cursor: pointer;
    border-left: 5px solid transparent;
    padding: 15px 15px 15px 11px;
    background: @color-white;
    display: table;
    width: 100%;
    color: @color-black;
    .clearfix();

    .icon::before {
      margin-right: 10px;
      font-size: 20px;
    }

    &.Delivery {
      font-size: 1em;
      margin-left: 0;
      color: @color-black;
    }

    &.AdhPrice {
      &:hover {
        border-left: 5px solid @color-gold;

        .icon::before {
          color: @color-gold;
        }
      }
    }

    &:hover {
      border-left: 5px solid @color-red;

      .icon::before {
        color: @color-red;
      }
    }

    .icon {
      display: table-cell;
      vertical-align: middle;
      font-size: 30px;
    }

    .stimuliOPC-Container {
      display: table-cell;
      vertical-align: middle;
      width: 90%;

      &Title {
        font-weight: 700;
      }

      &Text {
        font-size: 11px;
      }
    }
  }
}

// This must be merged with common OPC
.f-faPriceBox {
  &__stimuliOpc {
    font-size: 13px;
    font-weight: bold;
    vertical-align: top;
    margin-bottom: 4px;

    &Label {
      display: inline-block;
      font-size: 1.3rem;
      font-weight: bold;
      text-transform: lowercase;
      border-radius: 10px;
      overflow: hidden;
      //white-space: nowrap;
      vertical-align: middle;
      padding: 2px 6px;
      margin-bottom: 4px;
      .f-faPriceBox__priceLine & {
        margin-bottom: unset;
      }

      .f-faPriceBox__radio:not(:checked) ~ * & {
        background-color: @color-gray;
        color: @color-white;

        .stimuliOPC-color {
          color: @color-white;
        }
      }

      &.ebook {
        max-width: 100%;
      }
    }
    &Icon {
      width: 30px;
      height: 10px;
      vertical-align: 0;
    }
  }

  // &__labelOpc {
  //   margin-bottom: 2px;
  //   display: inline-block;
  //   //display: block;
  // }
}

// 3c
// Temporary styles for 3 col product page
.f-productPageMain & {
  .stimuliOPC-label {
    text-transform: lowercase;
    border-radius: 10px;
    line-height: 1.4;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
