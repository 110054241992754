// Layout ---------------------------------------- //
@import 'partners-img.less';

// body {
//   &.search, &.home {
//     position: relative;

//     .Sidebar {
//       position: absolute;
//       bottom: 0;
//       top: 62px;
//       display: none;
//       height: auto;

//       @media (min-width: @screen-sm-plus) {
//         display: block;
//       }
//     }
//   }
// }

// @media(max-width: 500px) {
//   html {
//     overflow-x: scroll;
//   }
// }

// .ScrollWrapper {
//   overflow-y: auto;
//   overflow-x: hidden;
//   -webkit-overflow-scrolling: touch;
//   position: absolute;
//   bottom: 0;
//   top: 0;
//   backface-visibility: hidden;
//   width: 220px;

//   &.home {
//     top: 81px;
//   }
// }

.js-strate--Fnac,
.js-strate--MP {
  display: none;

  &.strate {
    display: none;
  }

  &.isActive {
    &.strate {
      display: block;
    }

    display: block;
  }
}
