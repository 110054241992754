.suggestion {
  background: @color-white;
  padding: 50px 30px;
  color: @color-dark;
}

.suggestion-title {
  text-transform: uppercase;
  margin-right: 150px;
  margin-top: 0;
  font-size: 34px;
  font-weight: 300;
  margin-bottom: 5px;

  @media (max-width: @screen-md-min) {
    font-size: 22px;
  }
}

.suggestion-description {
  margin-bottom: 0;
  margin-right: 150px;
  font-size: 18px;
  font-weight: 300;

  @media (max-width: @screen-md-min) {
    font-size: 14px;
  }
}

.suggestion--product {
  padding: 0;
  background: @color-white;
}

.suggestion-connectionButton {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -20px;
  color: @color-dark;
  padding: 10px 15px;
  border: 1px solid @color-dark;
  border-radius: 3px;
  font-size: unit((15px / 12px), em);

  &:hover {
    text-decoration: none;
    background: @color-dark;
    color: @color-white;
  }
}
