@import (reference) '../../styles/_library.less';

// spec b2b
.f-addBasketQuantity-container {
  padding: 0;
  margin: 1em auto -0.5em auto;
  width: 150px;
  .clearfix();
}

.f-addBasketQuantity-quantity,
.f-addBasketQuantity-decrease,
.f-addBasketQuantity-increase {
  transition: color 0.3s ease;
  border: 0;
  padding: 0;
  position: relative;
  float: left;

  &:hover {
    transition: color 0.3s ease;
    color: @color-dark;
  }
}

.f-addBasketQuantity-quantity {
  text-align: center;
  height: 25px;
  width: 80px;
  border: 1px solid @color-orange;
  font-size: 15px;
  line-height: 20px;
  color: @color-dark;
}

.f-addBasketQuantity-decrease,
.f-addBasketQuantity-increase {
  background: none;
  width: 35px;
  height: 26px;
  font-size: 26px;
  line-height: 26px;
  color: @color-orange;
}

.f-addBasketQuantity-buyBox {
  padding: unit((20px / 12px), em) 0 unit((20px / 12px), em) 0;
}
