.LocalStore {
  &-header-title {
    color: @color-dark;
    font-size: 27px;
    font-weight: 300;
    margin-bottom: 17px;
    text-transform: uppercase;
    line-height: 32px;
    padding: 0 30px;
  }

  &-header-text {
    margin-bottom: 38px;
    font-size: 15px;
    color: @color-gray-lighter;
    line-height: 22px;
    padding: 0 30px;
  }

  &-infoMag {
    padding: 35px 30px 56px;
    background-color: @color-silver-lighter;

    &-title {
      text-transform: uppercase;
      font-size: 27px;
      margin: 0 0 35px;
      color: @color-dark;
      line-height: 32px;
    }

    &-image {
      width: 100%;
      height: 100%;
      min-height: 203px;
      position: absolute;
      background-position: 50% 0;
      background-size: cover;
    }

    &-content {
      display: table;
    }

    &-contactInformation {
      display: table-cell;
      min-width: 350px;
      padding-right: 35px;
      vertical-align: top;
    }

    &-containerImage {
      display: table-cell;
      width: 100%;
      vertical-align: top;
      position: relative;
    }

    &-item {
      margin-bottom: 8px;
      color: @color-gray-lighter;
      list-style: none;
      font-size: 15px;
    }

    &-cta {
      background-color: @color-dark;
      width: 100%;
      margin-top: 32px;
      border: 1px solid transparent;
      transition: all 0.2s;

      &:hover {
        background-color: @color-white;
        color: @color-black;
        border: 1px solid @color-black;
      }
    }

    &-address {
      font-weight: bold;
      margin-bottom: 20px;
      color: @color-dark;
      font-size: 21px;
      line-height: 25px;
    }

    &-list {
      padding-left: 0;
    }
  }

  &-carousel {
    .LocalStore-arrowPrev {
      left: 40px;
      background-color: rgba(@color-dark, 0.55);
      display: none !important;

      &:hover {
        background-color: rgba(@color-dark, 0.95);
      }
    }

    .LocalStore-arrowNext {
      right: 40px;
      background-color: rgba(@color-dark, 0.55);
      display: none !important;

      &:hover {
        background-color: rgba(@color-dark, 0.95);
      }
    }

    &:hover {
      .LocalStore-arrowPrev,
      .LocalStore-arrowNext {
        display: block !important;
      }
    }

    .miniFA {
      width: auto;
    }

    .miniFA__price {
      padding: 0;
    }

    .miniFA__image {
      margin-top: 10px;
    }
  }
}
