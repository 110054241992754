.rateList {
  padding: 0;
  margin: 30px auto;
  border: none;
  width: 170px;
  display: inline-block;
}

.rateList-item {
  padding: 0;
  margin: 0;
  position: relative;
  list-style: none;
  width: 23px;
  float: left;
  padding-top: 110px;
  margin-right: 10px;
  color: @color-orange;
  &:last-child {
    margin-right: 0;
  }
}

.rateList-percentGraph {
  height: 100px;
  width: 25px;
  top: 0;
  position: absolute;
}

.rateList-percent {
  background: @color-orange;
  min-height: 1px;
  margin-top: 2px;
  width: 25px;
  bottom: 0;
  position: absolute;
  left: 0;
}

.rateList-rateCount {
  color: @color-dark;
  margin-top: -18px;
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
}

.rateList-item .icon {
  color: @color-orange;
}
