// CLASSIC HOMES TITLES
//
// You can find this style on every node pages
//

.home-title {
  font-weight: bold;
  text-transform: uppercase;
  padding: 30px;
  overflow: hidden;

  h1 {
    display: inline-block;
    line-height: 42px;
    margin-bottom: 0;
  }
}

.home-title--big {
  font-weight: 300;
  font-size: 27px;
  margin-top: 0;
  margin-right: 20px;
  display: inline-block;
}

.home-title--small {
  font-size: 15px;
  display: inline-block;
}
