@import (reference) '../../styles/_library.less';

.expander {
  @mask-height: 30px;
  @fade-height: 30px;
  @arrow-size: 26px;

  overflow: hidden;
  position: relative;
  will-change: max-height;
  transition: max-height 0.15s ease-out;

  &__mask {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    // background: @color-white;
    min-height: @mask-height;
    text-align: center;
    background: currentColor;
    color: @color-white;

    .expander--dark & {
      color: @color-dark;
    }

    .expander--silver & {
      color: @color-silver-lighter;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -@fade-height;
      height: @fade-height;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, currentColor 100%);

      // .expander--dark & {
      //   background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, currentColor 100%);
      // }
    }
  }

  &__button {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &.nostyle {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &--hasIcon {
    .expander__button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: @mask-height;
      color: @color-gray;

      .f-icon {
        width: @arrow-size;
        height: @arrow-size;
      }
    }

    &.collapsed .expander__button .f-icon {
      transform: rotate(180deg);
    }
  }

  &.collapsed {
    .expander__mask::before {
      display: none;
    }
  }

  .js-accordion & {
    .expander__content,
    .expander__mask {
      padding: 8px;
    }
  }
}

// This is temproray fix until js-accordion is upgraded / changed
.js-accordion.js-withExpander {
  .js-accordion-content.open {
    max-height: none !important;
  }
}
