@import (reference) '../../styles/_library.less';

.f-layer-switcher {
  .f-selectList();

  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  border: 1px solid @color-silver-darker;
  background: @color-white colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @color-black) right 10px top 50% /
    12px auto no-repeat;
  padding: 10px 31px 10px 13px;

  &:hover {
    color: @color-orange;
    background-image: colorize(data-uri(icon('icon_i_058_arrow_bottom_02')), @color-orange);
  }

  option {
    text-transform: uppercase;
    color: @color-black;

    &:hover,
    &:focus {
      background-color: @color-silver-light;
    }
  }
}
