.f-productCarousel {
  background: @color-white;

  .f-nCarousel__itemInner {
    align-items: flex-start;
  }

  // Arrows are over the productCarousel slides
  &.f-nCarousel--arrows {
    padding: 0;
  }

  // article.thumbnail : this class is used on homes
  // TODO: use same miniFA as desktop
  article.thumbnail,
  .miniFA.thumbnail {
    width: 260px;
    border: solid 1px @color-silver-lighter;
  }

  .miniFA__title {
    white-space: normal;
  }
}
